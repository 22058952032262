import React from 'react';
import {
  CssBaseline,
  Grid,
  withStyles,
  // Typography
} from '@material-ui/core';
import LayoutPage from './LayoutPage';
import { TermsOfUseText, UserText, Dmca } from './TermsOfUseText';
// sample text halasz Terms of use

const styles = theme => ({
  container: {
    width: '100%',
    height: '100%',
    overflowY: 'auto',
    overflowX: 'auto',
    position: 'relative',
    backgroundColor: '#171A1D',
  },
  contentsMain: {
    border: '1px solid #f8f8f8',
    borderRadius: '5px',

    [theme.breakpoints.down('md')]: {},
  },
  contentsInner: {
    backgroundColor: '#1f2125',
    borderRadius: '8px',
    color: '#ffffff',
    margin: theme.spacing(0, 2),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(4, 4),
    height: '950px',
    overflowY: 'auto',

    [theme.breakpoints.down('md')]: {
      borderRadius: '5px',
      margin: theme.spacing(0, 1),
      marginBottom: theme.spacing(3),
      padding: theme.spacing(2, 2),
    },
  },
  TitleText: {
    fontWeight: 'bold',
    fontSize: '1.8em',
    [theme.breakpoints.down('md')]: {
      fontSize: '1.5em',
    },
  },
  tabStyled: {
    flexWrap: 'nowrap',
    '& > button': {
      padding: '12px',
      border: '1px solid red',
      background: 'transparent',
      fontWeight: 'bold',
      fontSize: '13px',
      color: '#ffffff',
      margin: '5px',
      cursor: 'pointer',
      [theme.breakpoints.down('md')]: {
        padding: '6px',
      },
    },
  },
});

const TAB = ['이용약관', '작가 회원 약관', 'DMCA 저작권 침해 통지'];

class TermsOfUse extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      matches: window.matchMedia('(max-width:480px)').matches, //md:992px
      tabvalue: 0,
    };
  }

  handleChageTabs = e => {
    this.setState({ tabvalue: e });
  };

  render() {
    const { classes } = this.props;

    return (
      <LayoutPage topbarBorderCheck={true} footer={true}>
        <Grid
          id='main_grid'
          container
          justifyContent='center'
          style={{ padding: this.state.matches ? this.props.theme.spacing(3, 0) : this.props.theme.spacing(10, 0), flexDirection: 'column', alignItems: 'center' }}
        >
          <Grid container justifyContent='center' item xs={10} md={5}>
            <Grid container justifyContent='flex-end' className={classes.tabStyled} item xs={12}>
              {TAB.map((button, idx) => (
                <button key={`Tabs_${idx}`} onClick={() => this.handleChageTabs(idx)}>
                  {button}
                </button>
              ))}
            </Grid>
          </Grid>
          <Grid container justifyContent='center' item xs={10} md={5} className={classes.contentsMain}>
            <Grid container justifyContent='center' item xs={12}>
              <Grid container item xs={1}></Grid>
              <Grid
                container
                item
                xs={10}
                alignContent='center'
                justifyContent='center'
                style={{
                  color: '#ffffff',
                  padding: this.state.matches ? this.props.theme.spacing(1, 0) : this.props.theme.spacing(2, 0),
                }}
              >
                <span className={classes.TitleText}>{TAB[this.state.tabvalue]}</span>
              </Grid>
              <Grid container item xs={1}></Grid>
            </Grid>

            <Grid id='content_main' container justifyContent='center' item xs={12}>
              <Grid className={classes.contentsInner}>
                <div dangerouslySetInnerHTML={{ __html: this.state.tabvalue === 0 ? TermsOfUseText : this.state.tabvalue === 1 ? UserText : this.state.tabvalue === 2 ? Dmca : '' }} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </LayoutPage>
    );
  }
}

export default withStyles(styles, { withTheme: true })(TermsOfUse);
