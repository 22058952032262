import axios from 'axios';
import { API_URL } from './index';
import { Auth } from 'aws-amplify';

const axiosInstance = axios.create({
  baseURL: API_URL,
  timeout: 1000 * 30,
  headers: { Pragma: 'no-cache', CacheControl: 'no-cache', Expires: '0', Authorization: `${localStorage.getItem('st_token')}` },
});

axiosInstance.interceptors.request.use(
  function (config) {
    const token = `${localStorage.getItem('jebo_jwt')}`;
    config.headers.userType = 'user';
    config.headers.Authorization = token;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },

  function (error) {
    if (error.response) {
      console.log('error data : ', error.response.data);
      console.log('error status : ', error.response.status);
      console.log('error headers : ', error.response.headers);
    } else if (error.request) {
      console.log(error.request);
    } else {
      console.log('Error', error.message);
    }
    console.log('error config : ', error.config);
    // const orgRequest = error.config;
    if (error.response && error.response.status === 401) {
      console.log('토큰 만료');
      tokenRefresh()
        .then(refreshRes => {
          // window.location.reload();
        })
        .catch(err => {
          // TODO Login Page 호출
          window.location.href = '/';
        });
    }
    // return error;
    return Promise.reject(error);
  },
);

export const tokenRefresh = async () => {
  try {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const currentSession = await Auth.currentSession();
    console.log(cognitoUser);
    console.log(currentSession);
    // cognitoUsers(cognitoUser, currentSession);
    return new Promise(function (resolve, reject) {
      cognitoUser.refreshSession(currentSession.refreshToken, async (err, session) => {
        if (session) {
          console.log(session);
          console.log('tokenRefresh 실행');
          const { accessToken } = session;
          const { idToken } = session;

          localStorage.setItem('jebo_jwt', idToken.jwtToken);
          localStorage.setItem('jebo_user', idToken.payload['cognito:username']);
          localStorage.setItem('jebo_name', idToken.payload.name);
          localStorage.setItem('jebo_email', idToken.payload.email);
          localStorage.setItem('jebo_picture', idToken.payload.profile);

          return resolve(accessToken.jwtToken);
        } else {
          console.log(err.message);
        }
      });
    });
  } catch (e) {
    console.log('Unable to refresh Token', e);
  }
};

export default axiosInstance;
