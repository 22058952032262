import React from 'react';
import {
  withWidth,
  TextField,
  Grid,
  withStyles,
  Button,
  Snackbar,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  LinearProgress,
  FormControlLabel,
  Checkbox,
  Chip,
  InputAdornment,
  Box,
  CircularProgress,
  Typography,
} from '@material-ui/core';
import DateRangeIcon from '@material-ui/icons/DateRange';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import DatePicker from 'react-datepicker';
import ko from 'date-fns/locale/ko';
import keyword from 'keyword-extractor-korean';
// import { getSelectOptions } from '../../components/ListComponets';

import moment from 'moment';
import { API_URL, VODAPI_URL, fileUpload, createVodUrl, changeFile } from '../../constants';
import axiosInstance from '../../constants/AxiosInstance';

import './react-datepicker.css';
import PropTypes from 'prop-types';
import axios from 'axios';
import Alert from '../../components/Alert';

// import ReactJWPlayer from 'react-jw-player';

const styles = theme => ({
  filePreviewWrapper: {
    width: '100%',
    color: '#aaa',
    fontWeight: '500',
  },
  fileContentWrapper: {
    backgroundColor: '#171A1D',
    borderRadius: '5px',
    marginTop: '0.5rem',
    marginBottom: '0.5rem',
  },
  filePreviewTitle: {
    height: '22px',
  },
  fileContent: {
    width: '100%',
    maxHeight: '130px',
    borderRadius: '5px',
    [theme.breakpoints.down('sm')]: {
      maxHeight: '180px',
    },
  },
  fileNameWrapper: {
    padding: '0.5rem',
    cursor: 'pointer',
  },
  fileName: {
    color: '#fff',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  labelTxt: {
    color: '#aaa',
    objectFit: 'contain',
    fontFamily: 'Noto Sans',
    fontSize: '18px',
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: 'normal',
    textAlign: 'left',
  },
  detailWrapper: {
    padding: '1rem 1rem 0rem 1rem',
  },
  titleWrapper: {
    marginBottom: '1.5rem',
    '& input': {
      fontFamily: 'Noto Sans',
      fontSize: '14px',
      border: '1px solid #2a2d31',
      backgroundColor: '#171A1D',
      color: '#fff',
    },
  },
  titleInput: {
    marginTop: '0.5rem',
    '::placeholder': {
      fontSize: '16px',
    },
  },
  equipmentSelectWrapper: {
    marginBottom: '1rem',
  },
  topicSelectWrapper: {
    marginBottom: '1rem',
  },
  formControl: {
    width: '100%',
    '& div': {
      color: '#fff',
      fontFamily: 'Noto Sans',
      fontSize: '14px',
    },
    '& svg': {
      color: '#fff',
    },
  },
  dateControl: {
    backgroundColor: '#171A1D',
    marginTop: '5px',
    '& .MuiOutlinedInput-input': {
      padding: '12.5px 14px',
      margin: 0,
      textAlign: 'left',
    },
    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: '10px',
    },
  },
  iconControl: {
    '& div': {
      height: '100%',
      color: '#fff',
    },
    '& svg': {
      color: '#fff',
      cursor: 'pointer',
    },
  },
  selectEmpty: {
    marginTop: theme.spacing(3),
    backgroundColor: '#171A1D',
    height: '34px',
    '& input': {
      color: '#fff',
      fontSize: '14px',
    },
  },
  dateTimeForm: {
    '& label': {
      color: '#aaa',
      fontFamily: 'Noto Sans',
      fontSize: '17px',
      textAlign: 'left',
      fontWeight: '500',
    },
    '& input': {
      marginTop: theme.spacing(1),
      paddingLeft: '0.5rem',
      color: '#fff',
      fontFamily: 'Noto Sans',
      fontSize: '14px',
    },
    '& span': {
      marginTop: theme.spacing(1),
      fontSize: '14px',
      color: '#fff',
    },
    '& .MuiInput-underline:before': {
      left: '0',
      right: '0',
      bottom: '0',
      position: 'absolute',
      transition: 'border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      borderBottom: '1px solid #2a2d31',
      pointerEvents: 'none',
    },
  },
  contentWrapper: {
    marginTop: '1.5rem',
  },
  contentMultiline: {
    width: '100%',
    marginTop: '0.5rem',
    marginBottom: '1rem',
    border: '1px solid #2a2d31',
    backgroundColor: '#171A1D',
    '& textarea': {
      color: '#fff',
      fontSize: '12px',
      fontFamily: 'Noto Sans',
    },
  },
  contentProgress: {
    height: '6px',
    borderRadius: '5px',
  },
  contentNotice: {
    color: '#aaa',
    objectFit: 'contain',
    fontFamily: 'Noto Sans',
    fontSize: '12px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: 'normal',
    textAlign: 'left',
    lineHeight: '1.8',
  },
  hashtagWrapper: {
    width: '100%',
    marginTop: '1.75rem',
    '& input': {
      fontFamily: 'Noto Sans',
      fontSize: '14px',
      border: '1px solid #2a2d31',
      backgroundColor: '#171A1D',
      color: '#fff',
    },
  },
  hashtagContentWrapper: {
    display: 'inline-block',
    marginTop: '0.5rem',
  },
  agreeWrapper: {
    marginTop: '0.5rem',
    '& span': {
      fontWeight: '600',
      fontSize: '14px',
      fontFamily: 'Noto Sans',
    },
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '1rem',
    flexWrap: 'wrap',
  },
  colorBtn: {
    width: '100%',
    backgroundColor: '#e61737',
    border: '1px solid #e61737',
    color: '#fff',
    fontWeight: 'bold',
    fontSize: 'large',
    height: '50px',
    margin: theme.spacing(1, 0),
    padding: theme.spacing(2, 0),
    [theme.breakpoints.down('md')]: {},
  },
  deleteBtn: {
    width: '100%',
    border: '1px solid #fff',
    color: '#fff',
    fontWeight: 'bold',
    fontSize: 'large',
    height: '50px',
    margin: theme.spacing(1, 0),
    padding: theme.spacing(2, 0),
    [theme.breakpoints.down('md')]: {},
  },
  hashtagContent: {
    border: '1px solid #e61737',
    marginRight: '0.3rem',
    marginBottom: '0.5rem',
  },
  circularProgress: {
    position: 'fixed',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
});
const extractor = keyword();

function CircularProgressWithLabel(props) {
  return (
    <Box position='relative' display='inline-flex'>
      <CircularProgress size={100} variant='determinate' {...props} />
      <Box top={0} left={0} bottom={0} right={0} position='absolute' display='flex' alignItems='center' justifyContent='center'>
        <Typography variant='caption' component='div' style={{ color: 'white', fontSize: '25px', fontWeight: 'bold' }}>{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

class Detail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFocus: false,
      tabValue: 0, // 탭 시작값
      matches: window.matchMedia('(max-width:480px)').matches, //md:992px
      fileList: this.props.fileList,
      equipmentList: null,
      topicList: null,
      progress: 0,
      uploadProgress: 0,
      tag_extractor: [],
      fileList: [],
      prevFileList: [],
      preViewList: [],
      validate: {
        title: false,
        content: false,
        equipment: false,
        topic: false,
        date: false,
        time: false,
        hashtag: false,
      },
      feedback: {
        msg: '',
        status: false,
      },
      body: {
        id: '',
        title: '',
        content: '',
        equipment: '',
        topic: '',
        date: '날짜선택',
        time: '시간선택',
        hashtag: [],
        vod_status: '',
        userId: '',
      },
      uploding: false,
    };

    // BINDING
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.onClickValidate = this.onClickValidate.bind(this);
    this.handleFeedbackClose = this.handleFeedbackClose.bind(this);
    this.getSelectOptions = this.getSelectOptions.bind(this);
    this.keywordExtractor = this.keywordExtractor.bind(this);
    this.handleTagDelete = this.handleTagDelete.bind(this);
    this.onLoadedVideo = this.onLoadedVideo.bind(this);
  }
  componentDidMount() {
    this.getSelectOptions();
    this.getData(this.props.data);
  }

  getData = async _data => {
    const response = await axiosInstance.get(`${API_URL}/vod/${_data.id}/${_data.userId}`, { headers: { Pragma: 'no-cache' } });
    const data = response.data.value.Item;
    let _body = this.state.body;
    let _validate = this.state.validate;

    // console.log(data);
    for (const [k, v] of Object.entries(data)) {
      for (const [_k, _v] of Object.entries(_body)) {
        if (k === _k) {
          _body[k] = v;
          _validate[k] = true;
        }
      }
    }

    if (data.vod[0]) {
      await createVodUrl(data.vod[0].mark).then(result => {
        this.setState({ prevFileList: [result] });
        this.setPreViewList([result]);
      });
    }
    this.setFileList(data.vod);
    this.setState({ body: _body, validate: _validate });
  };

  setFileList(files) {
    this.setState({ fileList: files });
  }

  setPreViewList = videos => {
    this.setState({ preViewList: videos });
  };

  onChangeFile = async e => {
    changeFile(e).then(res => {
      this.setPreViewList(res.buffs);
      this.setFileList(res.arr);
    });
  };

  ExampleCustomInput = ({ value, onClick }) => (
    <button class='example-custom-input' onClick={onClick}>
      {value}
    </button>
  );

  putData = (cb, oldVod) => {
    axiosInstance
      .put(`${API_URL}/vod/`, this.state.body, { headers: { Pragma: 'no-cache' } })
      .then(postRes => {
        try {
          if (this.state.preViewList[0] !== this.state.prevFileList[0]) {
            fileUpload(this.state.fileList, postRes.data.value.id, 'vod', 'vod', `${API_URL}/vod/`, this.progressCallback)
              .then(async vodRes => {
                // console.log(vodRes.data.value);
                let vod_id = vodRes.data.value.vod[0].key.split('/')[2];
                let vodEnc = await axios.get(`${VODAPI_URL}/thumbnail/?prefix=${this.state.body.userId}&vod_id=${vod_id}`);
                axios.get(`${VODAPI_URL}/video/?prefix=${this.state.body.userId}&vod_id=${vod_id}`);

                if (vodEnc.status === 200) {
                  cb(true, vodRes);
                  Alert('', '수정되었습니다. <br /> 영상이 인코딩 되는 동안 수정 및 공유가 불가능합니다.', '', '', () => {
                    this.props.onClose();
                    window.location.reload();
                  });
                }
              })
              .catch(vodErr => {
                return cb(false, vodErr);
              });
          } else {
            Alert('', '수정되었습니다.', '', '', () => {
              this.props.onClose();
            });
          }
        } catch (error) {
          console.log(error);
        } finally {
          this.props.reRender();
        }
      })
      .catch(err => {
        // swal('문제가 발생했습니다. 다시 시도해주세요');
      });
  };

  progressCallback = per => {
    this.setState({ uploadProgress: per });
  };

  getSelectOptions = () => {
    axiosInstance.get(`${API_URL}/code/`).then(response => {
      // FILTERING TWO GROUPS : { equipment, topic }
      const equipmentList = response.data.value.filter(x => x.group === 'equipment');
      const topicList = response.data.value.filter(x => x.group === 'topic');

      this.setState({ equipmentList: equipmentList, topicList: topicList });
    });
  };

  handleProgress = strLen => {
    this.setState({ progress: (strLen / 2) * 10 <= 100 ? (strLen / 2) * 10 : 100 });
  };

  handleInputChange = e => {
    let newObj = this.state.validate;
    let newBody = this.state.body;

    if (e.target.name === 'title') newObj[e.target.name] = e.target.value.length >= 10 ? true : false;
    else if (e.target.name === 'equipment') newObj[e.target.name] = e.target.value !== '' ? true : false;
    else if (e.target.name === 'topic') newObj[e.target.name] = e.target.value !== '' ? true : false;
    else if (e.target.name === 'date') newObj[e.target.name] = e.target.value !== '' ? true : false;
    else if (e.target.name === 'time') newObj[e.target.name] = e.target.value !== '' ? true : false;
    else if (e.target.name === 'content') {
      newObj[e.target.name] = e.target.value.replace(/\n/gi, '').length >= 20 ? true : false;
      this.handleProgress(e.target.value.replace(/\s/gi, '').length);
    } else newObj[e.target.name] = e.target.checked;

    if (e.target.name !== 'agree') {
      newBody[e.target.name] = e.target.value;
      this.setState({ body: newBody });
    }

    this.setState({ validate: newObj });
  };

  dateChange = e => {
    if (!e) return false;
    let date = new moment(e).format('yyyy.MM.DD');
    let newBody = this.state.body;
    let newObj = this.state.validate;

    newObj['date'] = date !== '' ? true : false;
    newBody['date'] = date;

    this.setState({ validate: newObj });
    this.setState({ body: newBody });
  };

  timeChange = e => {
    if (!e) return false;
    let date = new moment(e).format('HH:mm');
    let newBody = this.state.body;
    let newObj = this.state.validate;

    newObj['time'] = date !== '' ? true : false;
    newBody['time'] = date;

    this.setState({ validate: newObj });
    this.setState({ body: newBody });
  };

  clickDateInput = () => {
    document.getElementById('dataPicker').click();
  };

  clickTimeInput = () => {
    document.getElementById('timePicker').click();
  };

  fileChange = e => {
    e.preventDefault();
    document.getElementById('file-picker').click();
  };

  handleDateChange = date => {
    let newObj = this.state.validate;
    let newBody = this.state.body;

    newBody['date'] = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')} ${date.getHours().toString().padStart(2, '0')}:${date
      .getMinutes()
      .toString()
      .padStart(2, '0')}`;
    newObj['date'] = true;

    this.setState({ validate: newObj, body: newBody });
  };

  handleFeedbackClose = () => {
    this.setState({ feedback: { msg: '', status: false } });
  };

  onClickValidate = e => {
    let msg = '';
    let status = false;
    this.setState({ uploding: true });

    if (!Object.values(this.state.validate).includes(false)) {
      this.putData((retS, retV) => {
        if (retS === false) {
          this.setState({ feedback: { status: !retS, msg: 'Error' } });
        } else {
          setTimeout(() => {
            Alert('', '수정 되었습니다.');
          }, 500);
        }
      });
      return;
    }

    let newArr = Object.entries(this.state.validate).filter(x => x[1] === false)[0];

    switch (newArr[0]) {
      case 'title':
        msg = '제목을 최소 10자 이상 작성해주세요.';
        status = true;
        break;
      case 'equipment':
        msg = '촬영 기기를 선택해주세요.';
        status = true;
        break;
      case 'topic':
        msg = '주제를 선택해주세요.';
        status = true;
        break;
      case 'date':
        msg = '발생 날짜를 입력해주세요.';
        status = true;
        break;
      case 'time':
        msg = '시간을 입력해주세요.';
        status = true;
        break;
      case 'content':
        msg = '상황 설명을 최소 20자 이상 작성해주세요.';
        status = true;
        break;
      case 'hashtag':
        msg = '태그를 최소 5개 이상 입력해주세요.';
        status = true;
        break;
      case 'agree':
        msg = '동의를 선택해주세요.';
        status = true;
        break;
      default:
        msg = '오류가 발생했습니다.';
        status = true;
        break;
    }
    setTimeout(() => {
      this.setState({ uploding: false }, () => {
        document.getElementById(newArr[0]).focus();
      });
    }, 1000);

    this.setState({ feedback: { msg: msg, status: status } });
  };

  deleteVod = () => {
    Alert('', '영상을 삭제하시겠습나까?', '아니요', '예', res => {
      if (res) {
        axiosInstance.delete(`${API_URL}/vod/${this.state.body.id}/${this.state.body.userId}`, { headers: { Pragma: 'no-cache' } }).then(response => {
          if (response.status === 200) {
            Alert('', '삭제되었습니다.', '', '', () => {
              this.props.history.push('/');
            });
          }
        });
      }
    });
  };

  handleEnterKeyDown = e => {
    if (e.key === 'Enter') {
      let newBody = this.state.body;

      if (newBody['hashtag'].indexOf(e.target.value) < 0) newBody['hashtag'] = [...newBody['hashtag'], e.target.value];

      if (newBody['hashtag'].length >= 5) {
        let newObject = this.state.validate;
        newObject['hashtag'] = true;
        this.setState({ validate: newObject });
      }

      this.setState({ body: newBody });
      document.getElementById('hashtag').value = '';
    }
  };

  handleTagDelete = value => {
    let newBody = this.state.body;

    newBody['hashtag'].splice(newBody['hashtag'].indexOf(value), 1);

    if (newBody['hashtag'].length < 5) {
      let newObject = this.state.validate;
      newObject['hashtag'] = false;
      this.setState({ validate: newObject });
    }

    this.setState({ body: newBody });
  };

  keywordExtractor = e => {
    if (this.state.validate.title) {
      let newBody = this.state.body;

      newBody['hashtag'] = [...new Set([...newBody['hashtag'], ...Object.keys(extractor(e.target.value))])];

      if (newBody['hashtag'].length >= 5) {
        let newObject = this.state.validate;
        newObject['hashtag'] = true;
        this.setState({ validate: newObject });
      }

      this.setState({ body: newBody });
    }
  };

  onLoadedVideo = e => {
    const arr = this.state.fileList.map((file, idx) => {
      const newObj = file;
      newObj['duration'] = Math.floor(e.target.duration);
      newObj['resolution'] = `${e.target.videoWidth} X ${e.target.videoHeight}`;

      return newObj;
    });

    this.setState({ fileList: arr });
  };

  render() {
    const { classes } = this.props;
    const { uploding, fileList, preViewList } = this.state;

    return (
      <Grid container spacing={4}>
        {/* COL 1 SECTION */}
        {/* FILE PREVIEW WRAPPER */}
        <Grid className={classes.filePreviewWrapper} item xl={4} lg={4} md={4} sm={12} xs={12}>
          <Grid className={classes.filePreviewTitle} item xl={12} lg={12} md={12} sm={12} xs={12}>
            <span>파일 미리보기</span>
          </Grid>

          <Grid container item xl={12} lg={12} md={12} sm={12} xs={12} justifyContent='center'>
            {!!fileList.length &&
              fileList.map((file, idx) => (
                <Grid className={classes.fileContentWrapper} container direction='column' item key={`previewContent-${idx}`}>
                  <Grid item>
                    {preViewList && preViewList[idx] ? (
                      <video className={classes.fileContent} preload='metadata' src={preViewList[idx]} controls onLoadedMetadata={this.onLoadedVideo}></video>
                    ) : (
                      <div style={{ padding: 8 }}>
                        파일 미리보기가 지원되지 않는 <br /> 파일입니다.
                      </div>
                    )}
                  </Grid>
                  {/* FILE NAME WRAPPER */}
                  <input
                    type='file'
                    id='file-picker'
                    multiple
                    accept='video/*'
                    style={{ display: 'none' }}
                    onChange={e => {
                      this.onChangeFile(e);
                    }}
                  />
                  <Grid className={classes.fileNameWrapper} container item onClick={e => this.fileChange(e)}>
                    <Grid item>
                      <span>파일 이름</span> &nbsp;
                    </Grid>
                    <Grid className={classes.fileName} item>
                      <strong>{file.name === '' ? 'No File Name' : file.name}</strong>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
          </Grid>
        </Grid>

        {/* COL 2 SECTION */}
        {/* FILE DETAIL WRITING FIELD WRAPPER */}
        <Grid className={classes.detailWrapper} item xl={8} lg={8} md={8} sm={12} xs={12}>
          {/* TITLE WRAPPER */}
          <div className={classes.titleWrapper}>
            <InputLabel className={classes.labelTxt} shrink>
              영상 제목
            </InputLabel>
            <TextField
              className={classes.titleInput}
              name='title'
              id='title'
              variant='outlined'
              placeholder='사건의 개요를 알 수 있도록 자세히 적어주세요. (최소 10자 이상)'
              fullWidth
              value={this.state.body.title}
              onChange={this.handleInputChange}
              onBlur={this.keywordExtractor}
              disabled={uploding}
            />
          </div>

          {/* ROW 1 SELECT FIELD */}
          <Grid container spacing={2}>
            {/* COL 1 : EQUIPMENT */}
            <Grid container className={classes.equipmentSelectWrapper} item xs={6} justifyContent='flex-start'>
              <FormControl className={classes.formControl}>
                <InputLabel className={classes.labelTxt} shrink>
                  촬영 기기
                </InputLabel>

                <Select
                  className={classes.selectEmpty}
                  fullWidth
                  displayEmpty
                  name='equipment'
                  id='equipment'
                  variant='outlined'
                  value={this.state.body.equipment}
                  onChange={this.handleInputChange}
                  disabled={uploding}
                >
                  <MenuItem value='' disabled>
                    촬영기기 선택
                  </MenuItem>
                  {this.state.equipmentList &&
                    this.state.equipmentList.map(x => (
                      <MenuItem value={x.id} key={x.id}>
                        {x.value}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            {/* COL 2 : TOPIC */}
            <Grid className={classes.topicSelectWrapper} container item xs={6} justifyContent='flex-end'>
              <FormControl className={classes.formControl}>
                <InputLabel shrink className={classes.labelTxt}>
                  주제
                </InputLabel>

                <Select
                  className={classes.selectEmpty}
                  fullWidth
                  displayEmpty
                  name='topic'
                  id='topic'
                  variant='outlined'
                  value={this.state.body.topic}
                  onChange={this.handleInputChange}
                  disabled={uploding}
                >
                  <MenuItem value='' disabled>
                    주제 선택
                  </MenuItem>
                  {this.state.topicList &&
                    this.state.topicList.map(x => (
                      <MenuItem value={x.id} key={x.id}>
                        {x.value}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          {/* ROW 2 SELECT FIELD */}
          {/* DATE AND TIME FIELD WRAPPER */}
          <Grid container className={classes.dateTimeForm} spacing={2}>
            {/* COL 1 : DATE */}
            <Grid container item xs={6} justifyContent='flex-start' style={{ width: '100%' }}>
              <InputLabel shrink className={classes.labelTxt}>
                발생 날짜
              </InputLabel>

              <TextField
                className={classes.dateControl}
                name='date'
                id='date'
                type='button'
                variant='outlined'
                placeholder='날짜 선택'
                fullWidth
                aria-readonly
                onClick={this.clickDateInput}
                value={this.state.body.date}
                disabled={uploding}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end' className={classes.iconControl}>
                      <DateRangeIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <DatePicker id='dataPicker' customInput={<div />} locale={ko} onChange={this.dateChange} maxDate={new Date()} showMonthDropdown showYearDropdown />
            </Grid>
            {/* COL 2: TIME */}
            <Grid container item xs={6} justifyContent='flex-start'>
              <InputLabel shrink className={classes.labelTxt}>
                시간
              </InputLabel>
              <TextField
                className={classes.dateControl}
                name='time'
                id='time'
                type='button'
                variant='outlined'
                value={this.state.body.time}
                fullWidth
                aria-readonly
                onClick={this.clickTimeInput}
                disabled={uploding}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end' className={classes.iconControl}>
                      <AccessTimeIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <DatePicker id='timePicker' customInput={<div />} locale={ko} showPopperArrow={false} showTimeSelect showTimeSelectOnly timeIntervals={15} onChange={this.timeChange} />
            </Grid>
          </Grid>

          {/* CONTENT FIELD */}
          <div className={classes.contentWrapper}>
            <InputLabel shrink className={classes.labelTxt}>
              상황 설명
            </InputLabel>
            {/* TEXT AREA */}
            <TextField
              className={classes.contentMultiline}
              name='content'
              id='content'
              placeholder='누가, 언제, 어디서, 무엇을, 어떻게, 왜 일어났는지 알려주세요. (최소 20자 이상)'
              variant='outlined'
              multiline
              rows={5}
              value={this.state.body.content}
              onChange={this.handleInputChange}
              disabled={uploding}
            />

            {/* TEXT AREA LINEAR PROGRESS */}
            {this.state.progress !== undefined && (
              <React.Fragment>
                <LinearProgress className={classes.contentProgress} variant='determinate' value={this.state.progress} />
                {this.state.progress > 0 && this.state.progress < 100 && (
                  <div className={classes.contentNotice}>
                    <span>상황 설명을 더 추가해주세요!</span>
                  </div>
                )}
              </React.Fragment>
            )}
          </div>

          {/* HASHTAG FIELD */}
          <div className={classes.hashtagWrapper}>
            <InputLabel shrink className={classes.labelTxt}>
              태그
            </InputLabel>
            <TextField
              className={classes.titleInput}
              name='hashtag'
              id='hashtag'
              placeholder='입력하고 엔터를 눌러주세요. (태그 최소 5개)'
              variant='outlined'
              fullWidth
              onKeyDown={this.handleEnterKeyDown}
              disabled={uploding}
            />
            <div className={classes.hashtagContentWrapper}>
              {!!this.state.body.hashtag.length &&
                this.state.body.hashtag.map((item, idx) => {
                  return (
                    <React.Fragment key={`tag-${idx}`}>
                      <Chip className={classes.hashtagContent} name={item} label={item} onDelete={() => this.handleTagDelete(item)} color='primary' size='small' />
                    </React.Fragment>
                  );
                })}
            </div>
          </div>

          <div className={classes.buttonWrapper}>
            <Button className={classes.colorBtn} onClick={this.onClickValidate} disabled={uploding}>
              수정
            </Button>
            <Button className={classes.deleteBtn} onClick={this.deleteVod} disabled={uploding}>
              삭제
            </Button>
          </div>

          {/* SNACK BAR */}
          <div>
            <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              open={this.state.feedback.status}
              message={this.state.feedback.msg}
              onClose={this.handleFeedbackClose}
              key='vertical + horizontal'
            />
          </div>
        </Grid>

        {!!this.state.uploadProgress && (
          <div className={classes.circularProgress}>
            <CircularProgressWithLabel value={this.state.uploadProgress} />
          </div>
        )}
      </Grid>
    );
  }
}
export default withStyles(styles)(withWidth()(Detail));
