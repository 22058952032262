import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, makeStyles } from '@material-ui/core';

const black = makeStyles(theme => ({
  arrow: {
    color: '#0c0e10',
  },
  tooltip: {
    backgroundColor: '#0c0e10',
    padding: '12px 13px 9px 14px',
    width: '230px',
    textAlign: 'center',
  },
}));

const gray = makeStyles(theme => ({
  arrow: {
    color: '#36383b',
  },
  tooltip: {
    backgroundColor: '#36383b',
    padding: '12px 13px 9px 14px',
    width: '230px',
    textAlign: 'center',
  },
}));

const red = makeStyles(theme => ({
  arrow: {
    color: '#e61737',
  },
  tooltip: {
    backgroundColor: '#e61737',
    color: '#ffffff',
    width: '230px',
    height: '78px',
    textAlign: 'center',
    padding: '12px 13px 0px 14px',
    right: '0%',
  },
}));

const ToolTip = ({ children, title, placement, arrow, open, color }) => {
  let classes = null;
  let _title = null;

  if (color === 'red') classes = red();
  if (color === 'black') classes = black();
  if (color === 'gray') classes = gray();

  if (title === 'wait') _title = WaitToolTipTitle();
  if (title === 'request') _title = RequestToolTipTitle();
  if (title === 'report') _title = ReportToolTipTitle();
  if (title === 'vodEdit') _title = VodEditToolTipTitle();

  return (
    <Tooltip arrow={arrow} placement={placement} title={_title} open={open} classes={classes}>
      {children}
    </Tooltip>
  );
};

ToolTip.propTypes = {
  children: PropTypes.any,
  title: PropTypes.any,
  arrow: PropTypes.bool,
  open: PropTypes.bool,
};

export default ToolTip;

const WaitToolTipTitle = () => {
  return (
    <p style={{ margin: '0', fontSize: '0.6rem' }}>
      <b style={{ color: '#f60337', fontSize: '0.8rem', display: 'block', marginBottom: '3px' }}>관리자가 해당 영상을 심사중입니다.</b>
      심사중에도 언제든지 링크를 통해 제보할 수 있습니다. <br />
      심사가 끝나면 누구나 볼 수 있도록 공개됩니다.
    </p>
  );
};

const RequestToolTipTitle = () => {
  return (
    <p style={{ margin: '0', fontSize: '0.8rem' }}>
      <b style={{ color: '#426eff', fontSize: '0.9rem' }}>기업은행 000-0000-0000</b> <br />
      구매 금액을 입금해주세요! <br />
      입금 후 확인이 완료되면 <br />
      다운로드 버튼이 활성화됩니다.
    </p>
  );
};

const ReportToolTipTitle = () => {
  return (
    <p style={{ margin: '0', fontSize: '12px' }}>
      원하시는 방송사의 카톡 제보창을 열고 <br />
      바로 붙여넣기 해보세요! <br />
      자동으로 제보 형식이 만들어집니다.
    </p>
  );
};

const VodEditToolTipTitle = () => {
  return (
    <p style={{ margin: '0', fontSize: '12px' }}>
      영상이 아직 비공개인 경우, <br />
      수정 / 삭제가 가능합니다.
    </p>
  );
};
