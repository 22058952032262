import React from 'react';
import { Grid, withStyles, Typography } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { API_URL } from '../constants';

import LayoutPage from './LayoutPage';

const styles = theme => ({
  container: {
    width: '100%',
    height: '100%',
    overflowY: 'auto',
    overflowX: 'auto',
    position: 'relative',
    backgroundColor: '#171A1D',
  },
  accordionDiv: {
    // border: '',
    borderRadius: '5px',
    marginBottom: '2em',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      borderRadius: '2px',
    },
  },
  qnaAccordion: {
    backgroundColor: '#1f2125',
    boxShadow: 'none',
    // borderBottom: '',
    // minHeight: '8vh',
    [theme.breakpoints.down('sm')]: {},
  },
  summaryAccordion: {
    '&:hover': {
      '& *': { color: theme.palette.primary.main },
    },
  },
  qContent: {
    fontSize: '1rem',
    fontWeight: 'bold',
    color: '#aaaaaa',
    padding: theme.spacing(2, 0),
  },
  aContent: {
    borderTop: '1px solid #aaaaaa',
    color: '#aaaaaa',
    padding: theme.spacing(3, 6),
    fontSize: '0.9rem',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2, 3),
    },
    '& table': {
      width: '100%',
      borderCollapse: 'collapse',
    },
    '& td': {
      border: '1px solid dimgray',
      padding: '4px',
      background: 'white',
    },
    '& tr': {
      '&:first-child': {
        '& td': {
          backgroundColor: `${theme.palette.primary.bg} !important`,
          fontWeight: 'bold',
        },
      },
    },
  },
});

class FAQPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      faqList: [],
      expaneded: '',
      matches: window.matchMedia('(max-width:480px)').matches, //md:992px
    };
  }

  componentDidMount() {
    //sample API evt012 (test webconference)
    fetch(`${API_URL}/faq`, {
      method: 'GET',
    })
      .then(res => res.text())
      .then(body => {
        let _result = JSON.parse(body);
        _result.value.sort((a, b) => {
          return Number(a.sortOrder) > Number(b.sortOrder) ? 1 : -1;
        });

        this.setState({ faqList: _result.value });
      })
      .catch(err => {
        console.log(err);
      });
  }

  handleChange = panel => (event, newExpanded) => {
    this.setState({ expaneded: newExpanded ? panel : '' });
  };

  render() {
    const { classes } = this.props;
    const faqList = datas => {
      return datas.map((faq, i) => {
        return (
          <Accordion square expanded={this.state.expaneded === faq.id ? true : false} key={i} className={classes.qnaAccordion} style={{ margin: 0 }} onChange={this.handleChange(faq.id)}>
            <AccordionSummary
              expandIcon={this.state.expaneded === faq.id ? <ExpandMoreIcon style={{ color: '#545c80' }} /> : <ExpandMoreIcon style={{ color: '#adadad' }} />}
              key={i}
              className={classes.summaryAccordion}
            >
              <Typography className={classes.qContent}>{faq.title}</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.aContent}>
              <div dangerouslySetInnerHTML={{ __html: faq.contents }} style={{ wordBreak: 'break-word' }} />
            </AccordionDetails>
          </Accordion>
        );
      });
    };

    return (
      <LayoutPage topbarBorderCheck={true} footer={true}>
        <Grid container justifyContent='center'>
          <Grid container justifyContent='center' item xs={10} md={6}>
            <Grid
              container
              justifyContent='flex-start'
              style={{
                fontSize: '2.5em',
                color: '#aaaaaa',
                padding: this.state.matches ? this.props.theme.spacing(3, 0) : this.props.theme.spacing(6, 0),
              }}
            >
              FAQ
            </Grid>
            <div className={classes.accordionDiv}>{this.state.faqList ? faqList(this.state.faqList) : ''}</div>;
          </Grid>
        </Grid>
      </LayoutPage>
    );
  }
}

const mapStateToProps = state => {
  return {
    menuValue: state.changeMenu.value,
  };
};

export default withStyles(styles, { withTheme: true })(FAQPage);
