import React from 'react';
import { withStyles, CardContent, Grid, withWidth } from '@material-ui/core';
import LayoutPage from '../components/LayoutPage';
import { API_URL, encrypt } from '../constants';
import axiosInstance from '../constants/AxiosInstance';
import CustomCircularProgress from '../components/CustomCircularProgress';

import UploadBodyWebComponent from './components/UploadBodyWebComponent';
import UploadBodyMobileComponent from './components/UploadBodyMobileComponent';
import SaleBodyMobileComponent from './components/SaleBodyMobileComponent';
import SaleBodyWebComponent from './components/SaleBodyWebComponent';
import SaleFooterComponent from './components/SaleFooterComponent';
import PurchaseBodyWebComponent from './components/PurchaseBodyWebComponent';
import PurchaseBodyMobileComponent from './components/DownloadBodyMobileComponent';
import DownloadBodyMobileComponent from './components/DownloadBodyMobileComponent';
import DownloadBodyWebComponent from './components/DownloadBodyWebComponent';

const header = {
  upload: [
    { title: 'No.', size: 1 },
    { title: '영상', size: 2 },
    { title: '제목', size: 3 },
    { title: '업로드 날짜', size: 2 },
    { title: '상태', size: 2 },
    { title: '', size: 2 },
  ],
  sale: [
    { title: 'No.', size: 1 },
    { title: '영상', size: 2 },
    { title: '제목', size: 3 },
    { title: '판매 날짜', size: 2 },
    { title: '판매금액', size: 2 },
    { title: '상태', size: 2 },
  ],
  purchase: [
    { title: 'No.', size: 1 },
    { title: '영상', size: 2 },
    { title: '제목', size: 4 },
    { title: '구매날짜', size: 1 },
    { title: '처리상태', size: 1 },
    { title: '구매금액', size: 1 },
    { title: '다운로드', size: 2 },
  ],
  download: [
    { title: 'No.', size: 1 },
    { title: '영상', size: 2 },
    { title: '제목', size: 4 },
    { title: '구매날짜', size: 1 },
    { title: '구매금액', size: 2 },
    { title: '다운로드', size: 2 },
  ],
};

const styles = theme => ({
  container: {
    width: '100%',
    height: '100%',
    overflowY: 'auto',
    overflowX: 'auto',
    position: 'relative',
    backgroundColor: '#171A1D',
  },
  headerContainer: {
    width: '100%',
    backgroundColor: '#1f2125',
    textAlign: 'center',
    border: 'none',
    padding: '0',
    paddingBottom: '0 !important',
  },
  headerWrapperS: {
    display: 'none',
  },
  headerWrapperNS: {
    color: '#ffffff',
    textAlign: 'center',
    border: 'none',
    padding: '0',
    justifyContent: 'space-between',
  },
  UploadListMain: {
    width: '75%',
    margin: '5rem',
    [theme.breakpoints.down('md')]: {
      margin: '2rem 0',
    },
  },
  XsOrSmWidth: {
    color: '#ffffff',
    fontSize: '18px',
    fontWeight: 'bold',
    borderBottom: '1px solid rgb(143, 143, 143, .5)',
    width: '100%',
    paddingBottom: '10px',
  },
  NotXsOrSmWidth: {
    color: '#ffffff',
    fontSize: '18px',
    fontWeight: 'bold',
    margin: '15px 0',
  },
  titleBorder: {
    width: '100%',
    display: 'block',
    borderRight: '1px solid rgb(143, 143, 143, .5)',
    margin: '16px',
  },
  titleNoBorder: {
    width: '100%',
    display: 'block',
    margin: '16px',
  },
  noDataDiv: {
    display: 'flex',
    width: '100%',
    padding: '10rem',
    justifyContent: 'center',
    fontSize: 'large',
    color: 'white',
    [theme.breakpoints.down('md')]: {
      padding: '3rem',
      fontSize: '13px',
    },
  },
  circularProgress: {
    position: 'fixed',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
});

class HistoryPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      matches: window.matchMedia('(max-width:480px)').matches, //md:992px
      action: this.props.match.path.split('/')[2],
      userId: localStorage.getItem('jebo_user'),
      DataList: null,
      DataLoding: true,
    };
  }

  reRender = () => {
    this.componentDidMount();
  };

  componentDidMount() {
    this.getMyDataList();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      const path = this.props.location.pathname.split('/')[2];
      this.setState({ action: path, DataList: [], DataLoding: true }, () => {
        this.componentDidMount();
      });
    }
  }

  getMyDataList = async () => {
    const type = this.state.action === 'upload' ? 'vod' : 'order';

    let data;
    if (this.state.action === 'sale') {
      const response = await axiosInstance.get(`${API_URL}/${type}/mySales?userId=${this.state.userId}`);
      data = response.data.value;
    } else {
      const response = await axiosInstance.get(`${API_URL}/${type}/my?userId=${this.state.userId}`);
      data = response.data.value;
    }

    data = data.sort(function (a, b) {
      return a.created_at < b.created_at ? 1 : -1;
    });

    //  console.log(data);
    switch (this.state.action) {
      case 'upload':
        if (data) {
          data.sort(function (a, b) {
            return a.created_at < b.created_at ? 1 : -1;
          });
          this.setState({ DataList: data });
        }
        break;
      case 'download' || 'purchase':
        let _dataArr = [];
        for (let v = 0; v < data.length; v++) {
          if (data[v].status === 'complete') {
            if (data[v]?.downloadCnt > 0) {
              _dataArr.push(data[v]);
            }
          }
        }
        data = _dataArr;
      default:
        let reDataArr = [];
        for await (let _data of data) {
          await this.getVod(_data.vod, _data.seller).then(res => {
            _data['vodDetail'] = res;
            reDataArr.push(_data);
          });
        }

        this.setState({ DataList: reDataArr });
        break;
    }
    this.setState({ DataLoding: false });
  };

  getVod = async (vodId, seller) => {
    const response = await axiosInstance.get(`${API_URL}/vod/${vodId}/${seller}`, { headers: { Pragma: 'no-cache' } });
    const data = response.data.value.Item;

    return await data;
  };

  goItem = (type, data) => {
    let id = data?.id;
    let username = encrypt(data?.userId, 'data-key-1');

    if (type === 'download') {
      id = data?.vodDetail.id;
      username = encrypt(data?.vodDetail.userId, 'data-key-1');
    }

    this.props.history.push(`/vod/Detail?data=${username}&c=${id}`);
  };

  render() {
    const { classes, width } = this.props;
    const { action, DataList, DataLoding } = this.state;
    const noThumb = 'https://via.placeholder.com/320.png?text=No%20Thumbnail';

    if (!this.state.userId) {
      console.log('미로그인 접근불가');
      this.props.history.push('/');
    }

    let title = null;
    if (action === 'upload') title = '업로드';
    else if (action === 'sale') title = '판매';
    else if (action === 'purchase') title = '구매';
    else if (action === 'download') title = '다운로드';

    return (
      <>
        <LayoutPage topbarBorderCheck={true} footer={true}>
          <Grid container justifyContent='center'>
            <Grid container className={classes.UploadListMain}>
              {/* TITLE */}
              <Grid container item xs={12} alignContent='center'>
                <b className={width === 'xs' || width === 'sm' ? classes.XsOrSmWidth : classes.NotXsOrSmWidth}>{`${title} 내역`}</b>
              </Grid>

              {/* HEADER */}
              <CardContent className={classes.headerContainer}>
                <Grid className={width === 'xs' || width === 'sm' ? classes.headerWrapperS : classes.headerWrapperNS} container>
                  {header[action].length > 0 &&
                    header[action].map((row, idx) => (
                      <Grid key={`header-${idx}`} item xs={row.size}>
                        <div className={header[action].length - 1 === idx ? classes.titleNoBorder : classes.titleBorder}>{row.title}</div>
                      </Grid>
                    ))}
                </Grid>
              </CardContent>

              {/* BODY */}
              {!!DataList &&
                DataList.map((item, idx) => {
                  switch (action) {
                    case 'upload':
                      return width === 'xs' || width === 'sm' ? (
                        <UploadBodyMobileComponent data={item} noThumb={noThumb} idx={idx} key={`mobile-body-${idx}`} onClick={() => this.goItem('my', item)} />
                      ) : (
                        <UploadBodyWebComponent data={item} noThumb={noThumb} idx={idx} key={`web-body-${idx}`} onClick={() => this.goItem('my', item)} />
                      );
                    case 'sale':
                      return width === 'xs' || width === 'sm' ? (
                        <SaleBodyMobileComponent data={item} noThumb={noThumb} idx={idx} key={`mobile-body-${idx}`} onClick={() => this.goItem('my', item)} />
                      ) : (
                        <SaleBodyWebComponent data={item} noThumb={noThumb} idx={idx} key={`web-body-${idx}`} onClick={() => this.goItem('my', item)} />
                      );
                    case 'purchase':
                      return width === 'xs' || width === 'sm' ? (
                        <PurchaseBodyMobileComponent data={item} noThumb={noThumb} idx={idx} key={`mobile-body-${idx}`} onClick={() => this.goItem('download', item)} />
                      ) : (
                        <PurchaseBodyWebComponent data={item} noThumb={noThumb} idx={idx} key={`web-body-${idx}`} onClick={() => this.goItem('download', item)} />
                      );
                    case 'download':
                      return item.status === 'complete' ? (
                        width === 'xs' || width === 'sm' ? (
                          <DownloadBodyMobileComponent data={item} noThumb={noThumb} idx={idx} key={`mobile-body-${idx}`} onClick={() => this.goItem('download', item)} />
                        ) : (
                          <DownloadBodyWebComponent data={item} noThumb={noThumb} idx={idx} key={`web-body-${idx}`} onClick={() => this.goItem('download', item)} />
                        )
                      ) : null;
                    default:
                      return null;
                  }
                })}
              {DataList?.length === 0 && !DataLoding && <div className={classes.noDataDiv}>{`${title} 내역이 존재하지 않습니다.`}</div>}

              {/* footer */}
              {action === 'sale' && !!DataList && <SaleFooterComponent data={DataList} reRender={this.reRender} />}

              {/* lodingBar */}
              {DataLoding && <CustomCircularProgress />}
            </Grid>
          </Grid>
        </LayoutPage>
      </>
    );
  }
}

export default withStyles(styles)(withWidth()(HistoryPage));
