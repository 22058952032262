import React, { Component } from 'react';
import { Grid, withStyles, Tabs, Tab, withWidth } from '@material-ui/core';
import axios from 'axios';
import { API_URL } from '../constants';

const CustomTabs = withStyles(theme => ({
  root: {
    color: '#ffffff',
    width: '70%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  indicator: {
    backgroundColor: theme.palette.primary.main,
  },
}))(props => <Tabs {...props} />);

const CustomTab = withStyles(theme => ({
  root: {
    minWidth: '115px',
    [theme.breakpoints.down('md')]: {
      padding: 0,
      minWidth: '65px',
    },
  },
  selected: {},
}))(props => <Tab {...props} />);

const styles = theme => ({});

class KeywordTaps extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabValue: 'all', // 탭 시작값
      codeData: '',
      matches: window.matchMedia('(max-width:991px)').matches,
    };
  }
  componentDidMount() {
    axios
      .get(`${API_URL}/code`, { headers: { Pragma: 'no-cache' } })
      .then(response => {
        let res = response.data.value;

        res = res.sort(function (a, b) {
          return Number(a.order) > Number(b.order) ? 1 : -1;
        });

        this.setState({ codeData: res });
      })
      .catch(console.log);
  }

  render() {
    const CODE_DATA = this.state.codeData;
    const { children, tabData, onChange } = this.props;

    return (
      <div>
        <div style={{ width: '100%', height: '1px' }} id='move' />
        <Grid container justifyContent='center' style={{ position: 'sticky', borderBottom: '1px solid rgb(143, 143, 143, .5', backgroundColor: '#171A1D', top: 0, zIndex: 10 }}>
          <Grid
            container
            justifyContent='center'
            style={{
              borderColor: '#aeaeae',
              borderBottom: this.state.matches ? 0 : '1px solid',
            }}
            item
            xs={11}
          >
            <CustomTabs value={tabData} onChange={onChange} scrollButtons='auto' variant='scrollable'>
              {CODE_DATA &&
                CODE_DATA.filter(code => code.group === 'topic' || code.group === 'equipment' || code.group === 'category').map((codeList, code_Idx) => (
                  <CustomTab key={code_Idx} label={`${codeList.value}`} value={`${codeList.id}`} />
                ))}
            </CustomTabs>
          </Grid>
        </Grid>
        {children}
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(withWidth()(KeywordTaps));
