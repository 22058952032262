import React, { Component } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, Grid, withStyles } from '@material-ui/core';

const styles = theme => ({
  inputGrid: {
    padding: theme.spacing(1, 3),
  },

  loginInput: {
    '& input': {
      color: '#fefefe',
      padding: '0 1vw ',
      height: '5vh',
      backgroundColor: '#0c0e10',
      borderRadius: '5px',
      [theme.breakpoints.down('md')]: {
        padding: '0 4vw ',
        height: '50px',
      },
    },
  },

  colorBtn: {
    width: '95%',
    backgroundColor: '#e61737',
    border: '2px solid #e61737',
    borderRadius: '5px',
    color: '#ffffff',
    height: '5vh',
    fontWeight: 'bold',
    margin: theme.spacing(1, 0),
    [theme.breakpoints.down('md')]: {
      border: '1px solid #e61737',
      borderRadius: '5px',
      height: '50px',
      margin: theme.spacing(1, 0),
    },
  },
  btn: {
    width: '95%',
    // backgroundColor: '#fff',
    border: '2px solid #fff',
    borderRadius: '5px',
    color: '#ffffff',
    height: '5vh',
    fontWeight: 'bold',
    margin: theme.spacing(1, 0),
    [theme.breakpoints.down('md')]: {
      border: '1px solid #ffffff',
      borderRadius: '5px',
      height: '50px',
      margin: theme.spacing(1, 0),
    },
  },
  dialogBorder: {
    '& .MuiPaper-rounded': {
      borderRadius: '0px',
    },
  },
});

class DeleteDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      matches: window.matchMedia('(max-width:991px)').matches,
      pageStatus: 0,
      name: localStorage.getItem('jebo_name'),
      userId: localStorage.getItem('jebo_user'),
    };
  }

  handleChange = (event, newValue) => {
    this.setState({ [event]: newValue });
  };

  doDelete = () => {
    if (localStorage.getItem('jebo_user')) {
      this.setState({ pageStatus: 1 });
    } else {
    }
  };

  doneDelete = () => {
    localStorage.clear();
    sessionStorage.clear();
    this.props.onClose();
    this.props.handleCloseMypage();
  };

  render() {
    const { open, onClose, classes } = this.props;
    return (
      <Dialog className={classes.dialogBorder} fullWidth={true} maxWidth={'xs'} onClose={onClose} open={open} style={{ overflow: 'hidden' }}>
        <DialogTitle style={{ backgroundColor: '#171A1D' }}>
          <Grid container justifyContent='center'>
            <Grid item xs={2}></Grid>
            <Grid container justifyContent='center' item xs={12} md={8}>
              {this.state.pageStatus === 0 && <b style={{ color: '#ffffff' }}>정말 탈퇴하시겠습니까?</b>}
              {this.state.pageStatus === 1 && <b style={{ color: '#ffffff' }}>탈퇴 완료</b>}
            </Grid>
            <Grid container item xs={2} justifyContent='flex-end'>
              {/* <IconButton onClick={onClose}>
                  <CloseIcon style={{ color: '#ffffff' }} />
                </IconButton> */}
            </Grid>
          </Grid>
        </DialogTitle>

        <DialogContent style={{ backgroundColor: '#171A1D' }}>
          <Grid id='positioning_grid' container justifyContent='center' alignItems='center'>
            <Grid
              item
              md={8}
              xs={12}
              style={{
                textAlign: 'center',
                wordBreak: 'keep-all',
                fontWeight: 'bold',
                color: '#fff',
                padding: this.props.theme.spacing(0, 0, 3),
              }}
            >
              {this.state.pageStatus === 0 && <span>지금 탈퇴하시더라도 약관에 따라 일정 기간동안 계정이 유지될 수 있습니다.</span>}
              {this.state.pageStatus === 1 && (
                <span>
                  {this.state.name} &nbsp; 님! 탈퇴가 완료되었습니다.
                  <br />
                  그동안 제보마켓을 이용해 주셔서 감사합니다.
                </span>
              )}
            </Grid>

            {this.state.pageStatus === 0 && (
              <Grid container justifyContent='center' item xs={12}>
                <Grid container justifyContent='center' item xs={6}>
                  <Button className={classes.btn} onClick={onClose}>
                    아니오
                  </Button>
                </Grid>
                <Grid container justifyContent='center' item xs={6}>
                  <Button className={classes.colorBtn} onClick={this.doDelete}>
                    계속
                  </Button>
                </Grid>
              </Grid>
            )}

            {this.state.pageStatus === 1 && (
              <Grid container justifyContent='center' item xs={10}>
                <Grid container justifyContent='center'>
                  <Button className={classes.btn} onClick={this.doneDelete}>
                    확인
                  </Button>
                </Grid>
              </Grid>
            )}
          </Grid>
        </DialogContent>
      </Dialog>
    );
  }
}

export default withStyles(styles, { withTheme: true })(DeleteDialog);
