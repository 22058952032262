import React from 'react';
import ImageItem from './ImageItem';
import { API_URL, createVodUrl, encrypt } from '../constants';
import queryString from 'query-string';

import { Grid, withStyles } from '@material-ui/core';
import Masonry from 'react-masonry-css';
import { throttle, debounce } from 'loadsh';

import axiosInstance from '../constants/AxiosInstance';
import KeywordTaps from '../components/KeywordTaps';
import { connect } from 'react-redux';

import LayoutPage from '../components/LayoutPage';

// import FileUpload from '../components/FileUpload/FileUpload';

const styles = theme => ({
  container: {
    width: '100%',
    height: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
    position: 'relative',
    backgroundColor: '#171A1D',
  },
  masonry: {
    display: 'flex',
    width: '100%',
    '& > div': {
      padding: '5px',
    },
  },
  noData: {
    color: '#FFF',
    height: '300px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

// 이미지배열 라이브러리 가로배열 반응형 갯수 (todo: grid 크기로 수정필요)
const breakpointColumnsObj = {
  default: 4,
  1100: 3,
  700: 2,
  500: 1,
};

class SearchListPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      matches: window.matchMedia('(max-width:480px)').matches, //md:992px
      uploadData: [],
      query: '',
      menuDrawer: true, // 웹 메뉴버튼 Drawer
      mobMenuDrawer: false, // 모바일메뉴버튼 Drawer
      searchBody: this.props.location.state?.searchBody,
      tabValue: 'all',
    };
    this.refList = [];

    this.getVod = this.getVod.bind(this);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.videoScroll, true);
    window.addEventListener('resize', this.resizePage);
    this.getVod();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.videoScroll, true);
    window.removeEventListener('resize', this.resizePage);
  }

  resizePage = e => {
    this.setState({ matches: window.matchMedia('(max-width:480px)').matches });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location.search.length > 0 && this.props.location.search.length > 0) {
      if (prevProps.location.search !== this.props.location.search) {
        this.componentDidMount();
      }
    }
  }

  videoScrollDebounce = debounce(() => {
    this.videoEnable();
  }, 200);

  videoScrollThrottle = throttle(() => {
    this.videoDisable();
  }, 100);

  webMenuOpen = event => {
    this.setState({ menuDrawer: true });
  };

  webMenuClose = event => {
    this.setState({ menuDrawer: false });
  };

  mobMenuOpen = event => {
    this.setState({ mobMenuDrawer: true });
  };

  mobMenuClose = event => {
    this.setState({ mobMenuDrawer: false });
  };

  videoScrollDebounce = debounce(() => {
    this.videoEnable();
  }, 200);

  videoScrollThrottle = throttle(() => {
    this.videoDisable();
  }, 100);

  getVod = async search => {
    const query = queryString.parse(this.props.location.search);

    let response = null;
    let responseData = null;

    if (!query.search) {
      response = await axiosInstance.get(`${API_URL}/vod`, { headers: { Pragma: 'no-cache' } });
      responseData = response.data.value;
    } else {
      response = await axiosInstance.get(`${API_URL}/vod/search?q=${query.search}&equipmentSearchValue=${query.equipmentSearchValue}&topicSearchValue=${query.topicSearchValue}`, {
        headers: { Pragma: 'no-cache' },
      });
      responseData = response.data.value.hits.hits;
    }

    if (responseData) {
      let ArrayRow = [];
      for await (let awaitData of responseData) {
        let rowData = awaitData;
        if (query.search) rowData = awaitData._source;
        if (rowData.vod[0]) {
          await createVodUrl(rowData.vod[0].mark).then(result => {
            rowData.vod[0].vodUrl = result;

            ArrayRow.push(rowData);
          });
        }
      }

      ArrayRow.sort(function (a, b) {
        return a.created_at < b.created_at ? 1 : -1;
      });

      this.setState({ uploadData: ArrayRow });
    }
  };

  searchDataUpdate = e => {
    this.setState({ searchData: e.target.value });
    if (this.state.delay) {
      clearTimeout(this.state.delay);
    }
    let delay = setTimeout(() => {
      this.getVod(this.state.searchData);
    }, 200);
    this.setState({ delay: delay });
  };

  handleChange = (event, newValue) => {
    this.setState({ tabValue: newValue });
  };

  videoScroll = () => {
    if (this.state.matches) {
      this.videoScrollThrottle();
      this.videoScrollDebounce();
    }
  };

  videoDisable = () => {
    for (let i = 0; i < this.refList.length; i++) {
      let videoEl = this.refList[i];
      if (videoEl && videoEl.current) {
        if (!videoEl.current.paused) {
          videoEl.current.pause();
          videoEl.current.currentTime = 0;
        }
      }
    }
  };

  videoEnable = () => {
    if (this.refList.length > 0) {
      let windowHeight = window.innerHeight;
      for (let i = 0; i < this.refList.length; i++) {
        let videoEl = this.refList[i];
        let videoHeight = videoEl.current.clientHeight;
        let videoClientRect = videoEl.current.getBoundingClientRect().top;

        if (videoClientRect <= windowHeight - videoHeight * 0.5 && videoClientRect >= 0 - videoHeight * 0.5) {
          if (videoEl && videoEl.current) {
            if (videoEl.current.paused) {
              videoEl.current.play();
            }
          }
          break;
        } else {
          if (videoEl && videoEl.current) {
            if (!videoEl.current.paused) {
              videoEl.current.pause();
              videoEl.current.currentTime = 0;
            }
          }
        }
      }
    }
  };

  goItem = data => {
    let id = data.id;
    let username = encrypt(data.userId, 'data-key-1');

    this.props.history.push(`/vod/Detail?data=${username}&c=${id}`);
  };

  scrollToTop = () => {
    let keyWordTap = document.getElementById('keyWordTap');

    if (keyWordTap !== null) {
      keyWordTap.scrollIntoView(true);
    }
  };

  handleTabChange = (event, newValue) => {
    this.scrollToTop();
    this.setState({ tabValue: newValue });
  };

  dataFilter = vodList => {
    const { tabValue } = this.state;

    if (tabValue !== 'all') {
      vodList = vodList.filter(item => {
        return item.equipment.includes(tabValue) || item.topic.includes(tabValue);
      });
    }

    return vodList;
  };

  render() {
    const { classes } = this.props;
    const { uploadData, tabValue } = this.state;
    let _uploadData = [...uploadData];

    _uploadData = this.dataFilter(_uploadData);

    return (
      <LayoutPage topbarBorderCheck={false} footer={false} bannerSection={true}>
        {/* KEYWORD TABS */}
        <div id='keyWordTap' />
        <KeywordTaps tabData={tabValue} onChange={this.handleTabChange} />

        <Grid container justifyContent='center' style={{ padding: this.state.matches ? '2vh 0' : '4vh 0' }}>
          <Grid item xs={11}>
            {_uploadData.length > 0 ? (
              <Masonry className={classes.masonry} columnClassName={classes.masonryColum} breakpointCols={breakpointColumnsObj}>
                {_uploadData.length > 0 && _uploadData.map((item, idx) => <ImageItem key={idx} matches={this.state.matches} item={item} idx={idx} goItem={this.goItem} />)}
              </Masonry>
            ) : (
              <div className={classes.noData}>데이터가 존재하지 않습니다.</div>
            )}
          </Grid>
        </Grid>
      </LayoutPage>
    );
  }
}

const mapStateToProps = state => {
  return {
    menuValue: state.changeMenu.value,
  };
};

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps)(SearchListPage));
