import React from 'react';
import { withStyles, Grid, withWidth } from '@material-ui/core';

import ConditionalButton from './ConditionalButton';
import ReportButton from '../../components/ReportButton';
import { returnUrl, setCreatedAt } from '../../components/ListComponets';

const styles = theme => ({
  container: {
    color: '#ffffff',
    textAlign: 'center',
    border: 'none',
    padding: '15px 0',
    borderBottom: '1px solid rgb(143, 143, 143, .5)',
    alignItems: 'center',
  },
  stateWrapper: {
    width: '97%',
    display: 'flex',
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  btnWrapper: {
    width: '97%',
  },
  r1c1Wrapper: {
    textAlign: 'left',
    fontWeight: '600',
  },
  noBody: {
    width: '100%',
    margin: '0',
    fontSize: '0.85rem',
    marginBottom: '3px',
  },
  titleBody: {
    width: '100%',
    margin: '0',
    fontSize: '0.9rem',
    marginBottom: '10px',
    wordBreak: 'break-all',
  },
  dateBody: {
    width: '100%',
    margin: '0',
    fontSize: '0.85rem',
    alignSelf: 'flex-end',
  },
  r1c2Wrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    position: 'relative',
  },
  imgWrapper: {
    width: '100%',
    cursor: 'pointer',
  },
  imgBody: {
    height: '100%',
    display: 'flex',
    width: '100%',
    position: 'absolute',
    objectFit: 'contain',
    border: '1px solid rgb(143, 143, 143, .5)',
  },
});

class UploadBodyMobileComponent extends React.Component {
  render() {
    const { classes, idx, data, noThumb, onClick } = this.props;

    return (
      <Grid className={classes.container} container>
        {/* ROW 1 */}
        <Grid container item xs={12}>
          {/* ROW 1 COL 1 */}
          {/* CONTENT */}
          <Grid className={classes.r1c1Wrapper} container item xs={7}>
            <p className={classes.noBody}>{`No.${idx + 1}`}</p>
            <p className={classes.titleBody}>{data.title}</p>
            <p className={classes.dateBody}>{setCreatedAt(data.created_at)}</p>
          </Grid>

          {/* ROW 1 COL 2 */}
          {/* BUTTON */}
          <Grid container item xs={5}>
            <Grid className={classes.r1c2Wrapper} container item xs={12}>
              <div className={classes.imgWrapper} onClick={onClick}>
                <img className={classes.imgBody} src={data.vod[0]?.thumb ? returnUrl(data.vod[0]?.thumb) : noThumb} alt='vod_thumb' />
              </div>
            </Grid>
          </Grid>
        </Grid>

        {/* ROW 2 */}
        <Grid container item xs={12}>
          <Grid container item xs={6}>
            <div className={classes.stateWrapper}>
              {/* 
                                판매중 : PUBLIC or PRIVATE
                                거절 : REJECTED
                            */}
              <ConditionalButton code={data.vod_status} />
            </div>
          </Grid>
          <Grid className={classes.btnContainer} container item xs={6}>
            <div className={classes.btnWrapper}>
              <ReportButton data={data} />
            </div>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(withWidth()(UploadBodyMobileComponent));
