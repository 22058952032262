import dotenv from 'dotenv';
import awsconfig from '../aws-exports';
import Amplify, { Auth, Storage } from 'aws-amplify';
import axiosInstance from './AxiosInstance';
import UploadToS3 from '../components/FileUpload/UploadToS3';
import VideoToThumb from 'video-thumb-generator';
import CryptoJS from 'crypto-js';
import moment from 'moment';

dotenv.config();
Amplify.configure(awsconfig);

export const API_URL = 'https://api.jebomarket.com';
export const VODAPI_URL = 'https://vodapi.jebomarket.com';
export const JEBOMARKET_IMG = '/images/jbmarket';
// export const API_URL = 'http://localhost:3000';
export const VOD_URL = 'https://s3.ap-northeast-2.amazonaws.com/file.jebomarket.com/vod/vod_1630561249868_16309947580531.mp4/';
export const KAKAKO_KEY = '079bcff24b87a10343d3880547ea6122';
export const FACEBOOK_KEY = '351960399970939';
export const GOOGLE_KEY = '1053686177924-5s13l6n0dqsb40idalb5uk3h5kdp9qc6.apps.googleusercontent.com';
export const NAVER_KEY = '5QgeHd2Qw2oh5SzzFRQs';
export const NAVER_SECRET_KEY = 'bcmAwThAxg';
export const NAVER_CALLBACK = 'https://www.jebomarket.com/callback';

export const getUser = () => {
  return Auth.currentAuthenticatedUser()
    .then(userData => userData)
    .catch(() => {
      console.log('Not signed in');
    });
};

export const getAWSCredentials = (response, snsType, userInfo) => {
  return new Promise((resolve, reject) => {
    const { accessToken /* expiresIn */ } = response;
    if (!accessToken) {
      return;
    }

    Auth.signIn(userInfo.userId, userInfo.userId.replace(snsType + '_', '')).then(user => {
      const idToken = user.signInUserSession.idToken;
      localStorage.setItem('jebo_user', user.username);
      localStorage.setItem('jebo_jwt', idToken.getJwtToken());
      localStorage.setItem('jebo_name', user.attributes.name);
      localStorage.setItem('jebo_email', user.attributes.email);

      document.location.reload(true);
      resolve(true);
    });
  });
};

export const readFile = (file, limit) => {
  // if (limit < 0) limit = 5;
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    file.type.split('/')[0] === 'image' ? fileReader.readAsDataURL(file) : fileReader.readAsArrayBuffer(file);

    fileReader.onload = _read => {
      if (limit > 0 && _read.total > 1000000 * limit) {
        reject(`File too large : limit ${limit}MB`);
      } else {
        resolve(_read);
      }
    };
  });
};

export const base64ArrayBuffer = arrayBuffer => {
  var base64 = 'data:video/mp4;base64,';
  var encodings = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';

  var bytes = new Uint8Array(arrayBuffer);
  var byteLength = bytes.byteLength;
  var byteRemainder = byteLength % 3;
  var mainLength = byteLength - byteRemainder;

  var a, b, c, d;
  var chunk;

  // Main loop deals with bytes in chunks of 3
  for (var i = 0; i < mainLength; i = i + 3) {
    // Combine the three bytes into a single integer
    chunk = (bytes[i] << 16) | (bytes[i + 1] << 8) | bytes[i + 2];

    // Use bitmasks to extract 6-bit segments from the triplet
    a = (chunk & 16515072) >> 18; // 16515072 = (2^6 - 1) << 18
    b = (chunk & 258048) >> 12; // 258048   = (2^6 - 1) << 12
    c = (chunk & 4032) >> 6; // 4032     = (2^6 - 1) << 6
    d = chunk & 63; // 63       = 2^6 - 1

    // Convert the raw binary segments to the appropriate ASCII encoding
    base64 += encodings[a] + encodings[b] + encodings[c] + encodings[d];
  }

  // Deal with the remaining bytes and padding
  if (byteRemainder === 1) {
    chunk = bytes[mainLength];

    a = (chunk & 252) >> 2; // 252 = (2^6 - 1) << 2

    // Set the 4 least significant bits to zero
    b = (chunk & 3) << 4; // 3   = 2^2 - 1

    base64 += encodings[a] + encodings[b] + '==';
  } else if (byteRemainder === 2) {
    chunk = (bytes[mainLength] << 8) | bytes[mainLength + 1];

    a = (chunk & 64512) >> 10; // 64512 = (2^6 - 1) << 10
    b = (chunk & 1008) >> 4; // 1008  = (2^6 - 1) << 4

    // Set the 2 least significant bits to zero
    c = (chunk & 15) << 2; // 15    = 2^4 - 1

    base64 += encodings[a] + encodings[b] + encodings[c] + '=';
  }

  return base64;
};

export const fileUpload = async (uploadFile, fileId, uploadKey, folder, apiUrl, callback) => {
  const param = uploadKey === 'vod' ? { id: fileId, userId: localStorage.getItem('jebo_user') } : { id: fileId };

  const upKey = uploadKey ? uploadKey : 'files';
  const fileName = `${param.userId}_${moment().format('YYYYMMDDHHmm')}`;
  let upFile = uploadFile[0];

  const result = await UploadToS3(upFile, folder + '/' + localStorage.getItem('jebo_user'), fileId, callback, fileName);

  if (uploadKey === 'picture') {
    param['profile'] = result.path;
    param[upKey] = {
      key: result.key,
      path: result.path,
      name: uploadFile[0].name,
      type: uploadFile[0].type,
      size: uploadFile[0].size,
    };
  } else {
    param[upKey] = [];

    param[upKey].push({
      key: result.key,
      path: result.path,
      duration: uploadFile[0].duration,
      resolution: uploadFile[0].resolution,
      thumb: `${param.userId}/${param.id}_${fileName}.jpg`,
      mark: `vod/${param.userId}/mark_${param.id}_${fileName}.mp4`,
      enc_vod: `vod/${param.userId}/vod_h264_${param.id}_${fileName}.mp4`,
      name: fileName,
      type: uploadFile[0].type,
      size: uploadFile[0].size,
    });
  }

  return await axiosInstance.put(apiUrl, param);
};

export const fileThumbnail = file => {
  const videoToThumb = new VideoToThumb(file);

  return new Promise((resolve, reject) => {
    videoToThumb
      .load()
      .size([320, 240])
      .positions([1])
      .type('base64')
      .error(function (err) {
        reject(JSON.stringify(err));
      })
      .done(img => {
        resolve(dataURLtoFile(img[0], `${+new Date()}.jpg`));
      });
  });
};

// #### DO NOT DELETE ####
const dataURLtoFile = (dataurl, filename) => {
  let arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  let file = new File([u8arr], filename, { type: mime });

  file['path'] = dataurl;
  return file;
};

export const phoneNumber = value => {
  value = value.replace(/[^0-9]/g, '');
  return value.replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/, '$1-$2-$3');
};

export const createVodUrl = async vodKey => {
  return await Storage.get(vodKey, { customPrefix: { public: '' } });
};

export const encrypt = (data, key) => {
  return CryptoJS.AES.encrypt(data, key).toString();
};

export const decrypt = (text, key) => {
  try {
    const bytes = CryptoJS.AES.decrypt(text, key);
    return bytes.toString(CryptoJS.enc.Utf8);
  } catch (err) {
    console.log(err);
    return;
  }
};

export const changeFile = async (e, maxCount) => {
  const files = e.target.files;

  if (maxCount && files.length > maxCount) {
    alert(`max file count : ${maxCount}`);
    return;
  }

  let arr = [];
  let buffs = [];
  let reslut = {};

  for (let i = 0; i < files.length; i++) {
    const temp = files[i];

    await readFile(temp, -1)
      .then(file => {
        try {
          //모바일에서 용량제한으로 되지 않는 것으로 사료됨
          //이때 특별한 내용없이 죽어요. reload .. [webkit]. size 200mb
          //배열일 때 잘 될지 모르겠음. 우선 처리하겠음.
          temp['path'] = file.target.result;

          if (file.total < 209715200) {
            var video = new Blob([new Uint8Array(file.target.result)]);
            let buff = URL.createObjectURL(video);
            buffs.push(buff);
          } else {
            buffs.push(null);
          }
        } catch (e) {
          if (buffs.length <= i) {
            buffs.push(null);
          } else {
            buffs[i] = null;
          }
        }
      })
      .catch(console.log);

    /* 해당 부분은 MOV 등의 특정확장자 문제로 인하여 서버에서 인코딩 할때 처리
    await fileThumbnail(files[i])
      .then((file) => {
        temp['thumbnail'] = file;
      })
      .catch((e) => {
        console.log('error thumbnail');
        console.log(e);
      });
    */

    arr.push(temp);
  }

  reslut = { arr: arr, buffs: buffs };
  return reslut;
};
