import React from 'react';
import ConditionalButton from './ConditionalButton';
import { withStyles, Grid, withWidth } from '@material-ui/core';
import { replaceValue, getCodeList, setCreatedAt, returnUrl } from '../../components/ListComponets';

const styles = theme => ({
  container: {
    color: '#ffffff',
    textAlign: 'center',
    border: 'none',
    padding: '15px 0',
    borderBottom: '1px solid rgb(143, 143, 143, .5)',
    alignItems: 'center',
  },
  r1c1Wrapper: {
    textAlign: 'left',
    fontWeight: '600',
  },
  noBody: {
    width: '100%',
    margin: '0',
    fontSize: '0.85rem',
    marginBottom: '3px',
  },
  titleBody: {
    width: '100%',
    margin: '0',
    fontSize: '0.9rem',
    marginBottom: '10px',
  },
  dateBody: {
    width: '100%',
    margin: '0',
    fontSize: '0.85rem',
    alignSelf: 'flex-end',
  },
  r1c2Wrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    position: 'relative',
  },
  imgWrapper: {
    width: '100%',
    cursor: 'pointer',
  },
  imgBody: {
    height: '100%',
    display: 'flex',
    width: '100%',
    position: 'absolute',
    objectFit: 'contain',
    border: '1px solid rgb(143, 143, 143, .5)',
  },
  buttonContainer: {
    justifyContent: 'flex-end',
  },
  amountBody: {
    width: '100%',
    alignSelf: 'center',
    fontSize: '13px',
    textAlign: 'center',
    fontWeight: '600',
  },
  statusBtn: {
    width: '100%',
    '& > button': {
      height: '30px',
      fontSize: '12px',
    },
  },
});
class DownloadBodyMobileComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      vodList: [],
      price: '',
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.videoScroll, true);
    getCodeList('price').then(response => {
      this.setState({ price: `${replaceValue(response, this.props.data.amount)} ₩` });
    });
  }

  render() {
    const { classes, idx, data, noThumb, onClick } = this.props;

    return (
      <Grid className={classes.container} container>
        <Grid container item xs={12}>
          <Grid className={classes.r1c1Wrapper} container item xs={7}>
            <p className={classes.noBody}>{`No.${idx + 1}`}</p>
            <p className={classes.titleBody}>{data.vodDetail.title}</p>
            <p className={classes.dateBody}>{setCreatedAt(data.created_at)}</p>
          </Grid>

          <Grid container item xs={5}>
            <Grid className={classes.r1c2Wrapper} container item xs={12}>
              <div className={classes.imgWrapper} onClick={onClick}>
                <img className={classes.imgBody} src={data.vodDetail.vod[0]?.thumb ? returnUrl(data.vodDetail.vod[0]?.thumb) : noThumb} alt='vod_thumb' />
              </div>
            </Grid>
          </Grid>
        </Grid>

        <Grid className={classes.buttonContainer} container item xs={12}>
          <Grid container item xs={3} style={{ alignContent: 'center' }}>
            <div className={classes.statusBtn}>
              <ConditionalButton code={data.status} />
            </div>
          </Grid>
          <Grid container item xs={3}>
            <div className={classes.amountBody}>{this.state.price}</div>
          </Grid>
          <Grid container item xs={6} style={{ justifyContent: 'flex-end' }}>
            <div style={{ width: '90%', display: 'flex' }}>
              <ConditionalButton code={`Download${data.status}`} data={data} />
            </div>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(withWidth()(DownloadBodyMobileComponent));
