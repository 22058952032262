import React from 'react';
import { Grid, withStyles } from '@material-ui/core';
import { returnUrl } from '../components/ListComponets';
import ReactJWPlayer from 'react-jw-player';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const styles = (theme) => ({
  videoWrapper: {
    '& .jwplayer': {
      width: '100% !important',
      // height: '100% !important',
      borderTopLeftRadius: '15px',
      borderTopRightRadius: '15px',
      zIndex: 1,
    },
    '& .jw-video': {
      objectFit: 'cover !important',
      borderTopLeftRadius: '15px',
      borderTopRightRadius: '15px',
    },
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    position: 'absolute',
    borderTopLeftRadius: '15px',
    borderTopRightRadius: '15px',
    cursor: 'pointer',
  },
  imgWrapper: {
    width: '100%',
    overflow: 'hidden',
    borderTopLeftRadius: '15px',
    borderTopRightRadius: '15px',
    cursor: 'pointer',
  },
  vodLoding: {
    width: '100%',
    overflow: 'hidden',
    borderTopLeftRadius: '15px',
    borderTopRightRadius: '15px',
    cursor: 'pointer',
    // position: 'relative',
    // opacity: '0.4',
    filter: 'brightness(0.5)',
  },
  titleWrapper: {
    color: '#ffffff',
    fontWeight: 'bold',
  },
  titleContent: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  imageItemBox: {
    backgroundColor: '#1D1E22',
    margin: '10px 0',
    borderRadius: 15,
    cursor: 'pointer',
  },
  imgBox: {
    width: '100%',
    '& > span': {
      width: '100%',
    },
  },
});

class ImageItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      vodPlay: false,
      vodLoding: false,
      vodLoding_: true,
      delay: null,
    };
  }

  componentWillUnmount() {
    this.setState({ vodPlay: false, vodLoding: false, vodLoding_: true, delay: null });
  }

  handleMouseOver = (id) => {
    if (this.state.delay) {
      clearTimeout(this.state.delay);
    }
    this.setState({ vodLoding: true });
    let delay = setTimeout(() => {
      this.setState({ vodPlay: true });
    }, 1000);
    this.setState({ delay: delay });
  };

  handleMouseLeave = (id) => {
    if (this.state.delay) {
      clearTimeout(this.state.delay);
    }
    setTimeout(() => {
      this.setState({ vodPlay: false, vodLoding: false, vodLoding_: true });
    }, 100);
  };

  onReady(event, id) {
    // console.log(event);
    // interact with JW Player API here
    const player = window.jwplayer(id);
    // player.pause();
    // console.log(player);
    player.seek(0);
    player.play();
  }

  onVideoLoad(e) {
    // console.log('onVideoLoad');
    // console.log(e);
  }

  onMeta(e, id) {
    // console.log('onMeta');
    // console.log(e);
    const player = window.jwplayer(id);
    let rate = e.height / e.width;
    let height = player.getWidth() * rate;
    player.resize(player.getWidth(), height);
    this.setState({ vodLoding_: false });
  }

  render() {
    const { classes, matches, item, idx, goItem } = this.props;

    return (
      <Grid container justifyContent="center" className={classes.imageItemBox} key={idx} onClick={() => goItem(item)}>
        <Grid
          container
          style={{ position: 'relative' }}
          justifyContent="center"
          item
          xs={12}
          md={12}
          onMouseOver={matches ? null : (e) => this.handleMouseOver(item.id)}
          onMouseLeave={matches ? null : (e) => this.handleMouseLeave(item.id)}
        >
          <div className={classes.imgBox} style={this.state.vodLoding_ ? { zIndex: 2 } : { zIndex: 0 }}>
            <LazyLoadImage
              className={this.state.vodLoding && this.state.vodLoding_ ? classes.vodLoding : classes.imgWrapper}
              effect="blur"
              src={item.vod[0]?.thumb && returnUrl(item.vod[0]?.thumb)}
            />
          </div>
          {/* <img
            className={this.state.vodLoding && this.state.vodLoding_ ? classes.vodLoding : classes.imgWrapper}
            src={item.vod[0]?.thumb && returnUrl(item.vod[0]?.thumb)}
            alt="vod_thumb"
          /> */}

          {this.state.vodPlay && (
            <ReactJWPlayer
              aspectratio
              className={classes.videoWrapper}
              playerId={item.id}
              playerScript="https://cdn.jwplayer.com/libraries/grRptRQ9.js"
              playlist={[{ file: item.vod[0].vodUrl, image: returnUrl(item.vod[0]?.thumb) }]}
              //isAutoPlay={true}
              onReady={(e) => {
                this.onReady(e, item.id);
              }}
              onVideoLoad={(e) => {
                this.onVideoLoad(e);
              }}
              onMeta={(e) => {
                this.onMeta(e, item.id);
              }}
              customProps={{
                controls: false,
                repeat: true,
                mute: false,
                width: '100%',
                height: '100%',
              }}
            />
          )}
        </Grid>
        <Grid item xs={10} md={10} style={{ padding: matches ? '3vh 0' : '1vh 0' }}>
          <Grid
            className={classes.titleWrapper}
            container
            style={{ padding: matches ? '0.25vh 0' : '0.5vh 0', fontSize: matches ? '1em' : '1.2em' }}
          >
            <div className={classes.titleContent}>{item.title}</div>
          </Grid>
          <Grid container style={{ color: 'rgba(170, 170, 170, 0.5)', fontWeight: 'bold' }}>
            {item.date}
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles, { withTheme: true })(ImageItem);
