import React, { Component } from 'react';
import { ThemeProvider } from '@material-ui/styles';
import { createTheme } from '@material-ui/core/styles';
import './App.css';
import Routes from './routes';

const theme = createTheme({
  palette: {
    // 테마컬러 적색계열
    primary: {
      main: '#f60337',
    },
    // 테마컬러 회색계열
    secondary: {
      main: '#cccccc',
    },

    third: {
      main: '#ffffff',
    },
  },
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: ['"Noto Sans"', '"Lato"', 'sans-serif'].join(','),
  },
});

class App extends Component {
  render() {
    return (
      <div style={{ height: '100%', backgroundColor: '#ffffff' }}>
        <ThemeProvider theme={theme}>
          <Routes />
        </ThemeProvider>
      </div>
    );
  }
}

export default App;
