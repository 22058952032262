import React from 'react';
import { Tooltip, Button, withStyles } from '@material-ui/core';
import VodEditDialog from '../dialog/VodEditDialog';
import ToolTip from './ToolTip';

const styles = theme => ({
  monoBtn: {
    backgroundColor: 'transparent',
    border: '1px solid #ffffff',
    color: '#fff',
    fontWeight: 'bold',
    fontSize: 'large',
    height: '50px',
    margin: theme.spacing(1, 0),
    padding: theme.spacing(2, 0),
    width: '100%',
  },
});

class VodEditButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      report: {
        tooltip: false,
        dialog: false,
      },
    };

    this.setReportState = this.setReportState.bind(this);
  }

  setReportState = obj => {
    let newObj = this.state.report;

    for (let key in obj) newObj[key] = obj[key];

    this.setState({ report: newObj });

    if (newObj.dialog === true) {
      setTimeout(() => {
        newObj.tooltip = false;
        this.setState({ report: newObj });
      }, 3000);
    }
  };

  render() {
    const { classes, data, reRender, history } = this.props;

    return (
      <>
        <ToolTip title='vodEdit' open={this.state.report.tooltip} arrow={true} placement='bottom-end' color='gray'>
          <Button fullWidth={true} className={classes.monoBtn} onClick={() => this.setReportState({ dialog: true, tooltip: true })} style={{ margin: '0' }}>
            영상정보 변경
          </Button>
        </ToolTip>

        <VodEditDialog data={data} open={this.state.report.dialog} onClose={() => this.setReportState({ dialog: false })} disableEscapeKeyDown={true} reRender={reRender} history={history} />
      </>
    );
  }
}

export default withStyles(styles, { withTheme: true })(VodEditButton);
