import React from 'react';
import { Button, withStyles } from '@material-ui/core';
import ReportDialog from '../dialog/ReportDialog';
import ToolTip from './ToolTip';
import { encrypt } from '../constants';

const styles = theme => ({
  monoBtn: {
    backgroundColor: 'transparent',
    border: '1px solid #ffffff',
    color: '#fff',
    fontWeight: 'bold',
    fontSize: 'large',
    height: '50px',
    margin: theme.spacing(1, 0),
    padding: theme.spacing(2, 0),
    width: '100%',
  },
});

class ReportButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      report: {
        tooltip: false,
        dialog: false,
      },
    };

    this.setReportState = this.setReportState.bind(this);
  }

  setReportState = obj => {
    let newObj = this.state.report;

    for (let key in obj) newObj[key] = obj[key];

    this.setState({ report: newObj });

    if (newObj.dialog === true) {
      setTimeout(() => {
        newObj.tooltip = false;
        this.setState({ report: newObj });
      }, 3000);
    }
  };

  copy = async () => {
    const { data } = this.props;
    let id = data.id;
    let username = encrypt(data.userId, 'data-key-1');
    let url = `https://www.jebomarket.com/vod/Detail?data=${username}&c=${id}`;

    let text = `${data.title} 을(를) 목격했습니다.

날짜 :
${data.date} 시간 ${data.time}

자세한 경위 :
${data.content}

영상링크:
${url}`;

    var tmpTextarea = document.createElement('textarea');
    tmpTextarea.value = text;

    document.body.appendChild(tmpTextarea);
    tmpTextarea.select();
    tmpTextarea.setSelectionRange(0, 9999); // 셀렉트 범위 설정

    document.execCommand('copy');
    document.body.removeChild(tmpTextarea);
  };

  openDialog = () => {
    this.copy();
    this.setReportState({ dialog: true, tooltip: true });
  };

  render() {
    const { classes, data } = this.props;

    return (
      <>
        <ToolTip title='report' open={this.state.report.tooltip} arrow={true} placement='bottom-start' color='red'>
          <Button fullWidth={true} className={classes.monoBtn} onClick={this.openDialog}>
            제보/공유하기
          </Button>
        </ToolTip>

        <ReportDialog data={data} open={this.state.report.dialog} onClose={() => this.setReportState({ dialog: false })} disableEscapeKeyDown={true} />
      </>
    );
  }
}

export default withStyles(styles, { withTheme: true })(ReportButton);
