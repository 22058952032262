import React from 'react';
import { Button, Chip, Grid, withStyles, withWidth } from '@material-ui/core';
import { API_URL, createVodUrl, decrypt, encrypt } from '../constants';
import axiosInstance from '../constants/AxiosInstance';
import ReportButton from '../components/ReportButton';
import OfferDialog from '../dialog/OfferDialog';

import { connect } from 'react-redux';
import { openLogin } from '../redux/action';
import { replaceValue, getCodeList } from '../components/ListComponets';
import JwPlayer from './JwPlayer';
import RefVodPage from './RefVodPage';
import CustomCircularProgress from '../components/CustomCircularProgress';
import Alert from '../components/Alert';

import VodEditButton from '../components/VodEditButton';
import queryString from 'query-string';
import LayoutPage from '../components/LayoutPage';

const styles = theme => ({
  container: {
    width: '100%',
    height: '100%',
    overflowY: 'auto',
    overflowX: 'auto',
    position: 'relative',
    backgroundColor: '#171A1D',
  },
  vodMainDiv: {
    marginTop: '1rem',
    backgroundColor: '#0c0e10',
    borderRadius: '13px',

    [theme.breakpoints.down('md' || 'xm')]: {
      margin: '1rem 0.5rem',
    },
  },

  vodTitle: {
    color: '#ffffff',
    borderBottom: '1px solid rgb(143, 143, 143, .5)',
    fontWeight: 'bold',
    fontSize: '1.5em',
    padding: theme.spacing(4, 4, 1, 4),
    wordBreak: 'break-all',
    '& > div:first-child': {
      minHeight: '70px',
      [theme.breakpoints.down('md' || 'xm')]: {
        minHeight: '0',
        padding: theme.spacing(0, 1),
      },
    },
    [theme.breakpoints.down('md' || 'xm')]: {
      fontSize: '1.2em',
      padding: '0',
      borderBottom: 'none',
    },
  },
  vodText: {
    color: '#999999',
    fontSize: '1.5em',
    minHeight: '330px',
    maxHeight: '330px',
    overflow: 'auto',
    padding: theme.spacing(4, 4),
    wordBreak: 'break-all',
    [theme.breakpoints.down('md')]: {
      borderTop: '1px solid rgb(143, 143, 143, .5)',
      fontSize: '1em',
      padding: theme.spacing(1),
      borderBottom: 'none',
      minHeight: '0',
    },
  },

  colorBtn: {
    backgroundColor: '#e61737',
    border: '1px solid #e61737',
    color: '#fff',
    fontWeight: 'bold',
    fontSize: 'large',
    height: '50px',
    margin: theme.spacing(1, 0),
    padding: theme.spacing(2, 0),
    [theme.breakpoints.down('md')]: {},
    '&:hover': {
      backgroundColor: '#e61737',
      color: '#fff',
    },
  },

  monoBtn: {
    backgroundColor: 'transparent',
    border: '1px solid #ffffff',
    color: '#fff',
    fontWeight: 'bold',
    fontSize: 'large',
    height: '50px',
    margin: theme.spacing(1, 0),
    padding: theme.spacing(2, 0),
    [theme.breakpoints.down('md')]: {},
  },
  hashTag: {
    // width: '8%',
    padding: '0 8px',
    height: '24px',
    backgroundColor: '#515252',
    color: '#ffffff',
    fontWeight: 'bold',
    borderRadius: '3px',
    margin: theme.spacing(0, 1),
    [theme.breakpoints.down('md' || 'xm')]: {
      backgroundColor: 'rgb(12, 14, 16)',
      color: '#aaaaaa',
      fontSize: '0.8em',
      margin: '0',
    },
    '& >span': {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      paddingLeft: '0',
      paddingRight: '0',
      textOverflow: 'clip',
    },
  },
  navBar: {
    backgroundColor: '#0c0e10',
    borderRadius: '13px',
    [theme.breakpoints.down('md' || 'xm')]: {
      paddingBottom: '19px 0',
      marginTop: '0',
    },
  },
  vodArea: {
    // padding: '1rem 1rem 0rem 1rem',
    backgroundColor: 'rgb(12, 14, 16)',
    borderRadius: '13px',
    [theme.breakpoints.down('md' || 'xm')]: {
      margin: '0',
    },
  },
  contentArea: {
    color: '#aaaaaa',
    padding: theme.spacing(2, 0),
    borderBottom: '1px solid rgb(143, 143, 143, .5)',
    fontWeight: 'bold',
    [theme.breakpoints.down('md' || 'xm')]: {
      display: 'none',
    },
  },
  hashArea: {
    padding: theme.spacing(2, 2, 3, 2),
    [theme.breakpoints.down('md' || 'xm')]: {
      padding: theme.spacing(0),
    },
  },
  mobileNav: {
    display: 'none',
    [theme.breakpoints.down('md' || 'xm')]: {
      color: '#aaaaaa',
      fontSize: '11px',
      display: 'inline-block',
      padding: '3px 8px 10px 8px',
    },
  },
  Btn: {
    padding: theme.spacing(1, 0),
    [theme.breakpoints.down('md' || 'xm')]: {
      padding: '8px 8px 24px 8px',
    },
  },
  vodBox: {
    minHeight: '522px',
    [theme.breakpoints.down('md' || 'xm')]: {
      minHeight: '0',
    },
    // '& >video': {
    //   width: '100%',
    //   maxHeight: '522px',
    //   // objectFit: 'cover',
    //   borderTopLeftRadius: '13px',
    //   [theme.breakpoints.down('md' || 'xm')]: {
    //     borderTopRightRadius: '13px',
    //   },
    // },
  },

  priceBox: {
    [theme.breakpoints.down('md' || 'xm')]: {
      padding: '0 8px 8px 8px',
      fontSize: '1.3rem',
    },
  },
  circularProgress: {
    position: 'fixed',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'block',
    textAlign: 'center',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0.1,0.1,0.1, 0.3)',
  },
  refVodTitle: {
    color: '#fff',
    fontSize: '18px',
    padding: '24px 24px 0 24px',
    fontWeight: 'bold',
  },
  privateVod: {
    backgroundColor: '#e61737',
    color: '#fff',
  },
  borderR: {
    borderRight: '1px solid rgba(170,170,170,0.5)',
    height: 18,
  },
});

class ItemPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      offerDialog: false, // 구매하기 dialog
      reportDialog: false, //제보/공유하기 dialog
      tabValue: 0, // 탭 시작값
      matches: window.matchMedia('(max-width:991px)').matches, //md:992px
      vodLoding: true,
      tooltip: false,
      price: '',
      data: '',
      dataReRender: true,
      vodReRender: true,
      delay: '',
      loginOpen: '',
      seller: '',
    };
  }

  componentDidMount() {
    this.dataRender();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.dataReRender !== this.state.dataReRender) {
      this.componentDidMount();
    }
  }

  scrollToTop = () => {
    let topScroll = document.getElementById('topScroll');

    if (topScroll !== null) {
      topScroll.scrollIntoView(true);
    }
  };

  lodingStop = () => {
    this.setState({ vodLoding: false });
  };

  dataRender = () => {
    const query = queryString.parse(this.props.location.search);
    if (query) {
      const id = query.c;
      let username = query.data.replaceAll(' ', '+');
      username = decrypt(username, 'data-key-1');

      this.scrollToTop();
      this.getDetailVod(id, username);
    } else {
      // this.props.history.push('/');
    }
  };

  getDetailVod = async (id, username) => {
    let response = await axiosInstance.get(`${API_URL}/vod/${id}/${username}`, { headers: { Pragma: 'no-cache' } });
    let responseData = response?.data?.value.Item;

    if (!responseData) {
      this.props.history.push('/');
    }

    if (this.state.delay) {
      clearTimeout(this.state.delay);
    }

    let delay = setTimeout(async () => {
      if (responseData) {
        if (responseData.vod[0] !== undefined) {
          this.setState({ vodReRender: false });
          await createVodUrl(responseData.vod[0].mark).then(result => {
            responseData.vod[0].vodUrl = result;
          });
        }

        this.setState({ data: responseData, dataReRender: true, vodReRender: true, seller: responseData.userId }, () => {
          // console.log(this.state.data);
          getCodeList('price').then(response => {
            const data = replaceValue(response, this.state.data.price);
            this.setState({ price: data });
          });
        });
      }
    }, 200);

    this.setState({ delay: delay });
  };

  goItem = data => {
    this.setState({ dataReRender: false, vodReRender: false, vodLoding: true }, () => {
      // console.log('링크이동');
      let id = data.id;
      let username = encrypt(data.userId, 'data-key-1');

      this.props.history.replace(`/vod/Detail?data=${username}&c=${id}`);
    });
  };

  reformatTime = time => {
    return `${Math.floor(Number(time) / 60)
      .toString()
      .padStart(2, '0')}:${(Number(time) % 60).toString().padStart(2, '0')}`;
  };

  offerUserCheck = async vodUser => {
    const response = await axiosInstance.get(`${API_URL}/order/my?userId=${vodUser}`);

    if (this.state.seller !== vodUser) {
      for await (let res of response.data.value) {
        if (res.vod === this.state.data.id) {
          Alert('', '이미 구매한 영상입니다.');
          return false;
        }
      }
    } else {
      Alert('', '자신의 동영상은 구매가 불가능합니다.');
      return false;
    }

    return true;
  };

  openOfferDialog = event => {
    const loginCheck = localStorage.getItem('jebo_user');
    if (loginCheck) {
      this.offerUserCheck(loginCheck).then(res => {
        if (res) {
          this.setState({ offerDialog: true });
        }
      });
    } else {
      Alert('구매를 하기 위해 로그인이 필요합니다.', '로그인 하시겠습니까?', '아니요', '예', res => {
        if (res) {
          console.log(this.props);
          this.props.openLogin();
        }
      });
    }
  };

  closeOfferDialog = event => {
    this.setState({ offerDialog: false });
  };

  editCheck = data => {
    let result = false;
    const loginCheck = localStorage.getItem('jebo_user');
    if (loginCheck) {
      if (data.vod_status === 'wait' && data.userId === loginCheck) {
        result = true;
      }
    }

    return result;
  };

  render() {
    const { classes } = this.props;
    const { data, dataReRender, vodReRender } = this.state;

    return (
      <LayoutPage topbarBorderCheck={true} footer={true} bannerSection={false}>
        <Grid container>
          <Grid container justifyContent='center'>
            {data && dataReRender && (
              <>
                <Grid container justifyContent='center' item xs={12} style={{ marginTop: '35px' }}>
                  <Grid id='detail_content_main' container justifyContent='center' item className={classes.vodMainDiv} xs={12} md={10}>
                    {/* 영상영역 */}
                    <Grid container item md={8} xs={12} className={classes.vodArea}>
                      <Grid container item xs={12} className={classes.vodBox}>
                        {data && vodReRender && <JwPlayer data={data} lodingStop={this.lodingStop} history={this.props.history} />}
                      </Grid>

                      {/* todo: 개별 데이터 명칭 수정  */}
                      <Grid container item className={classes.contentArea} xs={12}>
                        <Grid container item xs={3} className={classes.borderR}>
                          <Grid container justifyContent='center' item xs={6} style={{ color: 'rgba(170,170,170,0.5)' }}>
                            촬영날짜
                          </Grid>
                          <Grid item xs={6} container justifyContent='center'>
                            {data.date}
                          </Grid>
                        </Grid>
                        {/* <Grid container item xs={2}>
                          <Grid container justifyContent="center" item xs={6} style={{ color: 'rgba(170,170,170,0.5)' }}>
                            촬영위치
                          </Grid>
                          <Grid container item xs={6} justifyContent="center">
                       
                          </Grid>
                        </Grid>
                        | */}
                        <Grid container item xs={2} className={classes.borderR}>
                          <Grid container justifyContent='center' item xs={6} style={{ color: 'rgba(170,170,170,0.5)' }}>
                            영상화질
                          </Grid>
                          <Grid container item xs={6} justifyContent='center'>
                            {data.vod[0]?.resolution ? `${data.vod[0]?.resolution.toString().split('X')[1]}p` : ''}
                          </Grid>
                        </Grid>
                        <Grid container item xs={2}>
                          <Grid container justifyContent='center' item xs={6} style={{ color: 'rgba(170,170,170,0.5)' }}>
                            영상길이
                          </Grid>
                          <Grid container justifyContent='center' item xs={6}>
                            {this.reformatTime(data.vod[0].duration)}
                          </Grid>
                        </Grid>
                      </Grid>

                      {/* 해시태그영역 */}
                      <Grid className={classes.hashArea} container justifyContent='flex-start'>
                        {data.hashtag &&
                          data.hashtag.map((ht_data, ht_idx) => {
                            return <Chip label={`#${ht_data}`} className={classes.hashTag} key={ht_idx} />;
                          })}
                      </Grid>
                    </Grid>

                    {/* 우측 텍스트 영역 */}
                    <Grid className={classes.navBar} item xs={12} md={4}>
                      <Grid>
                        {/* 제목 */}
                        <Grid container className={classes.vodTitle}>
                          <Grid item xs={12}>
                            {data.vod_status === 'wait' && (
                              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', marginTop: '11px' }}>
                                <Chip label='비공개 동영상' className={classes.privateVod} />
                                <Grid item xs={4}>
                                  {this.editCheck(data) && <VodEditButton onClick={this.openOfferDialog} data={data} reRender={this.dataRender} history={this.props.history} />}
                                </Grid>
                              </div>
                            )}
                            <p>{data.title}</p>
                          </Grid>
                          {/* 모바일 영역 */}
                          <Grid container item className={classes.mobileNav} xs={9}>
                            <Grid item xs={3} style={{ display: 'inline-block' }}>
                              <Grid item xs={12}>
                                {data.date}
                              </Grid>
                            </Grid>
                            <p style={{ margin: '0 3px 0 3px', display: 'inline-block' }}>|</p>
                            <Grid item xs={3} style={{ display: 'inline-block' }}>
                              <Grid item xs={12}>
                                {data.vod[0]?.resolution ? data.vod[0]?.resolution.toString() : ''}

                                {/* 1080p 화질일시 HD 마크 */}
                                {data.vod[0]?.resolution && data.vod[0].resolution.toString() === '1080' ? <sup style={{ color: '#e61737' }}>HD</sup> : ''}
                              </Grid>
                            </Grid>
                          </Grid>
                          {/* 가격 */}
                          <Grid className={classes.priceBox} container item xs={12} justifyContent={'flex-end'}>
                            {this.state.price && (
                              <span>
                                <b style={{ color: 'red' }}>₩</b> {this.state.price}
                              </span>
                            )}
                          </Grid>
                        </Grid>

                        {/* 내용 */}
                        <Grid container className={classes.vodText}>
                          <div dangerouslySetInnerHTML={{ __html: data.content.replace(/\n/g, '<br />') }}></div>
                        </Grid>

                        {/* 버튼영역 */}
                        <Grid container justifyContent='center' className={classes.Btn}>
                          <Grid container justifyContent='center' item md={9} xs={12}>
                            <Button fullWidth={true} className={classes.colorBtn} onClick={this.openOfferDialog}>
                              구매하기
                            </Button>
                          </Grid>

                          <Grid container justifyContent='center' item md={9} xs={12}>
                            <ReportButton data={data} />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {/* 추천 동영상 영역 */}
                {data.vod_status !== 'wait' && (
                  <Grid container justifyContent='center' item xs={12}>
                    <Grid id='detail_content_main' container justifyContent='flex-start' item xs={12} md={10} className={classes.refVodTitle}>
                      추천 영상
                    </Grid>
                    <Grid id='detail_content_main' container justifyContent='center' item xs={12} md={10}>
                      <RefVodPage vodData={data} goItem={this.goItem} />
                    </Grid>
                  </Grid>
                )}

                <OfferDialog open={this.state.offerDialog} data={data} onClose={this.closeOfferDialog} disableEscapeKeyDown={true} />
                {this.state.vodLoding && <CustomCircularProgress />}
              </>
            )}
          </Grid>
        </Grid>
      </LayoutPage>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    openLogin: () => dispatch(openLogin()),
  };
};

const mapStateToProps = state => {
  return {};
};

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(ItemPage));
