import React from 'react';
import { Grid, withStyles } from '@material-ui/core';
import MagazineService from '../service/MagazineService';
import LayoutPage from './LayoutPage';

const styles = theme => ({
  container: {
    width: '100%',
    height: '100%',
    overflowY: 'auto',
    overflowX: 'auto',
    position: 'relative',
    backgroundColor: '#171A1D',
  },
  cardGrid: {
    padding: theme.spacing(2, 2),
    cursor: 'pointer',
  },
  card: {
    border: '1px solid #ffffff',
    borderRadius: '5px',
  },
  cardImg: {
    height: '18vh',
    [theme.breakpoints.down('md')]: {
      height: '20vh',
    },
  },
  cardImgBox: {
    width: '100%',
    height: '100%',
    '& img': {
      [theme.breakpoints.down('md')]: {
        height: '20vh',
      },
    },
  },
});

class Magazine extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      magazineData: [],
      matches: window.matchMedia('(max-width:480px)').matches, //md:992px
    };
  }

  componentDidMount() {
    this.getData();
    //sample API evt012 (test webconference)
    /*
    axios
      .get(`https://bikahhcyl6.execute-api.ap-northeast-2.amazonaws.com/latest/notice/list?eventId=evt012`, {
        headers: { Pragma: 'no-cache' },
      })
      .then((response) => {
        console.log(response.data.Items);
        response.data.Items.sort((a, b) => {
          return Number(a.sortOrder) > Number(b.sortOrder) ? 1 : -1;
        });

        this.setState({ magazineData: response.data.Items });
      });
      */
  }

  // moveDetail(noticeTitle, noticeContents) {
  //   this.props.history.push({
  //     pathname: './magazineDetail',
  //     state: { noticeTitle: noticeTitle, noticeContents: noticeContents },
  //   });
  // }

  goUrl = url => {
    window.open(url, '_blank');
  };

  getData = () => {
    MagazineService.getMagazineList().then(response => {
      // console.log(response);
      this.setState({ magazineData: response });
    });
  };

  render() {
    const { classes } = this.props;

    const detailPopUp = datas => {
      datas = this.state.magazineData;
      return datas.map((magazine, i) => {
        return (
          <React.Fragment key={i}>
            <Grid container justifyContent='center' item xs={12} md={4} className={classes.cardGrid}>
              <Grid container className={classes.card}>
                {/* todo: sample img-> data img (afer API) */}
                <Grid container alignItems='center' className={classes.cardImg} onClick={() => this.goUrl(`${magazine.contents}`)}>
                  <Grid item xs={12} className={classes.cardImgBox}>
                    <img src={magazine.news[0].url} style={{ width: '100%', height: '100%', objectFit: 'cover' }} alt='logo' />
                  </Grid>
                </Grid>

                <Grid container style={{ backgroundColor: '#ffffff', padding: this.props.theme.spacing(2, 2), fontWeight: 'bold' }}>
                  {magazine.title}
                </Grid>
              </Grid>
            </Grid>
          </React.Fragment>
        );
      });
    };

    return (
      <LayoutPage topbarBorderCheck={true} footer={true}>
        <Grid
          id='main_grid'
          container
          justifyContent='center'
          //   style={{ padding: this.state.matches ? this.props.theme.spacing(3, 0) : this.props.theme.spacing(10, 0) }}
        >
          <Grid container justifyContent='center' item xs={10} md={8}>
            <Grid
              container
              justifyContent='flex-start'
              item
              xs={12}
              style={{
                fontSize: '2.5em',
                color: '#ffffff',
                padding: this.state.matches ? this.props.theme.spacing(2, 0) : this.props.theme.spacing(3, 0),
              }}
            >
              매거진
            </Grid>

            <Grid id='content_main' container justifyContent='center' item xs={12}>
              {detailPopUp(this.state.magazineData)}
            </Grid>
          </Grid>
        </Grid>
      </LayoutPage>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Magazine);
