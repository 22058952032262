import React, { Component } from 'react';
import { Dialog, DialogTitle, DialogContent, Grid, withStyles, Button, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const styles = theme => ({
  dialogMain: {
    backgroundColor: '#171A1D',
    fontFamily: 'Noto Sans KR',
    letterSpacing: '0.05rem',
    [theme.breakpoints.down('md')]: {
      padding: '0px',
    },
    //
  },
  dialogTopContainer: {
    width: '100%',
  },
  dialogTopWrapper: {
    display: 'block',
    width: '100%',
    textAlign: 'center',
  },
  dialogCloseWrapper: {
    textAlign: 'right',
    height: '13px',
  },
  dialogClose: {
    color: '#fff',
  },
  dialogTitle: {
    color: '#fff',
  },
  dialogContent: {
    color: '#fff',
    textAlign: 'center',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: 1.5,
  },
  colorBtn: {
    width: '70%',
    backgroundColor: '#e61737',
    border: '2px solid #e61737',
    borderRadius: '5px',
    color: '#ffffff',
    height: '5vh',
    fontWeight: 'bold',
    marginBottom: '2rem',
    [theme.breakpoints.down('md')]: {
      border: '1px solid #e61737',
      borderRadius: '5px',
      height: '50px',
      margin: theme.spacing(1, 0),
    },
  },
});

class UploadDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      matches: window.matchMedia('(max-width:991px)').matches,
    };
  }

  onClickClose = () => {
    this.props.setDialog(false);
  };

  render() {
    const { classes, dialog, name } = this.props;

    return (
      <Dialog fullWidth={true} maxWidth={'sm'} open={dialog} style={{ zIndex: '10001' }}>
        {/* DIALOG WRAPPER */}
        <Grid container item xs={12} className={classes.dialogMain}>
          {/* DIALOG TOP CONTAINER */}
          <DialogTitle className={classes.dialogTopContainer}>
            {/* DIALOG TOP WRAPPER */}
            <Grid container item xs={12} className={classes.dialogTopWrapper}>
              {/* DIALOG CLOSE WRAPPER */}
              <div className={classes.dialogCloseWrapper}>
                <IconButton onClick={this.onClickClose}>
                  <CloseIcon className={classes.dialogClose} />
                </IconButton>
              </div>
              {/* DIALOG TITLE */}
              <b className={classes.dialogTitle}>업로드 완료</b>
            </Grid>
          </DialogTitle>

          {/* DIALOG CONTENT CONTAINER */}
          <DialogContent>
            <div className={classes.dialogContent}>
              <p>
                <strong>{!name ? '홍길동' : this.props.name}</strong> 님! <br />
                소중한 영상을 업로드해주셔서 감사합니다.
                <br />
                <br />
                영상 심사는 수일 이내에 완료되며, 영상이
                <br />
                심사중이더라도 업로드 내역의 공유/제보하기 버튼을
                <br />
                이용해 언제든지 제보하실 수 있습니다.
                <br />
                <br />
                다시 한 번 감사드립니다.
              </p>
            </div>
          </DialogContent>

          <Grid container justifyContent='center' item xs={12}>
            <Button className={classes.colorBtn} onClick={this.onClickClose}>
              업로드 완료
            </Button>
          </Grid>
        </Grid>
      </Dialog>
    );
  }
}

export default withStyles(styles, { withTheme: true })(UploadDialog);
