import React from 'react';
import { Grid, withStyles } from '@material-ui/core';
import ImageItem from './ImageItem';
import { API_URL, createVodUrl, encrypt } from '../constants';
import Masonry from 'react-masonry-css';
import { throttle, debounce } from 'loadsh';
import axiosInstance from '../constants/AxiosInstance';
import KeywordTaps from '../components/KeywordTaps';
import LayoutPage from '../components/LayoutPage';

const styles = theme => ({
  container: {
    width: '100%',
    height: '100%',
    overflowX: 'hidden',
    overflowY: 'auto',
    position: 'relative',
    backgroundColor: '#171A1D',
  },
  masonry: {
    display: 'flex',
    width: '100%',
    '& > div': {
      padding: '5px',
    },
  },
  noData: {
    color: '#FFF',
    height: '300px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  webDrawer: {
    '& .MuiDrawer-paperAnchorLeft ': {
      width: '20%',
      backgroundColor: 'transparent',
    },
  },
});

const breakpointColumnsObj = {
  default: 4,
  1100: 3,
  700: 2,
  500: 1,
};

class MainPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      matches: window.matchMedia('(max-width:480px)').matches, //md:992px
      uploadData: [],
      searchData: '',
      nodata: false,
      delay: null,
      menuDrawer: true, // 웹 메뉴버튼 Drawer
      mobMenuDrawer: false, // 모바일메뉴버튼 Drawer
      tabValue: 'all',
    };
    this.refList = [];

    this.getVod = this.getVod.bind(this);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.videoScroll, true);
    window.addEventListener('resize', this.resizePage);
    this.getVod();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.videoScroll, true);
    window.removeEventListener('resize', this.resizePage);
    this.setState({ uploadData: [] });
  }

  resizePage = e => {
    this.setState({ matches: window.matchMedia('(max-width:480px)').matches });
  };

  videoScrollDebounce = debounce(() => {
    this.videoEnable();
  }, 200);

  videoScrollThrottle = throttle(() => {
    this.videoDisable();
  }, 100);

  webMenuOpen = event => {
    this.setState({ menuDrawer: true });
  };

  webMenuClose = event => {
    this.setState({ menuDrawer: false });
  };

  mobMenuOpen = event => {
    this.setState({ mobMenuDrawer: true });
  };

  mobMenuClose = event => {
    this.setState({ mobMenuDrawer: false });
  };

  getVod = async search => {
    let response = null;

    response = await axiosInstance.get(`${API_URL}/vod`, { headers: { Pragma: 'no-cache' } });
    let responseData = response.data.value;

    // vodUrl: 'https://via.placeholder.com/400.png?text=No%20Video'

    if (responseData) {
      let ArrayRow = [];
      for await (let awaitData of responseData) {
        let rowData = awaitData;
        if (rowData.vod[0] !== undefined) {
          await createVodUrl(rowData.vod[0].mark).then(result => {
            rowData.vod[0].vodUrl = result;

            ArrayRow.push(rowData);
          });
        }
      }

      ArrayRow.sort(function (a, b) {
        return a.created_at < b.created_at ? 1 : -1;
      });

      // console.log(ArrayRow);

      this.setState({ uploadData: ArrayRow });
    }
  };

  handleChange = (event, newValue) => {
    this.setState({ tabValue: newValue });
  };

  videoScroll = () => {
    if (this.state.matches) {
      this.videoScrollThrottle();
      this.videoScrollDebounce();
    }
  };

  videoDisable = () => {
    for (let i = 0; i < this.refList.length; i++) {
      let videoEl = this.refList[i];
      if (videoEl && videoEl.current) {
        if (!videoEl.current.paused) {
          videoEl.current.pause();
          videoEl.current.currentTime = 0;
        }
      }
    }
  };

  videoEnable = () => {
    if (this.refList.length > 0) {
      let windowHeight = window.innerHeight;
      for (let i = 0; i < this.refList.length; i++) {
        let videoEl = this.refList[i];
        let videoHeight = videoEl.current.clientHeight;
        let videoClientRect = videoEl.current.getBoundingClientRect().top;

        if (videoClientRect <= windowHeight - videoHeight * 0.5 && videoClientRect >= 0 - videoHeight * 0.5) {
          if (videoEl && videoEl.current) {
            if (videoEl.current.paused) {
              videoEl.current.play();
            }
          }
          break;
        } else {
          if (videoEl && videoEl.current) {
            if (!videoEl.current.paused) {
              videoEl.current.pause();
              videoEl.current.currentTime = 0;
            }
          }
        }
      }
    }
  };

  scrollToTop = () => {
    let keyWordTap = document.getElementById('keyWordTap');

    if (keyWordTap !== null) {
      keyWordTap.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    }
  };

  goItem = data => {
    let id = data.id;
    let username = encrypt(data.userId, 'data-key-1');

    this.props.history.push(`/vod/Detail?data=${username}&c=${id}`);
  };

  handleTabChange = (event, newValue) => {
    if (this.state.tabValue === newValue) {
      this.scrollToTop();
    }
    this.setState({ tabValue: newValue });
  };

  dataFilter = vodList => {
    const { tabValue } = this.state;

    if (tabValue !== 'all' && tabValue !== 'trending') {
      vodList = vodList.filter(item => {
        return item.equipment.includes(tabValue) || item.topic.includes(tabValue);
      });
    } else if (tabValue === 'trending') {
      vodList = vodList.filter(item => {
        return item?.trend;
      });
    }

    return vodList;
  };

  // ####  DO NOT DELETE CODE BELOW  ####
  /*  handleFile = (file, key) => {
    fileUpload(file, 'vod_1630998965879', key, 'vod', `${API_URL}/vod/`).then((response) => {
      console.log('sample upload : ', response);
    });
  }; */
  // ####  DO NOT DELETE CODE ABOVE ####

  render() {
    const { classes } = this.props;
    const { uploadData, tabValue } = this.state;
    let _uploadData = [...uploadData];

    _uploadData = this.dataFilter(_uploadData);

    return (
      <LayoutPage topbarBorderCheck={false} footer={false} bannerSection={true}>
        {/* KEYWORD TABS */}
        <div id='keyWordTap' />
        <KeywordTaps tabData={tabValue} onChange={this.handleTabChange}>
          {/* IMAGE LIST */}
          <Grid container justifyContent='center' style={{ padding: this.state.matches ? '2vh 0' : '4vh 0' }}>
            <Grid item xs={11}>
              {_uploadData.length > 0 ? (
                <Masonry className={classes.masonry} columnClassName={classes.masonryColum} breakpointCols={breakpointColumnsObj}>
                  {_uploadData.length > 0 && _uploadData.map((item, idx) => <ImageItem key={idx} matches={this.state.matches} item={item} idx={idx} goItem={this.goItem} />)}
                </Masonry>
              ) : (
                <div className={classes.noData}>데이터가 존재하지 않습니다.</div>
              )}
            </Grid>
          </Grid>
        </KeywordTaps>
      </LayoutPage>
    );
  }
}

export default withStyles(styles, { withTheme: true })(MainPage);
