import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import ScrollToTop from './components/ScrollTop';

import MainPage from './vod/MainPage';
// import LoginPage from './signup/Login';
// import JoinPage from './signup/Join';
import ItemPage from './vod/ItemPage';
import UploadPage from './upload/UploadPage';
import HistoryPage from './history/HistoryPage';
import FAQPage from './components/FAQ';
import SearchListPage from './vod/SearchListPage';
import TermsOfUse from './components/TermsOfUse';
import Magazine from './components/Magazine';
// import MagazineDetail from './components/MagazineDetail';
import NaverCallback from './signup/NaverCallback';

export default props => (
  <BrowserRouter>
    <ScrollToTop>
      <Switch>
        <Route exact path='/' component={MainPage} />
        <Route exact path='/callback' component={NaverCallback} />

        <Route exact path='/search' component={SearchListPage} />

        {/* /vod */}
        <Route exact path='/vod' component={MainPage} />
        {/* /vod/:id */}
        <Route exact path='/vod/:Detail' component={ItemPage} />
        {/* /vod/upload */}
        <Route path='/upload' component={UploadPage} />
        {/* /history/upload */}
        <Route exact path='/history/upload' component={HistoryPage} />
        {/* /history/sale */}
        <Route exact path='/history/sale' component={HistoryPage} />
        {/* /history/download */}
        <Route exact path='/history/download' component={HistoryPage} />
        {/* /history/purchase */}
        <Route exact path='/history/purchase' component={HistoryPage} />
        {/*FAQ*/}
        <Route exact path='/faq' component={FAQPage} />
        <Route exact path='/terms' component={TermsOfUse} />
        <Route exact path='/magazine' component={Magazine} />
        {/* <Route exact path="/magazineDetail" component={MagazineDetail} /> */}
      </Switch>
    </ScrollToTop>
  </BrowserRouter>
);
