import { INIT_MENUBAR_OPEN, INIT_LOGINDIALOG_OPEN } from './model';
import { OPEN_MENU, CLOSE_MENU, OPEN_LOGIN, CLOSE_LOGIN } from './action';

import { combineReducers } from 'redux';

const changeMenu = (state = INIT_MENUBAR_OPEN, action) => {
  switch (action.type) {
    case OPEN_MENU:
      return {
        ...state,
        value: true,
      };
    case CLOSE_MENU:
      return {
        ...state,
        value: false,
      };
    default:
      return state;
  }
};

const openLoginDialog = (state = INIT_LOGINDIALOG_OPEN, action) => {
  switch (action.type) {
    case OPEN_LOGIN:
      return {
        ...state,
        value: true,
      };
    case CLOSE_LOGIN:
      return {
        ...state,
        value: false,
      };
    default:
      return state;
  }
};

const reducer = combineReducers({ changeMenu, openLoginDialog });
export default reducer;
