import React from 'react';
import { Button, withStyles, withWidth } from '@material-ui/core';
import { createVodUrl, API_URL } from '../../constants';
import Alert from '../../components/Alert';
import ToolTip from '../../components/ToolTip';
import axiosInstance from '../../constants/AxiosInstance';

const styles = theme => ({
  buttonBody: {
    backgroundColor: '#36383b',
    border: '1px solid #36383b',
    color: '#fff',
    width: '100%',
    fontWeight: 'bold',
    fontSize: '15px',
    height: '50px',
    margin: theme.spacing(1, 0),
    padding: theme.spacing(2, 0),
  },
  publicButton: {
    backgroundColor: '#e61737',
    border: '1px solid #e61737',
    color: '#fff',
    width: '100%',
    fontWeight: 'bold',
    fontSize: '15px',
    height: '50px',
    margin: theme.spacing(1, 0),
    padding: theme.spacing(2, 0),
    pointerEvents: 'none',
  },
  rejectedButton: {
    backgroundColor: '#1f2125',
    border: '1px solid #1f2125',
    color: '#fff',
    width: '100%',
    fontWeight: 'bold',
    fontSize: '15px',
    height: '50px',
    margin: theme.spacing(1, 0),
    padding: theme.spacing(2, 0),
    pointerEvents: 'none',
  },
  doneButton: {
    backgroundColor: 'none',
    border: '1px solid #fff',
    color: '#fff',
    width: '100%',
    fontWeight: 'bold',
    fontSize: '15px',
    height: '50px',
    pointerEvents: 'none',
    margin: theme.spacing(1, 0),
    padding: theme.spacing(2, 0),
  },
  notdoneButton: {
    backgroundColor: '#36383b',
    border: '1px solid #36383b',
    color: '#fff',
    width: '100%',
    fontWeight: 'bold',
    fontSize: '15px',
    height: '50px',
    margin: theme.spacing(1, 0),
    padding: theme.spacing(2, 0),
  },
});

class ConditionalButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tooltip: false,
    };

    this.handleMouseOver = this.handleMouseOver.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
    this.handleClickMobile = this.handleClickMobile.bind(this);
  }

  handleMouseOver = w => {
    if (!(w === 'xs' || w === 'sm')) this.setState({ tooltip: true });
  };

  handleMouseLeave = (w, f) => {
    if (!(w === 'xs' || w === 'sm')) this.setState({ tooltip: false });
  };

  handleClickMobile = (w, f) => {
    if (w === 'xs' || w === 'sm') {
      this.setState({ tooltip: true });
      setTimeout(() => {
        this.setState({ tooltip: false });
      }, 3000);
    }
  };

  downloadVod = async () => {
    const { data } = this.props;

    let param = {
      id: data.id,
      downloadCnt: data.downloadCnt ? data.downloadCnt + 1 : 1,
    };

    if (data.vodDetail.vod[0].enc_vod) {
      await createVodUrl(data.vodDetail.vod[0].enc_vod).then(result => {
        const linkEle = document.createElement('a');
        linkEle.href = result;
        linkEle.download = `${data.vodDetail.title}.mp4`;
        linkEle.click();
        axiosInstance.put(`${API_URL}/order/`, param, { headers: { Pragma: 'no-cache' } });
      });
    } else {
      Alert('', '동영상 다운로드에 문제가 발생하였습니다. <br />고객센터에 문의 바랍니다.');
    }
  };

  render() {
    const { classes, width, code } = this.props;

    switch (code) {
      case 'public':
        return (
          <Button fullWidth={true} className={classes.publicButton}>
            판매중(공개)
          </Button>
        );
      case 'wait':
        return (
          <>
            <ToolTip arrow={true} placement='bottom-end' title='wait' open={this.state.tooltip} color='black'>
              <Button
                className={classes.buttonBody}
                fullWidth
                onClick={() => this.handleClickMobile(width)}
                onMouseOver={() => this.handleMouseOver(width)}
                onMouseLeave={() => this.handleMouseLeave(width)}
              >
                판매중(비공개)
              </Button>
            </ToolTip>
          </>
        );
      case 'process':
        return (
          <Button className={classes.buttonBody} fullWidth>
            영상처리중
          </Button>
        );
      case 'rejected':
        return (
          <Button fullWidth={true} className={classes.rejectedButton}>
            거절
          </Button>
        );
      case 'done':
        return (
          <Button fullWidth={true} className={classes.doneButton}>
            정산 완료
          </Button>
        );
      case 'notdone':
        return (
          <Button fullWidth={true} className={classes.notdoneButton}>
            미정산
          </Button>
        );
      case 'request':
        return (
          <>
            <ToolTip arrow={true} placement='bottom-end' title='request' open={this.state.tooltip} color='gray'>
              <Button
                className={classes.buttonBody}
                fullWidth
                style={{ height: '30px', fontSize: '12px' }}
                onClick={() => this.handleClickMobile(width)}
                onMouseOver={() => this.handleMouseOver(width)}
                onMouseLeave={() => this.handleMouseLeave(width)}
              >
                입금대기중
              </Button>
            </ToolTip>
          </>
        );
      case 'complete':
        return (
          <Button fullWidth={true} className={classes.publicButton}>
            결제완료
          </Button>
        );
      case 'Downloadrequest':
        return (
          <Button fullWidth={true} className={classes.rejectedButton} style={{ color: '#7a7a7a' }}>
            다운로드
          </Button>
        );
      case 'Downloadcomplete':
        return (
          <Button fullWidth={true} className={classes.doneButton} style={{ pointerEvents: 'auto' }} onClick={this.downloadVod}>
            다운로드
          </Button>
        );
      case 'withhold':
        return (
          <Button fullWidth={true} className={classes.rejectedButton} style={{ pointerEvents: 'auto' }}>
            미정산
          </Button>
        );
      case 'pay':
        return (
          <Button fullWidth={true} className={classes.doneButton} style={{ pointerEvents: 'auto' }}>
            정산완료
          </Button>
        );
      case 'pay-request':
        return (
          <Button fullWidth={true} className={classes.buttonBody} style={{ pointerEvents: 'auto' }}>
            정산 신청중
          </Button>
        );
      default:
        break;
    }
  }
}

export default withStyles(styles)(withWidth()(ConditionalButton));
