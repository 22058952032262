import axiosInstance from '../constants/AxiosInstance';
import { API_URL } from '../constants';

/* 배너가져오기 */
const getBanner = param => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(`${API_URL}/banner/`, param)
      .then(res => {
        let result = res.data.value;
        resolve(result);
      })
      .catch(err => {
        reject(err);
      });
  });
};

const BannerService = {
  getBanner,
};

export default BannerService;
