import React from 'react';
import { withStyles, withWidth, Grid } from '@material-ui/core';
import { changeFile } from '../constants/index';

import moment from 'moment';
import _ from 'loadsh';
import Entrance from './components/Entrance';
import Detail from './components/Detail';
import UploadDialog from '../dialog/UploadDialog';
import { connect } from 'react-redux';

import LayoutPage from '../components/LayoutPage';

const styles = theme => ({
  container: {
    width: '100%',
    height: '100%',
    overflowY: 'auto',
    overflowX: 'auto',
    position: 'relative',
    backgroundColor: '#171A1D',
  },
  wrapper: {
    width: '100%',
    paddingTop: '3rem',
    fontWeight: '600',
    letterSpacing: '0.02rem',
    textAlign: 'left',
    padding: theme.spacing(0, 25),
    [theme.breakpoints.down('md')]: {
      padding: '0',
    },
  },
  content: {
    padding: '3rem 2.5rem 1rem 2.5rem',
    borderRadius: '5px',
    backgroundColor: '#1f2125',
    color: '#ffffff',
    marginBottom: '5rem',
    [theme.breakpoints.down('md')]: {
      padding: '1rem',
    },
  },
});

class UploadPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: localStorage.getItem('jebo_user'),
      tabValue: 0, // 탭 시작값
      matches: window.matchMedia('(max-width:480px)').matches, //md:992px
      fileList: [],
      preViewList: [],
      dialog: false,
    };

    this.refList = [];
    this.setFileList = this.setFileList.bind(this);
    this.setDialog = this.setDialog.bind(this);
  }

  componentDidMount() {}

  setFileList(files) {
    this.setState({ fileList: files });
  }

  setPreViewList = videos => {
    this.setState({ preViewList: videos });
  };

  setDialog = value => {
    this.setState({ dialog: value });
    if (!value) this.props.history.push('/history/upload');
  };

  onChangeFile = async e => {
    changeFile(e).then(res => {
      this.setPreViewList(res.buffs);
      this.setFileList(res.arr);
      this.scrollToTop();
    });
  };

  scrollToTop = () => {
    let topScroll = document.getElementById('topScroll');

    if (topScroll !== null) {
      topScroll.scrollIntoView(true);
    }
  };

  render() {
    const { classes } = this.props;
    const userName = localStorage.getItem('jebo_name');

    if (!userName) {
      this.props.history.push('/');
    }

    return (
      <LayoutPage topbarBorderCheck={true} footer={false}>
        <Grid className={classes.wrapper} container item xs={12} sm={12} md={12} justifyContent='center' alignItems='center'>
          <Grid className={classes.content} item xs={10} sm={10} md={10} lg={8} xl={6}>
            {/* Conditional rendering based on state values. */}
            {!this.state.fileList.length ? (
              <Entrance fileList={this.state.fileList} maxCount={3} setFileList={this.setFileList} setPreViewList={this.setPreViewList} onChangeFile={this.onChangeFile} />
            ) : (
              <Detail fileList={this.state.fileList} preViewList={this.state.preViewList} setDialog={this.setDialog} onChangeFile={this.onChangeFile} />
            )}

            {/* UPLOAD DONE DIALOG */}
            {this.state.dialog && (
              <div>
                <UploadDialog dialog={this.state.dialog} setDialog={this.setDialog} name={userName} />
              </div>
            )}
          </Grid>
        </Grid>
      </LayoutPage>
    );
  }
}

const mapStateToProps = state => {
  return {
    menuValue: state.changeMenu.value,
  };
};

export default withStyles(styles)(withWidth()(connect(mapStateToProps)(UploadPage)));
