import React from 'react';
import { withStyles, Grid, withWidth } from '@material-ui/core';
import ConditionalButton from './ConditionalButton';
import { replaceValue, getCodeList, setCreatedAt, returnUrl } from '../../components/ListComponets';

const styles = theme => ({
  container: {
    color: '#ffffff',
    textAlign: 'center',
    border: 'none',
    padding: '15px 0',
    borderBottom: '1px solid rgb(143, 143, 143, .5)',
    alignItems: 'center',
  },
  vodContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  vodWrapper: {
    width: '80%',
    marginRight: '10px',
    cursor: 'pointer',
  },
  imgBody: {
    display: 'flex',
    width: '100%',
    height: '94px',
    objectFit: 'contain',
    border: '1px solid rgb(143, 143, 143, .5)',
  },
  vodBody: {
    display: 'flex',
    width: '100%',
    height: '94px',
  },
  titleBorder: {
    width: '100%',
    display: 'block',
    margin: '16px',
    wordBreak: 'break-word',
  },
  amountContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  amountWrapper: {
    width: '80%',
    marginRight: '10px',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  btnWrapper: {
    width: '80%',
    marginRight: '10px',
  },
  stateContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
});

class SaleBodyWebComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      amount: '',
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.videoScroll, true);

    getCodeList('price')
      .then(response => {
        this.setState({ amount: `${replaceValue(response, this.props.data.amount)} ₩` });
      })
      .catch(console.log);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.videoScroll, true);
  }

  render() {
    const { classes, idx, data, noThumb, onClick } = this.props;

    return (
      <Grid className={classes.container} container>
        <Grid item xs={1}>
          <div className={classes.titleBorder}>{idx + 1}</div>
        </Grid>
        <Grid className={classes.vodContainer} item xs={2}>
          <div className={classes.vodWrapper} onClick={onClick}>
            <img className={classes.imgBody} src={data.vodDetail.vod[0]?.thumb ? returnUrl(data.vodDetail.vod[0]?.thumb) : noThumb} alt='vod_thumb' />
          </div>
        </Grid>
        <Grid item xs={3}>
          <span className={classes.titleBorder} style={{ fontSize: '12px' }}>
            {data.vodDetail.title}
          </span>
        </Grid>
        <Grid item xs={2}>
          <div className={classes.titleBorder} style={{ fontSize: '13px' }} dangerouslySetInnerHTML={{ __html: setCreatedAt(data.created_at).replace(/ /g, '<br />') }} />
        </Grid>
        <Grid className={classes.amountContainer} item xs={2}>
          <div className={classes.amountWrapper}>{this.state.amount}</div>
        </Grid>
        <Grid className={classes.stateContainer} item xs={2}>
          <div className={classes.btnWrapper}>
            {/* 
              정산 : pay
              미정산 : withhold
              정산신청중 : pay-request
            */}
            <ConditionalButton code={data.account} />
          </div>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(withWidth()(SaleBodyWebComponent));
