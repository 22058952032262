import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {
  AppBar,
  Avatar,
  Button,
  Collapse,
  Dialog,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  SwipeableDrawer,
  withStyles,
  TextField,
  Toolbar,
  InputAdornment,
  ClickAwayListener,
  withWidth,
} from '@material-ui/core';
import axiosInstance from '../constants/AxiosInstance';
import { API_URL } from '../constants';

import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';

import { Auth } from 'aws-amplify';
import { Dropdown, DropdownMob } from './Menu';

import FilterList from './FilterList';

import Login from '../signup/Login';
import Join from '../signup/Join';
import MypageDialog from '../dialog/mypage/MypageDialog';
import HpValidate from '../signup/HpValidate';

import Alert from '../components/Alert';

import { connect } from 'react-redux';
import { openMenu, closeMenu, openLogin, closeLogin } from '../redux/action';

const styles = theme => ({
  uploadBtn: {
    marginRight: '1vw',
    color: '#ffffff',
    border: '1px solid',
    borderColor: '#ffffff',
  },
  headerBox: {
    boxShadow: 'none',
    backgroundColor: '#171A1D',
  },
  toolbarBox: {
    flexFlow: 'wrap',
    [theme.breakpoints.down('sm')]: {
      marginTop: '13px',
    },
  },

  color_button: {
    backgroundColor: theme.palette.primary.main,
    color: '#ffffff',
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    borderRadius: '5px',
    height: '5.5vh',
    [theme.breakpoints.down('sm')]: {
      height: '45px',
      padding: '0 4vw ',
      margin: '3vh 0 0',
    },
  },
  mono_button: {
    color: '#ffffff',
    border: '1px solid',
    borderColor: '#ffffff',
    borderRadius: '5px',
    height: '5.5vh',
    [theme.breakpoints.down('sm')]: {
      height: '45px',
      padding: '0 4vw ',
      margin: '3vh 0',
    },
  },
  listitemText: {
    color: '#cccccc',
    fontWeight: 'bold',
    marginLeft: '1vw',
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.main,
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: '4vw',
    },
  },
  searchInputWrapper: {
    position: 'relative',
    '& div:first-child': {
      borderRadius: '5px',
      borderRight: 'none',
    },
  },
  searchInput: {
    width: '100%',
    backgroundColor: '#0c0e10',
    border: '0.3px solid rgb(143, 143, 143, .5)',
    '& input': {
      color: '#ffffff',
      borderRadius: '5px',
      height: '4vh',
      padding: '0 1vw',
      backgroundColor: '#0c0e10',
      [theme.breakpoints.down('sm')]: {
        height: '35px',
        padding: '0 4vw ',
      },
    },
    [theme.breakpoints.down('sm')]: {
      margin: '15px 0',
    },
  },
  searchBtn: {
    width: '100%',
    backgroundColor: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    height: '4vh',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '35px',
    },
  },

  searchFilterList: {
    minWidth: '40px',
  },

  webDrawer: {
    '& .MuiDrawer-paperAnchorLeft ': {
      width: '20%',
      backgroundColor: 'transparent',
      [theme.breakpoints.down('sm')]: {
        width: '30%',
      },
      [theme.breakpoints.down('xs')]: {
        width: '40%',
      },
    },
  },
  dialogBorder: {
    overflowY: 'auto',
    '& .MuiPaper-rounded': {
      borderRadius: '16px',
      backgroundColor: '#171A1D',
    },
    '& .MuiDialog-paperWidthSm': {
      maxWidth: '550px',
    },
  },
  mobileAbatar: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  webAbatar: {
    display: 'block',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
});

class Topbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuDrawer: false, // 웹 메뉴버튼 Drawer
      mobMenuDrawer: false, // 모바일메뉴버튼 Drawer
      selectedMenu: '', // 선택된 상위메뉴
      isDropdownOpen: false,
      isMobDropdownOpen: false,
      isLoginOpen: false,
      isJoinOpen: false,
      isMypageOpen: false,
      vodList: this.props.vodList,
      filterOpen: false,
      hpValidateOpen: false,
      matches: window.matchMedia('(max-width:480px)').matches,
      openAlert: false,
      userData: '',

      searchBody: {
        search: this.props.searchBody?.search ? this.props.searchBody.search : '',
        equipmentSearchValue: this.props.searchBody?.equipmentSearchValue ? this.props.searchBody.equipmentSearchValue : '',
        topicSearchValue: this.props.searchBody?.topicSearchValue ? this.props.searchBody.topicSearchValue : '',
        renderEquipment: this.props.searchBody?.renderEquipment ? this.props.searchBody.renderEquipment : '전체',
        renderTopic: this.props.searchBody?.renderTopic ? this.props.searchBody.renderTopic : '전체',
        dataRange: this.props.searchBody?.dataRange ? this.props.searchBody.dataRange : '',
      },
    };
  }
  componentDidMount() {
    this.getUserInfo();
    window.addEventListener('resize', this.resizePage);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizePage);
  }

  getUserInfo = () => {
    let userId = localStorage.getItem('jebo_user');

    if (userId) {
      axiosInstance.get(`${API_URL}/user/${userId}`, { headers: { Pragma: 'no-cache' } }).then(response =>
        this.setState({ userData: response.data.value.Item }, () => {
          if (this.state.userData.phone_number === '') {
            this.setState({ hpValidateOpen: true });
          }
        }),
      );
    }
  };

  resizePage = e => {
    this.setState({ matches: window.matchMedia('(max-width:480px)').matches });
  };

  webMenuToggle = () => {
    this.props.menuValue ? this.props.closeMenu() : this.props.openMenu();
  };

  mobMenuOpen = () => {
    this.setState({ mobMenuDrawer: true });
  };

  mobMenuClose = () => {
    this.setState({ mobMenuDrawer: false });
  };

  handleDropdown = (event, label) => {
    event.stopPropagation();

    if (label === '마이페이지' || label === '업로드' || label === '다운로드' || label === '메인업로드') {
      if (!localStorage.getItem('jebo_user')) return this.props.openLogin();
    }

    switch (label) {
      case '마이페이지':
        this.setState({ isMypageOpen: true });
        break;
      case '메인업로드':
        this.props.history.push('/upload');
        break;
      case '매거진':
        this.props.history.push('/magazine');
        break;
      case 'FAQ':
        this.props.history.push('/faq');
        break;
      case '이용약관':
        this.props.history.push('/terms');
        break;
      default:
        this.setState(label === this.state.selectedMenu ? { isDropdownOpen: false, selectedMenu: '' } : { isDropdownOpen: true, selectedMenu: label });
        break;
    }
  };

  handleMobDropdown = (event, label) => {
    let flag = true;
    event.stopPropagation();

    if (!localStorage.getItem('jebo_user')) {
      this.props.openLogin();
      return false;
    }

    if (label === '마이페이지') {
      this.setState({ isMypageOpen: true });
    } else {
      this.setState(label === this.state.selectedMenu ? { isMobDropdownOpen: false, selectedMenu: '' } : { isMobDropdownOpen: true, selectedMenu: label });
      flag = false;
    }

    if (flag) this.props.closeMenu();
  };

  logOut = () => {
    localStorage.clear();
    sessionStorage.clear();
    Auth.signOut().then(() => this.goHome());
  };

  goHome = () => this.props.history.push('/');

  handlePushHistory = path => {
    this.props.location.pathname === this.props.history.location.pathname ? this.props.history.replace(path) : this.props.history.push(path);
    // this.props.closeMenu();
  };

  handlePushHistory = path => {
    this.props.location.pathname === this.props.history.location.pathname ? this.props.history.replace(path) : this.props.history.push(path);
  };

  handleOpenJoin = () => this.setState({ isJoinOpen: true }, () => this.props.closeLogin());

  handleOpenLogin = () => this.setState({ isJoinOpen: false }, () => this.props.openLogin());

  handleCloseMypage = () => this.setState({ isMypageOpen: false });

  onChangeSearch = (type, e, equData, topicData) => {
    let eventDate = e;
    let _searchBody = this.state.searchBody;
    if (type === 'equipment' || type === 'topic') {
      if (e === '') eventDate = '';
    }
    if (type === 'equipment') {
      _searchBody['equipmentSearchValue'] = eventDate;
      _searchBody['renderEquipment'] = equData;
    }
    if (type === 'topic') {
      _searchBody['topicSearchValue'] = eventDate;
      _searchBody['renderTopic'] = topicData;
    }
    if (type === 'search') _searchBody['search'] = e.target.value;

    this.setState({ serachBody: _searchBody });
  };

  goToSearchList = () => {
    const { searchBody } = this.state;

    if (searchBody.search === '') {
      Alert('', '검색어를 입력해주세요.');
      return false;
    }

    this.setState({ filterOpen: false });
    this.props.history.replace(
      `/search?search=${this.state.searchBody.search}&equipmentSearchValue=${this.state.searchBody.equipmentSearchValue}&topicSearchValue=${this.state.searchBody.topicSearchValue}`,
      {
        searchBody: this.state.searchBody,
      },
    );
  };

  openFilter = () => this.setState({ filterOpen: !this.state.filterOpen });

  render() {
    const { classes, width, borderCheck } = this.props;
    const { searchBody, userData } = this.state;

    return (
      <>
        <AppBar className={classes.headerBox} position='static' style={borderCheck ? { borderBottom: '1px solid #36383b' } : { borderBottom: 'none' }}>
          <Toolbar className={classes.toolbarBox}>
            {/* topbar web  */}
            <Grid container item xs={6} md={2}>
              <Grid container alignItems='center' item xs={12} md={12}>
                <IconButton
                  onClick={() => {
                    width === 'xs' ? this.mobMenuOpen() : this.webMenuToggle();
                  }}
                  style={{ color: '#ffffff' }}
                >
                  <MenuIcon />
                </IconButton>
                <img style={{ cursor: 'pointer', width: '85px' }} onClick={() => this.props.history.push('/')} src='/images/jbmarket/topbar_logo@2x.png' alt={'img'} />

                {this.state.matches ? null : (
                  <Grid container item sm={12}>
                    <SwipeableDrawer
                      className={classes.webDrawer}
                      anchor='left'
                      variant='persistent'
                      open={this.props.menuValue}
                      disableBackdropTransition={true}
                      onClose={() => this.props.closeMenu()}
                      onOpen={() => this.props.openMenu()}
                    >
                      <List style={{ backgroundColor: '#171A1D', height: '100%' }}>
                        <Grid container alignItems='center' style={{ paddingLeft: '24px' }}>
                          <IconButton onClick={() => (width === 'xs' ? this.mobMenuOpen() : this.webMenuToggle())} style={{ color: '#ffffff' }}>
                            <MenuIcon />
                          </IconButton>
                          <img style={{ cursor: 'pointer', width: '85px' }} onClick={() => this.props.history.push('/')} src='/images/jbmarket/topbar_logo@2x.png' alt={'img'} />
                        </Grid>
                        {Dropdown.map((menu, webIndex) => (
                          <React.Fragment key={`list-frag-${webIndex}`}>
                            <ListItem button onClick={event => this.handleDropdown(event, menu.label)} key={`listitem-${webIndex}`} style={{ borderBottom: '0.5px solid rgba(232, 232, 232, 0.5)' }}>
                              <Grid container>
                                <Grid container justifyContent='center' alignItems='center' item xs={1}>
                                  <Grid>
                                    <img src={`/images/common/ic_${menu.img}.png`} alt={menu.img} />{' '}
                                  </Grid>
                                </Grid>
                                <Grid item xs={11}>
                                  <ListItemText className={classes.listitemText} primary={menu.label} key={`listitem-text-${webIndex}`} style={{ fontWeight: 'bold' }} />
                                </Grid>
                              </Grid>
                            </ListItem>
                            {menu.label === this.state.selectedMenu && (
                              <Collapse
                                in={this.state.isDropdownOpen}
                                timeout='auto'
                                unmountOnExit
                                style={{ borderBottom: '1px solid rgba(232, 232, 232, 0.5)' }}
                                key={`listitem-collapse-${webIndex}`}
                              >
                                {menu.down.map((down, downIndex) => (
                                  <List component='div' disablePadding key={`list-sub-${downIndex}`} onClick={() => this.handlePushHistory(down.path)} style={{ backgroundColor: '#0e1114' }}>
                                    <ListItem key={`list-sub-item-${downIndex}`}>
                                      <ListItemText className={classes.listitemText} key={`list-sub-item-text-${downIndex}`}>
                                        {down.label}
                                      </ListItemText>
                                    </ListItem>
                                  </List>
                                ))}
                              </Collapse>
                            )}
                          </React.Fragment>
                        ))}
                        <Grid container justifyContent='center' style={{ backgroundColor: '#171A1D' }}>
                          <Grid container justifyContent='center' item xs={10} style={{ padding: '4vh 0' }}>
                            {localStorage.getItem('jebo_user') ? (
                              <React.Fragment>
                                <Grid container>
                                  <Button className={classes.color_button} fullWidth={true} onClick={() => this.logOut()}>
                                    로그아웃
                                  </Button>
                                </Grid>
                              </React.Fragment>
                            ) : (
                              <React.Fragment>
                                <Grid container style={{ paddingBottom: '2vh' }}>
                                  <Button className={classes.color_button} fullWidth={true} onClick={() => this.handleOpenLogin()}>
                                    로그인
                                  </Button>
                                </Grid>
                                <Grid container>
                                  <Button
                                    className={classes.mono_button}
                                    fullWidth={true}
                                    onClick={() => {
                                      this.setState({ isJoinOpen: true });
                                      // this.props.closeMenu();
                                    }}
                                  >
                                    회원가입
                                  </Button>
                                </Grid>
                              </React.Fragment>
                            )}
                          </Grid>
                        </Grid>
                      </List>
                    </SwipeableDrawer>
                  </Grid>
                )}
              </Grid>
            </Grid>

            {/* 모바일 전용 */}
            <Grid container item xs={6} md={2} className={classes.mobileAbatar}>
              <Grid container justifyContent='flex-end' alignItems='center'>
                <Grid>
                  <Button className={classes.uploadBtn} onClick={e => this.handleDropdown(e, '메인업로드')}>
                    <Grid container justifyContent='center' alignItems='center' item xs={12}>
                      <img src='/images/common/ic_upload.png' alt={'img'} />
                      업로드
                    </Grid>
                  </Button>
                </Grid>
                <Grid>
                  <Avatar style={{ cursor: 'pointer' }} src={userData.profile} onClick={e => this.handleDropdown(e, '마이페이지')} />
                </Grid>
              </Grid>
            </Grid>

            <Grid container item xs={12} md={8} justifyContent='center'>
              <Grid container justifyContent='center' alignItems='center' item xs={12}>
                <Grid item xs={this.props.menuValue ? 10 : 12} md={this.props.menuValue ? 10 : 12}>
                  <ClickAwayListener onClickAway={() => this.setState({ filterOpen: false })}>
                    <div className={classes.searchInputWrapper}>
                      <TextField
                        placeholder='검색'
                        className={classes.searchInput}
                        value={searchBody.search}
                        onChange={e => this.onChangeSearch('search', e)}
                        onKeyPress={e => e.key === 'Enter' && this.goToSearchList()}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position='end'>
                              <Button className={classes.searchFilterList} onClick={this.openFilter}>
                                <img src='/images/common/ic_filterList.png' alt={'img'} />
                              </Button>

                              <Button className={classes.searchBtn} onClick={this.goToSearchList}>
                                <SearchIcon style={{ color: '#ffffff' }} />
                              </Button>
                            </InputAdornment>
                          ),
                        }}
                      />
                      {this.state.filterOpen && <FilterList onChange={this.onChangeSearch} data={searchBody} />}
                    </div>
                  </ClickAwayListener>
                </Grid>
              </Grid>
            </Grid>

            {/* 웹 전용 */}
            <Grid container item xs={6} md={2} className={classes.webAbatar}>
              <Grid container justifyContent='flex-end' alignItems='center'>
                <Grid container justifyContent='flex-end' item xs={10}>
                  <Button className={classes.uploadBtn} onClick={e => this.handleDropdown(e, '메인업로드')}>
                    <Grid container justifyContent='center' alignItems='center' item xs={12}>
                      <img src='/images/common/ic_upload.png' alt={'img'} />
                      업로드
                    </Grid>
                  </Button>
                </Grid>
                <Grid container justifyContent='flex-start' item xs={1}>
                  <Avatar style={{ cursor: 'pointer' }} src={userData.profile} onClick={e => this.handleDropdown(e, '마이페이지')} />
                </Grid>
              </Grid>
            </Grid>
          </Toolbar>

          <Grid container item xs={12}>
            <SwipeableDrawer anchor='bottom' open={this.state.mobMenuDrawer} onClose={this.mobMenuClose} onOpen={this.mobMenuOpen} style={{ width: '100%' }}>
              <List style={{ backgroundColor: '#171A1D' }}>
                {DropdownMob.map((menu, mobIndex) => (
                  <React.Fragment key={`list-frag-${mobIndex}`}>
                    <ListItem button onClick={event => this.handleMobDropdown(event, menu.label)} key={`listitem-${mobIndex}`}>
                      <img src={`/images/common/ic_${menu.img}.png`} alt={menu.img} style={{ width: '15px', height: '15px' }} />
                      <ListItemText className={classes.listitemText} primary={menu.label} key={`listitem-text-${mobIndex}`} />
                    </ListItem>

                    {menu.label === this.state.selectedMenu && (
                      <Collapse in={this.state.isMobDropdownOpen} timeout='auto' unmountOnExit key={`listitem-collapse-${mobIndex}`}>
                        {menu.down.map((down, downMobIndex) => (
                          <List
                            component='div'
                            disablePadding
                            key={`list-sub-${downMobIndex}`}
                            onClick={() => {
                              if (localStorage.getItem('jebo_user')) {
                                this.props.location.pathname === this.props.history.location.pathname ? this.props.history.replace(down.path) : this.props.history.push(down.path);
                                this.mobMenuClose();
                              } else this.setState({ isLoginOpen: true });
                            }}
                          >
                            <ListItem key={`list-sub-item-${downMobIndex}`}>
                              <ListItemText className={classes.listitemText} key={`list-sub-item-text-${downMobIndex}`}>
                                {down.label}
                              </ListItemText>
                            </ListItem>
                          </List>
                        ))}
                      </Collapse>
                    )}
                  </React.Fragment>
                ))}
              </List>

              <Grid container style={{ backgroundColor: '#171A1D', paddingTop: '2.5vh', borderTop: '0.5px solid #cccccc' }}>
                <Grid container justifyContent='center' item xs={4}>
                  <Grid onClick={() => this.props.history.push('/magazine')}>
                    <img src='/images/common/ic_magazine.png' alt={'img'} style={{ width: 'auto', height: '20px', color: '#cccccc' }} />
                  </Grid>
                </Grid>
                <Grid container justifyContent='center' item xs={4}>
                  <Grid onClick={() => this.props.history.push('/faq')}>
                    <img src='/images/common/ic_faq.png' alt={'img'} style={{ width: 'auto', height: '20px', color: '#cccccc' }} />
                  </Grid>
                </Grid>
                <Grid container justifyContent='center' item xs={4}>
                  <Grid onClick={() => this.props.history.push('/terms')}>
                    <img src='/images/common/ic_terms.png' alt={'img'} style={{ width: 'auto', height: '20px', color: '#cccccc' }} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                style={{
                  backgroundColor: '#171A1D',
                  color: '#cccccc',
                  fontWeight: 'bold',
                  paddingTop: '1vh',
                  paddingBottom: '2.5vh',
                  borderBottom: '0.5px solid #cccccc',
                }}
              >
                <Grid container justifyContent='center' item xs={4} onClick={() => this.props.history.push('/magazine')}>
                  매거진
                </Grid>
                <Grid container justifyContent='center' item xs={4} onClick={() => this.props.history.push('/faq')}>
                  FAQ
                </Grid>
                <Grid container justifyContent='center' item xs={4} onClick={() => this.props.history.push('/terms')}>
                  이용약관
                </Grid>
              </Grid>

              <Grid container justifyContent='center' style={{ backgroundColor: '#171A1D', padding: ' 2vh 0 8vh' }}>
                {localStorage.getItem('jebo_user') ? (
                  <Grid container item xs={10} md={10}>
                    <Button className={classes.color_button} fullWidth onClick={() => this.logOut()}>
                      로그아웃
                    </Button>
                  </Grid>
                ) : (
                  <React.Fragment>
                    <Grid container item xs={10} md={10}>
                      <Button className={classes.color_button} fullWidth onClick={() => this.handleOpenLogin()}>
                        로그인
                      </Button>
                    </Grid>
                    <Grid container item xs={10} md={10}>
                      <Button className={classes.mono_button} fullWidth onClick={() => this.setState({ isJoinOpen: true }, () => this.props.closeMenu())}>
                        회원가입
                      </Button>
                    </Grid>
                  </React.Fragment>
                )}
              </Grid>
            </SwipeableDrawer>
          </Grid>
        </AppBar>

        <Dialog className={classes.dialogBorder} fullWidth maxWidth={'sm'} open={this.props.loginDialogOpen} onClose={() => this.setState({ isLoginOpen: false })}>
          <Login onClose={() => this.props.closeLogin()} handleOpenJoin={this.handleOpenJoin.bind(this)} open={this.props.loginDialogOpen} />
        </Dialog>

        <Dialog className={classes.dialogBorder} fullWidth maxWidth={'sm'} open={this.state.isJoinOpen} onClose={() => this.setState({ isJoinOpen: false })}>
          <Join onClose={() => this.setState({ isJoinOpen: false })} handleOpenLogin={this.handleOpenLogin.bind(this)} open={this.state.isJoinOpen} />
        </Dialog>

        {userData && (
          <MypageDialog
            open={this.state.isMypageOpen}
            onClose={() => this.setState({ isMypageOpen: false })}
            userData={userData}
            handleCloseMypage={this.handleCloseMypage.bind(this)}
            goHome={this.goHome}
            reRender={this.getUserInfo}
          />
        )}
        {userData && (
          <Dialog className={classes.dialogBorder} fullWidth maxWidth={'sm'} open={this.state.hpValidateOpen}>
            <HpValidate open={this.state.hpValidateOpen} userData={userData} />
          </Dialog>
        )}
      </>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    openMenu: () => dispatch(openMenu()),
    closeMenu: () => dispatch(closeMenu()),
    openLogin: () => dispatch(openLogin()),
    closeLogin: () => dispatch(closeLogin()),
  };
};

const mapStateToProps = state => {
  return {
    menuValue: state.changeMenu.value,
    loginDialogOpen: state.openLoginDialog.value,
  };
};

export default withRouter(withStyles(styles)(withWidth()(connect(mapStateToProps, mapDispatchToProps)(Topbar))));
