export const Menu = [
  {
    label: '마이페이지',
    anchor: 'mypage',
    pathname: '/',
  },
  {
    label: '업로드',
    anchor: 'upload',
    pathname: '/',
  },
  {
    label: '다운로드',
    anchor: 'download',
    pathname: '/',
  },
  {
    label: '매거진',
    anchor: 'magazine',
    pathname: '/',
  },
  {
    label: 'FAQ',
    anchor: 'FAQ',
    pathname: '/',
  },
  {
    label: '이용약관',
    anchor: 'termsofuse',
    pathname: '/',
  },
];

export const Dropdown = [
  {
    label: '마이페이지',
    title: 'mypage',
    down: [],
    img: 'avatar',
  },
  {
    label: '업로드',
    title: 'upload',
    down: [
      {
        label: '업로드하기',
        path: '/upload',
      },
      {
        label: '업로드내역',
        path: '/history/upload',
      },
      {
        label: '판매내역',
        path: '/history/sale',
      },
    ],
    img: 'upload',
  },
  {
    label: '다운로드',
    title: 'download',
    down: [
      {
        label: '구매 내역',
        path: '/history/purchase',
      },
      {
        label: '다운로드 내역',
        path: '/history/download',
      },
    ],
    img: 'download',
  },
  {
    label: '매거진',
    title: 'magazine',
    down: [],
    img: 'magazine',
  },
  {
    label: 'FAQ',
    title: 'FAQ',
    down: [],
    img: 'faq',
  },
  {
    label: '이용약관',
    title: 'termsofuse',
    down: [],
    img: 'terms',
  },
];

export const DropdownMob = [
  {
    label: '마이페이지',
    title: 'mypage',
    down: [],
    img: 'avatar',
  },
  {
    label: '업로드',
    title: 'upload',
    down: [
      {
        label: '업로드하기',
        path: '/upload',
      },
      {
        label: '업로드내역',
        path: '/history/upload',
      },
      {
        label: '판매내역',
        path: '/history/sale',
      },
    ],
    img: 'upload',
  },
  {
    label: '다운로드',
    title: 'download',
    down: [
      {
        label: '구매 내역',
        path: '/history/purchase',
      },
      {
        label: '다운로드 내역',
        path: '/history/download',
      },
    ],
    img: 'download',
  },
];

export default Menu;
