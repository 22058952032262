export const TermsOfUseText = `<div>
<b>제1조 목적</b>
<br />
<br />
본 이용약관은 주식회사 트립클립이 소유 및 운영하는 제보마켓(이하 회사) 이 제공하는 제보마켓 웹사이트 서비스(jebomarket.com)의 이용과 관련하여 회사와 회원과의 권리, 의무 및 책임 사항, 기타 필요한 사항을 규정함을 목적으로 합니다.
<br /><br />
<b>제2조 정의</b>
<br /><br />
1. 콘텐츠는 제보마켓 내에 게재된 동영상을 말합니다. <br />
2. 에디토리얼 전용 콘텐츠란 상업적 용도로 사용할 수 없으며 비상업적 용도로만 사용 가능한 콘텐츠를 말합니다.<br />
3. 회원이란 제보마켓에 가입한 회원을 말하며, 본 약관 및 ‘제보마켓 작가 회원 약관’에 따라 회사가 제공하는 서비스를 이용하는 자를 말합니다.<br />
4. 작가 회원이란 제보마켓의 일반 회원 중 콘텐츠를 업로드하여 판매하기 위해 추가 정보를 입력하고 인증된 회원을 말합니다.<br />
5. 아이디(ID)는 회원 식별과 서비스 이용을 위하여 회원이 신청하고 회사가 승인한 문자와 숫자의 조합을 말하며 보통 가입할 때 입력하는 이메일 주소를 말합니다.<br />
6. 비밀번호는 회원이 부여 받은 아이디와 일치된 회원임을 확인하고, 비밀 보호를 위해 회원 자신이 정한 문자 또는 숫자의 조합을 말합니다.<br />
7. 서비스란 회원이 회사 사이트 내의 콘텐츠를 무료 또는 유료로 이용하는 행위를 말합니다.<br />
8. 해지는 회사 또는 회원이 서비스 계약을 종료하는 것을 말합니다.<br />
9. 정산은 작가 회원의 콘텐츠가 판매될 시 작가가 지정하고 회사가 인증한 본인 명의의 계좌로 수수료 및 기타 비용을 제한 금액을 송금하는 것을 의미합니다. <br />
10. 수수료는 플랫폼의 운영 유지를 위해 작가 회원의 콘텐츠 판매 금액 정산 시, 회사가 정한 요율만큼의 금액을 제하는 것을 의미합니다. <br />
11. 기타 비용이란 작가회원 해당 국가의 소득세 및 카드 수수료 등 필요 처리 비용을 의미합니다.<br />
12. 트레이드마크(Trade Mark)는 제보마켓의 상호명, 로고, 워터마크, 이미지, 그래픽 등 회사의 정체성을 나타낼 수 있는 모든 형태의 표기를 의미합니다. <br />
13. 라이선스는 작가 회원이 제보마켓에 업로드한 콘텐츠에 대한 사용권을 의미합니다. <br />
<br /><br />
<b>제3조 약관의 명시, 효력 및 개정</b>
<br /><br />
1. 이 약관은 회원이 쉽게 알 수 있도록, 서비스 초기화면에 게시합니다.<br />
2. 회사는 약관의 규제에 관한 법률, 정보통신망 이용촉진 및 정보보호 등에 관한 법률(이하 정보통신망법) 등 관련법을 위배하지 않는 범위 내에서 이 약관을 개정할 수 있습니다. <br />
3. 회사가 약관을 개정하는 경우 적용 일자 및 개정사항을 명시하여 적용일 7일 전에 서비스 공지사항 게시판 또는 이메일을 통해 공지합니다. 다만, 회원에게 불리한 약관 개정의 경우에는 공지 외에 일정 기간 서비스 내 전자우편, 로그인 시 동의 창 등의 전자적 수단을 통해 따로 명확히 통지하도록 합니다. <br />
4. 회사가 제3조 3항에 따라 개정약관을 공지 또는 통지한 공지일로부터 7일 이내에 회원이 명시적으로 거부하지 않는 경우 개정약관에 동의한 것으로 간주합니다.<br />
5. 회원이 개정에 동의하지 않는다는 의사를 표시한 경우 회사는 개정된 약관을 적용할 수 없으며, 회원은 개정 약관의 효력 발생일로부터 서비스를 이용할 수 없습니다.<br />
6. 약관을 개정할 경우 그 개정약관은 그 적용 일자 이후에 체결되는 계약에만 적용됩니다. 그 이전에 이미 체결된 계약에 대해서는 개정 전의 약관조항이 그대로 적용됩니다.<br />
<br /><br />
<b>제4조 약관의 해석</b>
<br /><br />
1. 이 약관에서 정하지 않은 사항이나 해석은 관련 법령 또는 상 관례에 따릅니다.<br />
2. 서비스를 이용 중인 회원들의 분쟁 발생 시 약관을 우선으로 적용하고, 해석이 애매하거나 이 약관에서 정하지 않은 사항은 관련 법령 또는 상 관례에 따라 분쟁의 당사자가 해결해야 합니다.<br />
<br /><br />
<b>제5조 이용계약 체결</b>
<br /><br />
1. 서비스 이용계약은 회원이 되고자 하는 자(이하 가입 신청자)가 약관의 내용에 대하여 동의를 한 다음 회원가입신청을 하고 회사가 이를 수락함으로써 체결됩니다.<br />
2. 제1항 신청에 있어 회사는 본인확인 기관을 통한 실명확인 및 본인인증을 요청할 수 있습니다.<br />
3. 가입 신청자는 가입신청 시 진실한 정보를 기재해야 하며, 허위 정보를 기재함으로 인한 불이익 및 법적 책임은 가입 신청자에게 있습니다.<br />
4. 회사는 다음 각호에 해당하는 신청에 대하여는 승낙을 하지 않습니다.<br />
1) 만 14세 미만의 경우<br />
2) 실명이 아니거나 타인의 명의를 이용한 경우<br />
3) 이용자의 귀책사유로 인하여 승인할 수 없거나 기타 규정한 제반 사항을 위반하며 신청하는 경우<br />
4) 가입 신청시 허위 내용을 등록하는 경우 <br />
5) 다른 사람의 ‘당사 웹사이트 및 어플리케이션’ 이용을 방해하거나 그 정보를 도용하는 등 전자상거래 질서를 위협하는 경우 <br />
6) ‘당사 웹사이트 및 어플리케이션’을 이용하여 법령 또는 이 약관이 금지하거나 미풍 양속에 반하는 행위를 하는 경우 <br />
7) 회사가 판단하여 ‘당사 웹사이트 및 어플리케이션’ 이용을 제한하고자 하는 경우<br />
5. 제4항에 따라 회원가입신청을 승낙하지 아니하거나 유보한 경우, 회사는 원칙적으로 이를 가입 신청자에게 알리도록 합니다.<br />
6. 회사는 회원에 대해 회사정책에 따라 등급별로 구분하여 이용시간, 이용횟수, 서비스 메뉴 등을 세분하여 이용에 차등을 둘 수 있습니다.<br />
<br /><br />
<b>제6조 회원 정보의 변경</b>
<br /><br />
1. 회원은 서비스 내 마이페이지를 통하여 언제든지 본인의 개인정보를 열람하고 수정할 수 있습니다. 단, 서비스 관리를 위해 필요한 아이디 및 이메일 주소 등은 수정할 수 없습니다.<br />
2. 제1항의 변경사항을 회사에 알리지 않아 발생한 불이익에 대하여 회사는 책임지지 않습니다.<br />
<br /><br />
<b>제7조 개인정보의 보호 및 사용</b>
<br /><br />
회사는 관계 법령에 따라 회원 등록정보를 포함한 이용자의 개인정보를 보호하기 위해 노력하며, 이용자 개인정보의 보호 및 사용에 대해서는 관련 법령 및 회사의 개인정보 취급방침이 적용됩니다. 그러나 이용 회원의 귀책사유로 인해 노출된 정보에 대해서는 일체의 책임을 지지 않습니다.
<br /><br />
<b>제8조 회원의 아이디 및 비밀번호의 관리에 대한 의무</b>
<br /><br />
1. 회원의 아이디와 비밀번호에 관한 관리책임은 회원에게 있으며, 이를 제삼자가 이용하도록 하여서는 안 됩니다.<br />
2. 회사는 회원의 아이디가 개인정보 유출 우려가 있거나 반사회적 또는 미풍양속에 어긋나거나 회사 및 회사의 운영자로 오인한 우려가 있는 경우, 해당 아이디의 이용을 제한할 수 있습니다.<br />
3. 회원은 아이디 및 비밀번호가 도용되거나 제삼자가 사용하고 있음을 인지한 경우에는 이를 즉시 회사에 통지하고 회사의 안내에 따라야 합니다.<br />
4. 제3항의 상황에 해당 회원이 회사에 그 사실을 통지하지 않거나, 통지한 경우에도 회사의 안내에 따르지 않아 발생한 불이익에 대하여 회사는 책임지지 않습니다.<br />
5. 회원의 아이디, 비밀번호 관리 소홀로 인한 피해는 회사가 책임지지 않습니다. <br />
<br /><br />
<b>제9조 회원에 대한 통지</b>
<br /><br />
회사가 회원에 대해 통지를 하는 경우 이 약관에 별도 규정이 없는 한 서비스 내 공지사항, 전자우편주소 등으로 할 수 있습니다.
<br /><br />
<b>제10조 회사의 의무</b>
<br /><br />
1. 회사는 관련 법령 또는 이 약관을 위반하지 않으며, 계속적이고 안정적으로 서비스를 제공하기 위하여 최선을 다합니다.<br />
2. 회사는 개인정보 보호를 위해 보안시스템을 구축하며 개인정보 보호 정책을 공시하고 준수합니다.<br />
3. 회사는 회원으로부터 제기되는 의견이나 불만이 정당하고 객관적으로 인정될 경우에는 적절한 절차를 거쳐 즉시 처리하여야 합니다. 다만 즉시 처리가 곤란한 경우는 회원에게 그 사유와 처리 일정을 통보하여야 합니다.<br />
<br /><br />
<b>제11조 회원의 보증과 의무</b>
<br /><br />
1. 회원은 가입 신청 또는 회원 정보 변경 시 모든 사항을 사실대로 작성하여야 합니다. 만일 허위의 사실이나 타인의 정보를 이용하여 등록한 경우에는 일체의 권리를 주장하거나 보호받을 수 없습니다.<br />
2. 회원은 본 약관에서 규정하는 사항과 기타 회사가 정한 ‘제보마켓 작가회원 약관’ 및 회사가 공지하는 사항을 준수하여야 합니다.<br />
3. 회원은 서비스의 이용 권한, 계약상의 지위 등을 타인에게 양도, 증여할 수 없으며 이를 담보로 제공할 수 없습니다.<br />
4. 회원은 아래와 같은 행위를 하여서는 안 되며, 이러한 행위를 하는 경우에 회사는 회원 서비스 이용 제한 및 강제 탈퇴, 고소, 고발 등 적법 조치를 포함한 제재를 가할 수 있습니다.<br />
1) 서비스를 통해 얻은 콘텐츠를 서비스 이용 외의 목적으로 사용하거나 복제하는 행위, 제삼자에게 제공하는 행위<br />
2) 회사의 콘텐츠를 적법하게 구매하지 아니하고 이미지 및 동영상 캡쳐, 불법 다운로드 등 기타 회사로부터 허가받지 않은 수단을 사용해 무단으로 콘텐츠를 사용하는 행위<br />
3) 회사의 콘텐츠를 적법하게 구매하지 아니하고 콘텐츠의 트레이드 마크를 인위적으로 제거 혹은 블러 처리 등의 기타 편집을 통해 무단으로 사용하는 행위<br />
4) 제16조 (콘텐츠 이용 시 제한 사항)에 해당하는 행위<br />
5) 본 약관을 포함하여 기타 회사가 정한 제반 규정 또는 이용조건을 위반하는 행위<br />
6) 서비스에 위해를 가하거나 고의로 방해하는 행위<br />
7) 서비스 내 기능을 임의대로 조작하거나 고의적인 부정거래를 하는 행위<br />
8) 기타 관계 법령을 위반하는 행위<br />
9) 콘텐츠 거래가를 임의로 선동하거나 거래가 담합 등의 행위로 판명되거나 의심되는 행위 <br />
10) 매크로 등의 방법을 통해 의도적으로 과도한 트래픽을 유발하는 행위<br />
11) 회원의 직/간접적인 행위로 인하여 회사가 피해를 입히거나 입힐 가능성이 있는 행위<br />
12) 구매 신청 또는 변경시 허위 내용을 등록하는 행위<br />
13) 회사 및 기타 제 3자의 저작권 등 지적재산권을 침해하는 행위<br />
14) 회사 및 기타 제 3자의 명예를 손상시키거나 업무를 방해하는 행위<br />
15) 외설 도는 폭력적인 메시지·화상·음성 기타 공서양속에 반하는 정보를 사이트에 공개 또는 게시하는 행위<br />
5. 회원은 회사의 서비스를 이용 시 다음 각 호에 대한 책임과 의무를 다해야하며 상기 조항을 위반한 경우에는 고의성 여부를 막론하고 사용료의 최소 10배, 최대 100배를 현금으로 배상하여야 합니다. <br />
1) 출처 명시<br />
2) 구매한 저작물에 대한 개작 및 왜곡 금지<br />
3) 회사의 서비스 저작물에 대한 무단사용 및 무단 복제 금지<br />
4) 시사보도 목적으로만 사용이 가능하고 상업적인 목적으로는 사용을 제한한 저작물에 대한 사용 금지<br />
5) 구매신청한 용도 및 매체 외에 다른 용도 및 매체에 사용금지<br />
6) 구매한 저작물을 불법 또는 미풍양속에 반하는 용도 및 회사의 명예를 훼손시킬 수 있는 목적으로 사용 금지<br />
7) 이용자가 ‘당사 판매사이트’의 저작물을 구입, 사용함으로 인해 발생한 초상권, 상표권, 지적 재산권 등의 권리 침해에 대한 법적 책임<br />
<br /><br />
<b>제12조 회원의 계약해지</b>
<br /><br />
1. 회원은 언제든지 서비스 내 마이페이지를 통하여 서비스 이용계약 해지를 신청할 수 있으며 회사는 이를 즉시 처리합니다. <br />
2. 회원이 계약을 해지(회원탈퇴)할 경우, 관련법 및 개인정보처리방침에 따라 회사가 회원 정보를 보유하는 경우를 제외하고는 해지 즉시 회원의 모든 데이터는 소멸합니다. 단, 작가회원의 경우 회원탈퇴 신청을 한 시점에서 60일 이후에 모든 데이터가 소멸합니다. 이것은 탈퇴하려는 작가회원의 콘텐츠를 기존에 구매한 회원의 재다운로드를 보장하기 위함입니다. 또한, 탈퇴신청을 한 후 60일 내 해당 작가의 콘텐츠는 검색에서 노출되지 않으며, 새로운 구매가 불가능합니다. 추가 내용은 작가회원 이용약관 제11조를 따릅니다. <br />
3. 따라서 회원탈퇴 신청 후 같은 이메일 주소로 재가입을 원하는 작가회원의 경우 60일 이후에 가능합니다.<br />
4. 해지로 인하여 콘텐츠 이용 및 현금 전환 신청과 관련한 정보가 삭제됨에 따라 회원에게 발생하는 손해에 대해 회사는 책임지지 않습니다.<br />
<br /><br />
<b>13조 서비스의 제공</b>
<br /><br />
1. 회사는 회원에게 아래와 같은 서비스를 제공합니다.<br />
1) 콘텐츠 제공: 작가 회원이 업로드 한 동영상을 제공, 판매, 중개<br />
2) 콘텐츠 이용: 작가 회원 및 회사가 제공하는 콘텐츠를 이용, 구매, 중개<br />
3) 기타 회사가 추가 개발하여 회원에게 제공하는 일체의 서비스<br />
2. 서비스는 연중무휴, 1일 24시간 제공함을 원칙으로 합니다.<br />
3. 회사는 컴퓨터 등 정보통신설비의 보수점검, 교체 및 고장, 통신두절, 천재지변 또는 운영상 타당한 이유가 있는 경우 서비스의 제공을 일시적으로 중단할 수 있습니다. 다만 이 경우 그 사유 및 기간 등을 회원에게 사전 또는 사후에 공지합니다.<br />
4. 회사는 서비스의 제공에 필요한 경우 정기점검을 할 수 있으며, 정기점검시간은 서비스제공화면에 공지한 바에 따릅니다.<br />
<br /><br />
<b>제14조 콘텐츠의 관리</b>
<br /><br />
1. 회사에서 제공하는 콘텐츠는 회사의 독자적 판단에 따라 임의로 삭제 및 변경할 수 있습니다.<br />
2. 저작권 문제 등을 포함한 이유로 콘텐츠에 문제가 발생했을 때 회사는 이를 공지함(사이트 내 공지사항 게재 또는 전자메일 통지)으로써 회원들에 대한 알릴 의무를 다하는 것으로 하며, 회원은 그 사용을 중지해야 합니다. 공지 후 해당 콘텐츠 사용으로 인한 모든 법적 책임은 회사에 있지 아니하고 사용한 회원에게 있습니다.<br />
3. 작가회원약관 7조 콘텐츠 업로드 제한 참조 <br />
4. 제보마켓에서 사용 가능하도록 설정한 모든 콘텐츠 또는 기타 자료가 회원님이 소유하거나 제어하는 모든 저작권을 침해한다고 여겨지는 경우 제보마켓의 DMCA 저작권 침해 공지 정책에 명시된 방식에 따라 제보마켓에 알릴 수 있습니다.<br />
<br /><br />
<b>제15조 콘텐츠 저작권</b>
<br /><br />
1. 회사의 서비스에서 제공되는 콘텐츠의 저작권은 그 적법한 저작자 혹은 작가 회원 및 관련 권리자에게 있습니다. 회원은 회원 종류의 구분 및 지불한 금액에 따라 허용되는 목적의 범위 내에서만 사용 허락을 받는 것이며, 회사가 콘텐츠의 저작권 자체를 판매하는 것은 아닙니다.<br />
2. 회사의 사이트에서 제공되는 콘텐츠의 무단복제, 무단사용 또는 회사와 사전협의 없이 사이트에 게시된 ‘제보마켓 이용 약관’ 및 ‘제보마켓 작가 회원 약관’을 벗어나는 행위에 대해서는 저작권법 및 관련 법률에 의한 권리 침해로 간주합니다. 따라서 회원은 본 약관과 ‘제보마켓 작가 회원 약관’에서 명시적으로 허용하는 사용 이외의 콘텐츠 사용으로 발생하는 모든 종류의 손해 또는 책임에 대해 회사와 제삼자에게 손해를 배상하여야 합니다. 또한, 제삼자의 청구에 대해서는 자신의 비용으로 회사를 면책시켜야 합니다. <br />
3. 회원은 제보마켓을 이용함으로써 얻은 정보를 회사의 사전 허가없이 복제, 송신, 출판, 배포, 방송 기타 방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하게 해서는 안됩니다. <br />
4. 사이트에서 제공되는 콘텐츠에 등장하는 피사체(인물, 건물, 장소, 브랜드 등)에 대한 초상권, 재산권, 저작권, 상표권, 특허권, 디자인권(이하 ‘저작권 등’)은 회사가 보유하고 있지 않습니다. 따라서 콘텐츠의 사용 형태(광고, 출판 등의 상업적 목적)에 따라 회원이 사용 전에 직접 해당 권리를 취득해야 하는 경우가 있을 수 있으므로, 사용 목적에 따라 저작권 등에 대한 권리의 존재가 의심되는 일부 피사체에 대하여는 사전에 회사에 문의해야 합니다. 만일 회사와 사전협의 없이 사용하여 제삼자와 이들 권리에 대한 분쟁이 발생할 경우 회사는 일체의 책임을 지지 않으며, 모든 책임은 회원에게 있습니다. 회원은 이러한 사용으로 인한 제삼자의 청구에 대해 회사를 면책시켜야 합니다.<br />
5. 콘텐츠에 일부 포함될 수 있는 로고, 심볼, 캐릭터, 트레이드마크, 서비스마크 등에 대한 권리는 회사가 아닌 해당 권리자에게 있으므로 해당 권리의 침해가 되는 형태로 사용하려 할 경우 권리자로부터 사전에 승인을 받아야 합니다.<br />
6. 전항의 경우 회원이 사용 중지 및 삭제조치 처리에 협조하지 않아 발생하는 저작권 분쟁이나 이로 인해 확대된 손해에 대한 책임은 회원에게 있으며, 회사는 해당 분쟁이나 확대된 손해에 대하여 책임지지 않습니다.<br />
7. 위 각 항 중, 회사와 회원의 책임이 문제 되는 제삼자와의 분쟁이 있을 때, 회원은 회사의 동의 없이 그 제삼자에 대해 책임을 인정하거나 조정, 합의를 할 수 없으며, 만일 회원이 회사의 동의 없이 제삼자에 대한 책임인정, 조정, 기타 여하한 합의를 하더라도 이는 회사에 대해 효력을 미칠 수 없습니다.<br />
8. 콘텐츠를 업로드하거나 구매, 사용함으로써 발생하는 모든 법적 분쟁에 대해 회사는 책임지지 않습니다. 해당 분쟁에 대해서는 당사자 간의 합의 및 분쟁을 통해 해결해야 합니다. 회사는 당사자들 간의 원만한 합의를 위해 사실관계를 당사자에게 통지할 수는 있습니다.<br />
9. 침해 주장/DMCA 고지 – 제보마켓에서 사용 가능하도록 설정한 모든 콘텐츠 또는 기타 자료가 회원님이 소유하거나 제어하는 모든 저작권을 침해한다고 여겨지는 경우 제보마켓의 DMCA 저작권 침해 공지 정책에 명시된 방식에 따라 회사에 알릴 수 있습니다. – 별도 공개<br />
<br /><br />
<b>제16조 콘텐츠 이용제한</b>
<br /><br />
1. 회사의 모든 콘텐츠는 기본적으로 에디토리얼 전용 콘텐츠의 성격을 가지며 아래와 같은 용도로만 사용 가능합니다. <br />
1) 뉴스 기사를 포함한 보도 전용<br />
2) 기타 뉴스 가치가 있는 용도<br />
3) 공익성의 목적을 띄는 용도<br />
4) 보도, 시사, 평론, 평가 등 기타 비 상업적인 용도<br />
2. 뉴스 보도, 시사 해설, 출판 또는 기타 "에디토리얼"에 시각적 콘텐츠를 사용할 경우 기여자 및 회사에 인접한 크레딧이 동반되어야 하며, 그 형식은 실질적으로 다음과 같아야 합니다. “출저: 제보마켓"<br />
3. 모든 콘텐츠는 하위 라이선스를 발행하거나 재배포, 재분배, 재판매(배급, 양도, 대여, 임대, 임차)를 할 수 없습니다. <br />
4. 모든 콘텐츠는 아래와 같은 용도로 사용할 수 없습니다.<br />
1) 음란물 및 성인 광고, 유흥업소, 고리대금업, 기타 매춘업, 비뇨기과, 성형외과 등 사회 미풍양속과 일반 정서에 반하는 용도로 회사가 판단되는 경우 <br />
2) 특정 제품을 모델이 보증하는 형식의 과대광고   <br />
A. 예) 성형외과 비교 (before & after)<br />
B. 모델에 대한 허위사실을 기재하여 사용한 경우 <br /> 
C. 기타 과대광고 및 허위 광고 등<br />
3) 타인의 인격권을 훼손하는 용도<br />
A. 타인을 비방하거나 외설적으로 사용하는 등 명예를 훼손하는 용도   <br />
B. 신체 및 얼굴 등과 제삼자의 사진을 합성 혹은 재가공하는 행위<br />
C. 기타 타인의 인격권을 훼손하는 용도 등<br />
4) 기타 불법적인 사용<br />
5) 콘텐츠를 전시회/공모전, 로고, 심벌, 트레이드마크, 서비스마크, 상표 등으로 사용하는 경우<br />
6) 제보마켓 서비스를 단일 고객 또는 소수의 고객에게 전송하기 위한 수단으로 사용할 수 없으며 자신의 콘텐츠를 다운로드 할 수도 없습니다. 이러한 활동을 하거나 의심되는 경우 회사는 해당 회원의 계정을 해지하거나 거둔 수익금 지급을 유보하는 등 모든 권한을 행사할 수 있습니다. <br />
5. 당사는 콘텐츠 구매 신청에 대하여 다음 각호에  해당하는 경우 거절할 수 있습니다.<br />
1) 신청 내용에 허위, 기재누락, 오기가 있는 경우<br />
2) 이용자가 신청한 저작물이 계약조건, 저작물 사용규정, 사용범위 제한 및 기타 운영상의 이유로 이용자의 구매 목적에 사용되는 데 적합하지 않다고 당사가 판단한 경우<br />
3) 기타 구매신청에 승낙하는 것이 회사의 기술 및 행정처리 상 현저히 지장이 있다고 판단하는 경우<br />
6. 콘텐츠 사용 시 이용제한에 위반될 수 있다고 우려되거나 판단이 어려운 경우 회사 혹은 콘텐츠의 저작권자(작가 회원)에게 미리 문의 후 사용해야 합니다. 특히 인물 콘텐츠 사용의 경우 콘텐츠 이용제한에 있어 좀 더 민감하게 적용될 수 있으므로 인물 콘텐츠의 사용에 있어 회사 혹은 콘텐츠의 저작권자(작가 회원)에게 미리 문의 후 사용해야 합니다.<br />
7. 모든 콘텐츠는 비독점적 사용을 전제로 제공되는 것이며, 독점사용을 위하여는 별도협의가 필요합니다.<br />
8. 구매한 콘텐츠를 원본 형태 그대로 웹하드, 블로그, 카페, SNS(페이스북, 인스타그램 등) 등에 재배포, 재분배 목적으로 업로드하거나 스크랩하는 것을 금합니다.<br />
9. 다운로드 받은 콘텐츠 및 2차 저작물에 대하여 지적재산권의 권리등록을 금합니다. 2차 저작물을 만들어 판매 및 배포를 해야 하는 경우 회사에 미리 문의를 하시기 바랍니다.<br />
10. 저작물을 납품(제2차 사용자에게 제공) 시 납품된 저작물의 원본 소스는 동시에 납품할 수 없습니다. 만일 원본 저작물을 납품하여 클라이언트가 원본 콘텐츠를 이용하여 2차 저작물을 제작할 경우, 그로 인한 모든 책임은 납품한 회원에게 있습니다.<br />
11. 제보마켓에서 구매한 저작물을 타 사이트에 원본 그대로 업로드해 상품의 판매 가치를 떨어트리는 행위를 금합니다. <br />
<br /><br />
<b>제17조 콘텐츠의 요금</b>
<br /><br />
1. 제보마켓에서 판매하는 콘텐츠 라이선스는 회원 누구나 구매할 수 있으며, 다수의 회원이 동일한 콘텐츠에 대한 라이선스를 구매할 수 있습니다. 이러한 일반적인 경우에는 제보마켓에서 판매하는 모든 콘텐츠는 회사에서 지정한 동일한 금액에 판매 됩니다. <br />
2. 독점구매 등 기타 위에서 명시한 일반적인 구매에 해당되지 않는 경우에는 회사에 문의하여야 하며 회사가 별도로 정한 사용요금이 적용됩니다. <br />
<br /><br />
<b>제18조 콘텐츠의 환불</b><br />
<br /><br />
1. 복제가 가능한 디지털 콘텐츠의 특성상 구매한 콘텐츠에 대해서는 환불이 불가능합니다.<br />
2. 회사는 법적으로 요구되지 않는 한 어떠한 상황에서도 요금을 환불할 의무가 없습니다. 귀하는 회사가 구매 시점에 동의한 기간 동안 구매 금액 전액을 고객에게 청구할 수 있도록 승인한 것이 됩니다. 회사가 고객이 결제한 요금의 전부 또는 일부를 환불받을 자격이 있다고 판단한 경우 해당 금액은 고객이 원래 구매할 때 사용한 결제 방식으로만 환불됩니다. 고객이 유럽 연합 국가의 거주자이며 제보마켓에서 결제한 날짜로부터 십사(14) 일 이내에 계정을 취소하는 경우, 어떠한 시각적 콘텐츠에 대해서도 아직 다운로드를 하거나 사용 허가를 받지 않았을 것을 전제로, 회사는 그렇게 취소된 계정과 관련하여 고객이 지불한 모든 결제 금액을 환불해 드립니다. 계정을 취소하려면 고객 서비스에 연락해야 합니다.<br />
<br /><br />
<b>제19조 손해배상</b>
<br /><br />
서비스에서 제공되는 콘텐츠의 무단 복제, 무단 사용 또는 본 약관에 규정된 내용을 벗어나는 행위(사용범위 초과 사용 또는 콘텐츠 이용 시 제한 사항의 위반 사용 등)는 저작권법 및 관련 법률에 의한 저작권 침해에 해당합니다. 이 경우 회사에 발생한 손해를 배상하여야 하며, 회사가 이를 초과하는 손해를 입은 경우 그 초과 손해도 배상해야 합니다. 회원의 위와 같은 행위로 인하여 제삼자가 회사에 대해 손해배상을 청구할 경우, 자신의 비용으로 회사를 면책시켜야합니다.<br />
<br /><br />
<b>제20조 면책조항</b>
<br /><br />
1. 회사는 천재지변, 전쟁 및 기타 이에 따르는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 대한 책임이 면제됩니다.<br />
2. 회사는 기간통신 사업자가 전기통신 서비스를 중지하거나 정상적으로 제공하지 아니하여 손해가 발생한 경우 책임이 면제됩니다.<br />
3. 회사는 서비스용 설비의 보수, 교체, 정기점검, 공사 등 부득이한 사유로 발생한 손해에 대한 책임이 면제됩니다.<br />
4. 회원들의 접속 폭주로 인한 서비스 속도의 지체나 일시적 오류 그리고 회사의 서비스개선을 위한 정비 등으로 인한 서비스의 일시 중단에 대하여는 환불 또는 배상하지 않습니다.<br />
<br /><br />
<b>제21조 서비스 관련 분쟁 해결</b>
<br /><br />
1. 회사는 서비스 이용과 관련한 회원의 의견이나 불만 사항을 신속하게 처리합니다. 단, 신속한 처리가 곤란한 경우에는 그 사유와 처리일정을 통보하여 드립니다.<br />
2. 회사와 회원 간에 발생한 분쟁은 전자거래기본법에 의해 설치된 전자거래 분쟁 조정위원회의 조정절차에 의해 해결할 수 있습니다.<br />
<br /><br />
<b>제22조 서비스의 종료</b>
<br /><br />
1. 회사는 서비스를 종료하고자 하는 날로부터 3개월 이전에 서비스 내 공지사항 및 전자우편 등의 방법으로 회원에게 알립니다.<br />
2. 종료 통지일로부터 서비스 종료일까지 서비스 일부가 제한될 수 있습니다.<br />
3. 서비스 종료 통지를 했음에도 불구하고 종료 전까지 정산을 받지 못한 회원의 정산 금액은 회사에 귀결됩니다. <br />
<br />
<b>제23조 준거법 및 합의관할</b>
<br />
1. 회사와 회원 간에 제기된 법적 분쟁은 대한민국 법을 준거법으로 합니다.<br />
2. 서비스 이용으로 발생한 분쟁에 대하여 소송이 제기될 경우 회사의 소재지를 담당하는 법원을 관할 법원으로 합니다.<br />
<br />
</div>`;

export const UserText = `<div>
<b>제1조 목적</b>
<br />
본 작가 회원 가입약관은 주식회사 트립클립(tripClip Co.,Ltd)(이하 회사) 이 제공하는 제보마켓 웹사이트 서비스(www.jebomarket.com) 이용과 관련하여 회사와 작가 회원과의 권리, 의무 및 책임 사항, 기타 필요한 사항을 규정함을 목적으로 합니다.또한 제보마켓에 콘텐츠 업로드 및 제출에 관련하여 콘텐츠의 지적 재산권 및 이용 권한, 금지조항, 사용허가, 소유권 등 기타 작가 회원에게 필요한 사항을 규정함을 목적으로 합니다.<br />
<br /><br />
<b>제2조 정의</b>
<br /><br />
본 작가 회원 가입약관의 정의는 회사의 이용약관을 따릅니다.
<br /><br />
<b>제3조 이용계약 체결</b>
<br /><br />
1. 서비스 이용계약은 작가 회원이 되고자 하는 자가 작가 가이드 및 튜토리얼을 숙지하고 약관의 내용에 대하여 동의를 한 다음 본인 인증을 거쳐 회사가 이를 수락함으로써 체결됩니다.<br />
2. 서비스를 통해 콘텐츠를 업로드 혹은 제출함으로써 작가 회원은 본 약관에 동의하는 것으로 간주됩니다. 또한, 이 약관은 작가 회원이 업로드한 모든 콘텐츠에 적용이 됩니다.<br />
3. 의문의 소지를 없애기 위해, 본 약관은 제보마켓 서비스를 통해 콘텐츠를 업로드 하거나, 작가 회원 자격을 유지하는 기간 동안 모든 콘텐츠에 적용됩니다.<br />
4. 작가 가입과 동시에 가입한 E-mail을 통해 제보마켓의 새로운 정보, 공지사항, 작가 실적 및 활동내역을 수신받는데 동의한 것으로 간주합니다.<br />
<br /><br />
<b>제4조 라이선스 약관</b>
<br /><br />
1. 작가 회원은 제보마켓에 업로드하는 콘텐츠의 라이선스가 다른 회사, 플랫폼, 혹은 개인에게 제공되지 않은 제보마켓만의 독점 라이선스임을 보장해야합니다. <br />
3. 제보마켓에 업로드한 영상의 저작권자인 작가가 개인 SNS나 다른 형태의 온/오프라인 플랫폼에 전시할 경우, 반드시 제보마켓에서 제공된 링크를 사용해서 미리보기 형태(워터마크, 로고 등 제보마켓의 트레이드 마크가 표시되어있는 형태)의 변형없는 상태로 공유해야 합니다. <br />
4. 제보마켓에 업로드한 콘텐츠의 라이선스는 어떤 미디어 형태로든 사용될 수 있으며, 구매자는 제보마켓 내에서 라이선스를 정당하게 구매함으로써 해당 콘텐츠를 다른 이미지, 텍스트, 그래픽, 오디오 등과 결합해서 사용할 수 있습니다. <br />
5. 본 가입약관에 동의함으로써, 회사는 작가 회원이 업로드 혹은 제출한 콘텐츠에 대해 사용 및 판매, 중개 권한을 가집니다.<br />
6. 콘텐츠의 사용 권한에 대한 자세한 사항은 제5조에 서술합니다.<br />
7. 작가 회원이 업로드한 콘텐츠를 삭제한 경우에도, 회원은 기존에 서비스를 통해 다운로드 받은 콘텐츠에 대한 사용 권리를 유지합니다.<br />
<br /><br />
<b>제5조 콘텐츠 사용 권한</b>
<br /><br />
1. 작가 회원의 콘텐츠는  아래와 같은 용도로 사용 가능합니다.<br />
1) 뉴스 기사를 포함한 보도 전용<br />
2) 기타 뉴스 가치가 있는 용도<br />
3) 공익성의 목적을 띄는 용도<br />
4) 보도, 시사, 평론, 평가 등 기타 비 상업적인 용도<br />
2. 작가 회원의 콘텐츠는 판매촉진 및 회사의 홍보를 위하여, 제보마켓의 마케팅 및 홍보 목적으로 사용 및 가공할 수 있습니다.<br />
3. 1항을 목적으로 작가 회원의 콘텐츠는 회원이 언제든지 구매 및 다운로드 가능합니다.<br />
4. 1항에 포함되지 않는 목적으로 사용하기를 원하는 구매자가 있을 경우, 해당부서 담당자(cs@jebomarket.com)에게 별도로 문의해야 합니다. <br />
<br /><br />
<b>제6조 지적 재산권(콘텐츠의 저작권)</b>
<br /><br />
회사의 서비스에서 제공되는 콘텐츠의 저작권은 그 적법한 저작자 혹은 작가 회원 및 관련 권리자에게 있습니다.
<br /><br />
<b>제7조 콘텐츠 업로드 제한</b>
<br /><br />
1. 회사는 아래와 같은 내용이 포함된 콘텐츠의 업로드를 금지합니다. <br />
1) 제삼자의 저작권, 기타 지적재산권, 초상권 등을 침해한다고 판단되는 내용<br />
2) 음란물, 명예 훼손 또는 그 외 불법적이거나 비도덕적인 콘텐츠가 포함된 내용<br />
3) 불법적이거나 폭력적인 행동을 부추기거나 유도하는 내용<br />
4) 동물 학대 또는 동물에게 폭력을 부추기거나 유도하는 내용<br />
5) 사기성 광고 또는 불공정 경쟁을 일으키는 내용<br />
6) 방송, 유선 방송, OTT 동영상 서비스(Netflix, Hulu, Amazon 등) 또는 극장을 통해 배포된 멀티미디어 저작물에 포함된 동영상 사용<br />
7) 기타 무의미한 콘텐츠, 개인 소장용 콘텐츠 등 회사가 판단하여 플랫폼의 성격과 맞지 않는 경우<br />
2. 위와 같은 문제로 콘텐츠에 문제가 발생할 경우, 관련된 모든 책임은 콘텐츠를 업로드한 작가 회원에게 있으며, 회사는 어떠한 책임도 부담하지 않습니다.<br />
3. 허위의 정보를 입력하거나 작가약관 및 회사에서 제공한 관련 안내 사항을 충분히 숙지하지 않고 동영상을 업로드하여 문제가 발생한 경우, 관련된 모든 책임은 콘텐츠를 업로드한 작가 회원에게 있으며, 회사는 어떠한 책임도 부담하지 않습니다.<br />
4. 회사는 제7조 콘텐츠 업로드 제한 내용에 따라 업로드가 거부된 콘텐츠에 대해 개별 이유를 설명할 의무가 없습니다.<br />
<br /><br />
<b>제8조 초상권 및 재산권 규정</b>
<br /><br />
1. 회사는 초상권 및 재산권 등의 권리에 대해 존중합니다.<br />
2. 작가 회원은 초상권 및 재산권 등의 권리를 침해하지 않기 위해 최선을 다합니다.<br />
3. 회사는 인물사진 등 초상권 및 재산권 등의 권리가 필요한 경우, 이에 대해서 작가 회원에게 별도의 동의를 요구할 수 있습니다. 이러한 요구는 콘텐츠 판매시 별도의 계약서를 통해 이루어집니다.<br />
4. 회사는 해당 콘텐츠의 권리 여부가 불분명한 콘텐츠에 대해서 언제든지 삭제할 수 있습니다. 이에 대한 모든 책임은 작가 회원에게 있으며, 회사는 어떠한 책임도 부담하지 않습니다.<br />
<br /><br />
<b>제9조 에디토리얼 전용 콘텐츠</b>
<br /><br />
1. 에디토리얼 전용 콘텐츠는 아래와 같은 이유로 인해 상업적으로 사용할 수 없는 콘텐츠입니다.<br />
1) 공인을 포함한 유명인 콘텐츠 (연예인, 스포츠선수, 정치인 등)<br />
2) 초상권 동의서 없는 인물 콘텐츠<br />
A. 예) 거리에서 찍은 영상에 포함된 사람들<br />
B. 예) 뒷모습이나 옆모습, 실루엣 등으로 누군지 식별이 가능한 피사체<br />
3) 재산권 동의 없는 상표가 등록된 로고 또는 서비스 마크가 포함된 콘텐츠<br />
A. 예) 특정 브랜드 매장과 로고가 찍힌 길거리<br />
B. 예) 배경에 나오는 경복궁 혹은 에펠탑 등의 유명한 건축물<br />
4) 사유 재산 또는 상표 등록된 재산에서 촬영된 콘텐츠 (공연 장소, 실내 건물 등)<br />
5) 상업적 용도로 사용되고 있는 광고, 상품, 제품 포장 등<br />
2. 에디토리얼 전용 콘텐츠는 아래와 같은 용도로 사용 가능합니다.<br />
1) 뉴스 기사를 포함한 보도 전용<br />
2) 기타 뉴스 가치가 있는 용도<br />
3) 보도, 시사, 평론, 평가 등 기타 비 상업적인 용도<br />
4) 뉴스 보도, 시사 해설, 출판 또는 기타 "에디토리얼"에 시각적 콘텐츠를 사용할 경우 기여자 및 회사에 인접한 크레딧이 동반되어야 하며, 그 형식은 실질적으로 다음과 같아야 합니다. "출처: 제보마켓"<br />
<br /><br />
제10조 수익금
<br /><br />
1. 판매된 콘텐츠는 회사가 정한 절차에 따라 현금으로 회원에게 송금되며 송금방식은 계좌이체, 무통장입금 등의 지급 방식을 사용합니다. <br />
2. 판매된 콘텐츠에 대한 정산을 받으려는 회원은 회사가 정한 추가 정보를 성실히 입력하여야 하며 해당 정보가 불분명하거나 일치하지 않는 경우 회사는 해당 정보에 대해 재요청할 수 있습니다.<br />
3. 재요청 시에도 합당한 정보를 제공하지 못한 회원은 비록 자신의 콘텐츠가 판매되었을지라도 해당 금액을 정산받지 못하며, 정산받지 못한 금액은 매년 연말 정산시 회사에 귀속됩니다. <br />
4. 회사는 모든 콘텐츠의 판매대금에서 회사의 중개수수료율에 따른 중개수수료를 제합니다. <br />
5. 회사는 작가 회원이 지정한 은행 계좌에 해당 금액을 입금함으로써 적법하게 지급을 완료한 것으로 봅니다.<br />
6. 수익금 발생 및 송금 시 발생하는 수수료 및 제세공과금은 회원이 부담합니다. (VAT, 원천세, 카드 수수료 등)<br />
7. 국세청 조세조약에 의거하여 해당 국가별 조약에 따라 세금을 부과할 수 있습니다. <br />
8. 귀하의 거주 국가의 법률에 따라 제보마켓이 판매세, 부가가치세, 상품 및 서비스세와 같은 간접세를 수금해야 하는 경우, 귀하는 그러한 간접세를 지불할 책임이 있습니다. 제보마켓 또는 귀하가 직접세나 간접세를 수금 또는 송금해야 하는 경우, 귀하는 귀하의 거주 국가의 해당 법률에 따라 전술한 세금을 자진 납부해야 할 수 있습니다.<br />
9. 국세기본법시행령 제 68조 2항에 따라, 소득세 신고를 위해 작가 회원의 주민등록번호, 실명, 전화번호 등의 추가적인 정보를 요구할 수 있습니다.<br />
국세기본법시행령 제 68조 2항<br />
세법에 따른 원천징수의무자는 원천징수 사무를 수행하기 위하여 불가피한 경우 제 1항에 따른 개인정보가 포함된 자료를 처리할 수 있다.<br />
<br /><br />
<b>제11조 수익금 정정, 취소 및 소멸</b>
<br /><br />
1. 수익금에 오류가 발생한 경우 회원은 오류 발생일로부터 90일 이내에 회사에 정정 요구를 할 수 있으며, 회사는 정당한 요구임이 확인된 경우 정정요구일로부터 90일 이내에 정정하여야 합니다.<br />
2. 작가 회원의 탈퇴 시 가지고 있던 정산 받지 않은 수익금 모두 소멸합니다.<br />
3. 회사는 어떠한 이유로든 작가 회원에게 수익금을 과다 지불한 경우 미지급된 수익금에서 해당 과다 지불금을 공제하거나, 과다 지불한 해당 금액만큼을 즉각 상환하도록 요구할 권리를 지닙니다.  <br />
<br /><br />
<b>제12조 약관의 해석</b>
<br /><br />
1. 작가 회원 가입약관에서 규정하지 않은 조항들은 이용약관을 준용합니다.<br />
2. 이용약관, 작가 회원 가입약관 사이에 약관 조항 해석의 불일치 혹은 충돌이 발생하는 경우 이용약관을 우선하여 적용합니다.<br />
</div>`;

export const Dmca = `<div>
㈜제보마켓(이하 “제보마켓”)은 타인의 지적 재산권을 존중하며, 제보마켓 웹사이트 사용자들 또한 그러할 것이라고 믿습니다.
<br />
저작권 침해 신고 절차: <br />
저작권의 보호를 받는 본인의 저작물이 저작권이 침해되는 방식으로 당사 웹 사이트에서 제공되고 있다고 생각하는 경우, 1998년 디지털 밀레니엄 저작권법(“DMCA”)에 명시된 바와 같이 당사 저작권 담당자에게 알릴 수 있습니다. 통보가 DMCA에 따라 효력을 갖기 위해서는 다음과 같은 정보를 기재하여 제보마켓의 지정된 담당자에게 저작권 침해 청구를 서면으로 발송해야 합니다.
<br /><br />
1. 침해된 독점 권리 소유자가 승인한 대리인의 실물 또는 전자 서명 <br />
2. 저작권이 침해된 저작물을 식별할 수 있는 정보 또는 (저작권의 보호를 받는 복수의 저작물이 단일 온라인 사이트에서 침해되었고 이에 대한 청구를 한 건의 통보로 제기하는 경우) 해당 저작물을 대표하는 목록 <br />
3. 저작물의 저작권을 침해하는 또는 저작권 침해의 주체가 되는 자료를 식별할 수 있는 정보와 제보마켓이 이를 제거하거나 이에 대한 접근을 차단하기 위해 해당 자료를 찾는 데 참고할 방법<br />
4. 제보마켓이 저작권 침해 청구를 제기하는 당사자에게 연락을 취하는 데 필요한 정보(주소, 전화번호, 전자 우편 주소 등) <br />
5. 저작권 소유자와 그 대리인 또는 법적인 측면에서 보았을 때 자료가 허가받지 않은 방식으로 사용되었음을 고발 당사자가 확인하는 진술서 <br />
6. 신고한 내용이 정확하며, 침해를 주장하는 독점권의 소유자를 대신하도록 고발 당사자가 허가받았으며 위증 시 위증죄의 처벌을 받는다는 진술서 <br />
<br />
위 정보를 기재하여 다음과 같은 제보마켓의 지정된 담당자에게 서면으로 통보해야 합니다.
<br />
저작권 침해 청구를 수신할 담당자의 이름: YH Shim<br />
지정된 담당자의 주소: 서울특별시 중구 청계천로 40, 12층 1202호 (다동) ㈜트립클립<br />
수신인: YH Shim<br />
지정된 담당자의 이메일 주소: shim@tripclip.kr<br />
<br />
온라인 자료가 저작권을 침해한다는 사실을 고의로 허위 진술하는 자는 연방법에 따라 민사처벌의 대상이 될 수 있습니다. 여기에는 제보마켓, 저작권 소유자 또는 허위 진술에 따른 결과로 피해를 받은 저작권 소유자의 라이선스 이용자가 부과한 금전적 피해, 소송 비용 및 변호사 수임료가 포함됩니다. 위증을 저지른 자는 형사 기소될 수 있습니다.
<br />
</div>`;
