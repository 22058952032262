import React, { Component } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, Grid, withStyles } from '@material-ui/core';

const styles = (theme) => ({
  loginInput: {
    '& input': {
      backgroundColor: '#0c0e10',
      [theme.breakpoints.down('md')]: {},
    },
  },
  snsButton: {
    height: '5vh',
    borderRadius: '5px',
    backgroundColor: '#e61737',
    color: '#ffffff',
    fontWeight: 'bold',
    width: '70%',
    margin: theme.spacing(2, 0),
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    [theme.breakpoints.down('md')]: {
      height: '50px',
      borderRadius: '5px',
      width: '90%',
      margin: theme.spacing(1, 0),
    },
  },
  dialogBorder: {
    '& .MuiPaper-rounded': {
      borderRadius: '0px',
    },
  },
});

class LoginDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '', //이메일(ID)
      matches: window.matchMedia('(max-width:991px)').matches,
      showPassword: false, //비밀번호 보기
    };
  }

  handleChange = (event, newValue) => {
    this.setState({ tabValue: newValue });
  };

  render() {
    const { open, onClose, classes } = this.props;
    return (
      <Dialog className={classes.dialogBorder} fullWidth={true} maxWidth={'sm'} onClose={onClose} open={open}>
        <DialogTitle style={{ backgroundColor: '#171A1D', paddingTop: '28px' }}>
          <Grid container item xs={12}>
            <Grid container item xs={1}></Grid>
            <Grid container item xs={10} alignContent="center" justifyContent="center">
              <b style={{ color: '#ffffff' }}>가입완료</b>
            </Grid>
            <Grid container item xs={1}>
              {/* <IconButton onClick={onClose}>
                <CloseIcon style={{ color: '#ffffff' }} />
              </IconButton> */}
            </Grid>
          </Grid>
        </DialogTitle>

        <DialogContent style={{ backgroundColor: '#171A1D' }}>
          <Grid id="positioning_grid" container justifyContent="center">
            <Grid container justifyContent="center" item xs={10}>
              {/* 본문 */}
              <Grid container justifyContent="center">
                <Grid container justifyContent="center" style={{ color: '#ffffff', textAlign: 'center', fontSize: '16.5px' }}>
                  {this.props.nickName}님!
                  <br />
                  제보마켓에 가입해주셔서 감사합니다.
                </Grid>
              </Grid>

              {/* 닫기 버튼 */}
              <Grid container justifyContent="center">
                <Button
                  className={classes.snsButton}
                  fullWidth={true}
                  style={{ fontWeight: 'bold', color: '#ffffff' }}
                  onClick={() => this.props.handleOpenLogin()}
                >
                  로그인 하기
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  }
}

export default withStyles(styles)(LoginDialog);
