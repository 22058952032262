import axiosInstance from '../constants/AxiosInstance';
import { API_URL } from '../constants';

/* 매거진 리스트 가져오기 */
const getMagazineList = (param) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(`${API_URL}/news/`, param)
      .then((res) => {
        resolve(res.data.value);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

/* 매거진 정복 가져오기 */
const getMagazineInfo = (param) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(`${API_URL}/news/${param.id}`, param)
      .then((res) => {
        resolve(res.data.value);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const MagazineService = {
  getMagazineList,
  getMagazineInfo,
};

export default MagazineService;
