import React, { Component } from 'react';
import withStyles from '@material-ui/styles/withStyles';
import { withRouter } from 'react-router-dom';
import { Grid, IconButton, withWidth } from '@material-ui/core';

import axiosInstance from '../constants/AxiosInstance';
import { API_URL } from '../constants';

import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import BookIcon from '@material-ui/icons/Book';

import { connect } from 'react-redux';

const styles = theme => ({
  container: {
    // position: 'absolute',
    bottom: 0,
    width: '100%',
  },
  footer: {
    // height: '80px',
    backgroundColor: '#171A1D',
    borderTop: '1px solid rgb(54, 56, 59)',
    color: '#b9b9b9',
    marginTop: '15px',
    '& div': {
      fontSize: '1em',
      color: '#fff',
      lineHeight: '1.8',
      // fontWeight: '500',
    },
    '& span': {
      marginRight: '10px',
      float: 'left',
    },
    padding: theme.spacing(3, 0),
  },
  title: {
    fontWeight: 'bold',
  },
  logo: {
    width: '80px',
    marginLeft: '7px',
  },
  contact: {
    padding: '0 8px 8px 8px',
    '& >div': {
      float: 'left',
    },
  },
  chips: {
    color: 'white',
    minWidth: '100px',
    height: '25px',
    // '& span': {
    //   padding: 5,
    //   fontSize: '1.1em',
    // },
  },
  bottom: {
    borderTop: '3px solid rgb(54, 56, 59)',
    padding: '0 8px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItem: 'center',
  },
  btnGroup: {
    '& >button': {
      color: '#fff',
    },
  },
});

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: '',
      matches: window.matchMedia('(max-width:480px)').matches,
    };
  }

  componentDidMount() {
    this.getText();
  }

  getText = async () => {
    let response = await axiosInstance.get(`${API_URL}/info/company`, { headers: { Pragma: 'no-cache' } });

    this.setState({ text: response.data.value });
  };

  goSite = url => {
    if (url) {
      window.open(`${url}`, '_blank');
    }
  };

  render() {
    const { classes } = this.props;
    const { text } = this.state;

    return (
      <div className={classes.container}>
        {text && (
          <div className={classes.footer}>
            <div style={{ width: !this.state.matches && this.props.menuValue ? '20%' : '0%' }}></div>
            <div style={{ width: !this.state.matches && this.props.menuValue ? '80%' : '100%', float: 'right' }}>
              <Grid item container xs={12} md={9} spacing={1} style={{ margin: '0 auto', paddingBottom: '10px' }}>
                <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', paddingRight: 16 }}>
                  <img src='/images/jbmarket/topbar_logo@2x.png' alt='' className={classes.logo} />
                </Grid>
                <Grid item xs={12}>
                  <div className={classes.contact}>
                    <div>
                      <span>{`상호명: ${text.name}`}</span>
                      <span>{`대표자명: ${text.ceo}`}</span>
                      <span>{`사업자등록번호: ${text.businessNumber}`}</span>
                      <span>{`통신판매업신고: ${text.license1}`}</span>
                    </div>
                    <div>
                      <span>{`주소: ${text.addr}`}</span>
                      <span>{`이메일: ${text.email}`}</span>
                    </div>
                    <div>
                      {text.desc.split('\\n').map(line => {
                        return (
                          <span key={line}>
                            {line}
                            <br />
                          </span>
                        );
                      })}
                    </div>
                  </div>
                </Grid>
              </Grid>
              <Grid item container xs={12} md={9} spacing={1} style={{ margin: '0 auto' }}>
                <Grid item xs={12} className={classes.bottom}>
                  <span style={{ fontSize: '1rem', color: 'rgba(255, 255, 255, 0.5)', lineHeight: '34px' }}>{text.copyright}</span>
                  <div className={classes.btnGroup}>
                    <IconButton onClick={() => this.goSite(text.facebook)}>
                      <FacebookIcon />
                    </IconButton>
                    <IconButton onClick={() => this.goSite(text.instagram)}>
                      <InstagramIcon />
                    </IconButton>
                    <IconButton onClick={() => this.goSite(text.blog)}>
                      <BookIcon />
                    </IconButton>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    menuValue: state.changeMenu.value,
  };
};

export default withRouter(withStyles(styles)(withWidth()(connect(mapStateToProps)(Footer))));
