import React, { Component } from 'react';
import DeleteDialog from './DeleteDialog';

import axiosInstance from '../../constants/AxiosInstance';
import { API_URL, phoneNumber } from '../../constants';

import { Button, Dialog, DialogTitle, DialogContent, Grid, IconButton, withStyles, TextField } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import swal from 'sweetalert';

import Alert from '../../components/Alert';

const styles = theme => ({
  inputGrid: {
    padding: theme.spacing(1, 3),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1, 0),
    },
  },
  loginInput: {
    '& input': {
      color: '#fefefe',
      padding: '0 1vw ',
      height: '5vh',
      backgroundColor: '#0c0e10',
      borderRadius: '5px',
      [theme.breakpoints.down('md')]: {
        padding: '0 3vw ',
        height: '50px',
      },
    },
  },
  emailInput: {
    '& input': {
      color: 'rgba(170, 170, 170, 0.5)',
      padding: '0 1vw ',
      height: '5vh',
      backgroundColor: '#0c0e10',
      borderRadius: '5px',
      [theme.breakpoints.down('md')]: {
        padding: '0 4vw ',
        height: '50px',
      },
    },
  },
  colorBtn: {
    backgroundColor: '#e61737',
    border: '2px solid #e61737',
    borderRadius: '5px',
    color: '#ffffff',
    height: '5vh',
    fontWeight: 'bold',
    margin: theme.spacing(2, 3),
    [theme.breakpoints.down('md')]: {
      border: '1px solid #e61737',
      borderRadius: '5px',
      height: '50px',
      margin: theme.spacing(1, 0),
    },
  },
  dialogContainer: {
    '& .MuiPaper-rounded': {
      borderRadius: '0px',
    },
  },
  btn: {
    backgroundColor: '#0c0e10',
    border: '2px solid #0c0e10',
    borderRadius: '5px',
    color: '#ffffff',
    height: '5.5vh',
    fontWeight: 'bold',
    margin: theme.spacing(0, 3, 2, 3),
    [theme.breakpoints.down('md')]: {
      border: '1px solid #0c0e10',
      borderRadius: '5px',
      height: '50px',
      margin: theme.spacing(1, 0),
    },
  },
});

class ChangeInfoDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      matches: window.matchMedia('(max-width:991px)').matches,
      deleteDialog: false, //삭제 Dialog
      userInfo: {
        name: this.props.userData.name,
        id: this.props.userData.email,
        phone_number: this.props.userData.phone_number,
        bank: this.props.userData.bank,
        bank_account: this.props.userData.bank_account,
      },
    };
  }

  handleChange(type, e) {
    let newObj = this.state.userInfo;

    const userArr = Object.entries(this.state.userInfo);

    for (const [key, value] of userArr) {
      if (key === type) newObj[key] = type === 'phone_number' ? phoneNumber(e.target.value) : e.target.value;
    }

    this.setState({ userInfo: newObj });
  }

  summitChange() {
    // swal('수정완료', '변경이 완료되었습니다', 'success');
    this.onClose();
  }

  openDelete = () => {
    this.setState({ deleteDialog: true });
  };

  cloeseDelete = () => {
    this.setState({ deleteDialog: false });
  };

  userInfoEdit = () => {
    const userArr = Object.entries(this.state.userInfo);
    const KR_MOBILE_REG = /^01(?:0|1|[6-9])-(?:\d{3}|\d{4})-(\d{4})$/;

    for (const [key, value] of userArr) {
      switch (key) {
        case 'name':
          if (value === '') Alert('', '이름을 입력해주세요.');
          break;
        case 'phone_number':
          if (!KR_MOBILE_REG.test(value)) Alert('', '전화번호를 확인해주세요.');
          break;
        case 'bank':
          if (value === '') Alert('', '은행을 입력해주세요.');
          break;
        case 'bank_account':
          if (value === '') Alert('', '계좌번호를 입력해주세요.');
          break;
      }
    }

    axiosInstance.put(`${API_URL}/user/`, this.state.userInfo, { headers: { Pragma: 'no-cache' } }).then(response => {
      if (response.status === 200) {
        Alert('', '수정되었습니다.', '', '', () => {
          this.props.reRender();
        });
      }
    });
  };

  render() {
    const { open, onClose, classes } = this.props;

    return (
      <Dialog className={classes.dialogContainer} fullWidth={true} maxWidth={'xs'} onClose={onClose} open={open}>
        <DialogTitle style={{ backgroundColor: '#171A1D' }}>
          <Grid container justifyContent='center'>
            <Grid container item xs={11}>
              <Grid container item xs={10} alignContent='center'>
                <b style={{ color: '#ffffff' }}>개인정보 변경</b>
              </Grid>
              <Grid container item xs={2} justifyContent='flex-end'>
                <IconButton onClick={onClose}>
                  <CloseIcon style={{ color: '#ffffff' }} />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </DialogTitle>

        <DialogContent style={{ backgroundColor: '#171A1D' }}>
          <Grid id='positioning_grid' container justifyContent='center'>
            <Grid container justifyContent='center' className={classes.inputGrid}>
              <Grid container justifyContent='flex-start' style={{ color: '#fff', fontWeight: 'bold', paddingBottom: this.props.theme.spacing(1) }}>
                이름
              </Grid>
              <Grid container justifyContent='center'>
                <TextField
                  id='name'
                  name='name'
                  value={this.state.userInfo.name}
                  className={classes.loginInput}
                  fullWidth={true}
                  variant='outlined'
                  onChange={e => this.handleChange('name', e)}
                  placeholder='이름을 입력해 주세요'
                />
              </Grid>
            </Grid>

            {/* todo:이메일은 아이디라 수정 가능한지 확인 필요 */}
            <Grid container justifyContent='center' className={classes.inputGrid}>
              <Grid container justifyContent='flex-start' style={{ color: '#fff', fontWeight: 'bold', paddingBottom: this.props.theme.spacing(1) }}>
                이메일
              </Grid>
              <Grid container justifyContent='center'>
                <TextField
                  id='email'
                  type='email'
                  name='email'
                  value={this.state.userInfo.id}
                  className={classes.emailInput}
                  fullWidth={true}
                  variant='outlined'
                  disabled
                  placeholder='이메일을 입력해 주세요'
                />
              </Grid>
            </Grid>

            <Grid container justifyContent='center' className={classes.inputGrid}>
              <Grid container justifyContent='flex-start' style={{ color: '#fff', fontWeight: 'bold', paddingBottom: this.props.theme.spacing(1) }}>
                전화번호
              </Grid>
              <Grid container justifyContent='center'>
                <TextField
                  id='phone_number'
                  name='phone_number'
                  value={this.state.userInfo.phone_number}
                  className={classes.loginInput}
                  fullWidth={true}
                  variant='outlined'
                  onChange={e => this.handleChange('phone_number', e)}
                  placeholder='전화번호'
                />
              </Grid>
            </Grid>
            <Grid container justifyContent='center' className={classes.inputGrid}>
              <Grid container justifyContent='flex-start' style={{ color: '#fff', fontWeight: 'bold', paddingBottom: this.props.theme.spacing(1) }}>
                은행명
              </Grid>
              <Grid container justifyContent='center'>
                <TextField
                  id='bank'
                  name='bank'
                  value={this.state.userInfo.bank}
                  className={classes.loginInput}
                  fullWidth={true}
                  variant='outlined'
                  onChange={e => this.handleChange('bank', e)}
                  placeholder='은행명을 입력해 주세요'
                />
              </Grid>
            </Grid>
            <Grid container justifyContent='center' className={classes.inputGrid}>
              <Grid container justifyContent='flex-start' style={{ color: '#fff', fontWeight: 'bold', paddingBottom: this.props.theme.spacing(1) }}>
                계좌번호
              </Grid>
              <Grid container justifyContent='center'>
                <TextField
                  id='bank_account'
                  name='bank_account'
                  value={this.state.userInfo.bank_account}
                  className={classes.loginInput}
                  fullWidth={true}
                  variant='outlined'
                  onChange={e => this.handleChange('bank_account', e)}
                  placeholder='계좌번호를 입력해 주세요'
                />
              </Grid>
            </Grid>
            <Grid container justifyContent='center'>
              <Button fullWidth={true} className={classes.colorBtn} onClick={() => this.userInfoEdit()}>
                변경 완료
              </Button>
              <Button fullWidth={true} className={classes.btn} onClick={this.openDelete}>
                탈퇴하기
              </Button>
            </Grid>
          </Grid>
          <DeleteDialog open={this.state.deleteDialog} onClose={this.cloeseDelete} handleCloseMypage={this.props.handleCloseMypage} />
        </DialogContent>
      </Dialog>
    );
  }
}

export default withStyles(styles, { withTheme: true })(ChangeInfoDialog);
