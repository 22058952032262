import React from 'react';
import { withStyles } from '@material-ui/core';
import Flicking from '@egjs/react-flicking';
import '@egjs/react-flicking/dist/flicking.css';

import BannerService from '../service/BannerService';
import moment from 'moment';
import 'moment/locale/ko';
import { connect } from 'react-redux';

const styles = theme => ({
  container: {
    width: '99vw',
    // width: '100%',
    height: '24vw',
    display: 'flex',
    flexDirection: 'column',
    marginTop: '1vw',
    marginBottom: '1vw',
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      height: '33vw',
    },
  },
  pannel: {
    marginLeft: '1vw',
    // width: '80%',
    width: '80vw',
    height: '24vw',
    overflow: 'hidden',
    marginRight: '1vw',
    borderRadius: '1vw',
    cursor: 'grab',
    [theme.breakpoints.down('md')]: {
      borderRadius: '3vw',
      height: '33vw',
    },
  },
  banner_img: {
    width: '80vw',
    // width: '100%',
    height: '24vw',
    objectFit: 'fill',
    pointerEvents: 'none',
    [theme.breakpoints.down('md')]: {
      borderRadius: '3vw',
      height: '33vw',
    },
  },
  dot_unselected: {
    backgroundColor: '#FFFFFF',
    width: 4,
    height: 4,
    borderRadius: 5,
    borderColor: '#eeeeee',
    borderWidth: 0.5,
    borderStyle: 'solid',
    marginLeft: 6,
  },
  dot_selected: {
    backgroundColor: '#f60337',
    width: 16,
    height: 4,
    borderRadius: 5,
    borderColor: '#f60337',
    borderWidth: 0.5,
    borderStyle: 'solid',
    marginLeft: 6,
  },
});

class BannerSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      matches: window.matchMedia('(max-width:560px)').matches,
      bannerList: [],
      selectedIdx: 0,
    };
    this.getBanner();
    this.refBanner = React.createRef();
  }

  getBanner = () => {
    BannerService.getBanner().then(response => {
      let banners = response.filter(data => {
        return this.bannerFilter(data);
      });
      if (banners && banners.length > 0 && banners[0].items && banners[0].items.length > 0) {
        banners[0].items.sort((a, b) => {
          return Number(a.order) > Number(b.order) ? 1 : -1;
        });

        this.setState({ bannerList: banners[0].items }, () => {});
      } else {
        this.setState({ bannerList: [] });
      }
    });
  };

  bannerFilter = data => {
    try {
      if (!data.startDate || !data.endDate) {
        return false;
      }
      let start = parseInt(data.startDate);
      let end = parseInt(data.endDate);
      let todayMoment = moment();

      if (start <= todayMoment.valueOf() && todayMoment.valueOf() <= end) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  };

  renderBanner = datas => {
    const { classes } = this.props;
    return datas.map((data, i) => (
      <div
        key={i}
        className={classes.pannel}
        onClick={() => {
          if (data.buttonLink) {
            window.open(data.buttonLink, '_blank');
          }
        }}
      >
        <img alt={`${i}번째 배너`} className={classes.banner_img} src={data.file.url} />
      </div>
    ));
  };

  render() {
    const { classes } = this.props;
    const { bannerList } = this.state;

    return (
      <div
        className={classes.container}
        // style={{ width: this.props.menuValue ? '91vw' : '100vw' }}
      >
        {bannerList && bannerList.length > 0 ? (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Flicking
              circular={true}
              moveType={'snap'}
              ref={this.refBanner}
              onChanged={e => {
                this.setState({ selectedIdx: e.index });
              }}
              style={{ width: '100vw' }}
            >
              {bannerList && this.renderBanner(bannerList)}
            </Flicking>
            <div style={{ display: 'flex', flexDirection: 'row', marginTop: -15, zIndex: 1 }}>
              {bannerList.map((data, i) => {
                return this.state.selectedIdx === i ? <div key={`dot_${i}`} className={classes.dot_selected} /> : <div key={`dot_${i}`} className={classes.dot_unselected} />;
              })}
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    menuValue: state.changeMenu.value,
  };
};

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps)(BannerSection));
