import React from 'react';
import { withStyles, Grid, withWidth } from '@material-ui/core';
import ConditionalButton from './ConditionalButton';

import { replaceValue, getCodeList, setCreatedAt, returnUrl } from '../../components/ListComponets';

const styles = theme => ({
  container: {
    color: '#ffffff',
    textAlign: 'center',
    border: 'none',
    padding: '15px 0',
    borderBottom: '1px solid rgb(143, 143, 143, .5)',
    alignItems: 'center',
  },
  vodContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  vodWrapper: {
    width: '80%',
    marginRight: '10px',
    cursor: 'pointer',
  },
  imgBody: {
    display: 'flex',
    width: '100%',
    height: '94px',
    objectFit: 'contain',
    border: '1px solid rgb(143, 143, 143, .5)',
  },
  vodBody: {
    display: 'flex',
    width: '100%',
    height: '94px',
  },
  titleBorder: {
    width: '100%',
    display: 'block',
    margin: '16px',
    wordBreak: 'break-word',
  },
  amountContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  amountWrapper: {
    width: '100%',
    display: 'block',
    margin: '16px',
    marginRight: '0',
    textAlign: 'center',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  accountBox: {
    justifyContent: 'flex-end',
    borderBottom: '1px solid rgba(143, 143, 143, 0.5)',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-between',
    },
  },
});

class DownloadBodyWebComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      vodList: [],
      price: '',
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.videoScroll, true);
    getCodeList('price').then(response => {
      this.setState({ price: `${replaceValue(response, this.props.data.amount)} ₩` });
    });
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.videoScroll, true);
  }

  returnUrl = url => `https://s3.ap-northeast-2.amazonaws.com/file.jebomarket.com/thumbnail/dglee%40atozsoft.kr/${url}`;

  render() {
    const { classes, idx, data, noThumb, onClick } = this.props;

    return (
      <Grid className={classes.container} container>
        <Grid item xs={1}>
          <div className={classes.titleBorder}>{idx + 1}</div>
        </Grid>
        <Grid className={classes.vodContainer} item xs={2}>
          <div className={classes.vodWrapper} onClick={onClick}>
            <img className={classes.imgBody} src={data.vodDetail.vod[0]?.thumb ? returnUrl(data.vodDetail.vod[0]?.thumb) : noThumb} alt='vod_thumb' />
          </div>
        </Grid>
        <Grid item xs={4}>
          <span className={classes.titleBorder} style={{ fontSize: '12px' }}>
            {data.vodDetail.title}
          </span>
        </Grid>
        <Grid item xs={1}>
          <div className={classes.titleBorder} style={{ fontSize: '13px' }} dangerouslySetInnerHTML={{ __html: setCreatedAt(data.created_at).replace(/ /g, '<br />') }} />
        </Grid>
        <Grid className={classes.amountContainer} item xs={2}>
          <span className={classes.amountWrapper}>{this.state.price}</span>
        </Grid>
        <Grid className={classes.buttonContainer} item xs={2}>
          <div style={{ width: '80%', marginRight: '10px' }}>
            <ConditionalButton code={`Download${data.status}`} data={data} />
          </div>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(withWidth()(DownloadBodyWebComponent));
