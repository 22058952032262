import React from 'react';
import { Button, Checkbox, FormControlLabel, Grid, IconButton, InputAdornment, withStyles, TextField } from '@material-ui/core';
import axios from 'axios';
import axiosInstance from '../constants/AxiosInstance';
import Amplify, { Auth } from 'aws-amplify';
import awsconfig from '../aws-exports';
import { API_URL, phoneNumber } from '../constants';

import Alert from '../components/Alert';

Amplify.configure(awsconfig);

const styles = theme => ({
  bg: {
    backgroundImage: 'url("/images/sample/bg_sample.png")',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    width: '100%',
    height: '100%',
    overflowY: 'auto',
  },

  loginInput: {
    backgroundColor: '#0c0e10',
    borderRadius: '5px',
    '& input': {
      color: '#fefefe',
      padding: '0 1vw ',
      height: '5vh',
      backgroundColor: '#0c0e10',
      [theme.breakpoints.down('md')]: {
        padding: '0 4vw ',
        height: '50px',
        fontSize: '16px',
        transform: 'scale(0.9)',
      },
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'transparent',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'transparent',
      },
    },
    '& .MuiFormHelperText-root': {
      backgroundColor: 'rgb(24, 27, 31)',
      color: 'rgb(24, 27, 31)',
      margin: 0,
    },
    '& .MuiFormHelperText-root.Mui-error': {
      color: '#e61737',
    },
  },

  sendAuthNumBtn: {
    height: '5vh',
    width: '95%',
    border: '1px solid #ffffff',
    color: '#fefefe',
    [theme.breakpoints.down('md')]: {
      height: '50px',
      wordBreak: 'keep-all',
    },
  },

  confirmBtn: {
    width: '95%',
    border: '1px solid #585858',
    color: '#fefefe',
    backgroundColor: '#585858',
    height: '5vh',
    [theme.breakpoints.down('md')]: {
      height: '50px',
      wordBreak: 'keep-all',
    },
  },

  snsButton: {
    backgroundColor: '#e61737',
    color: '#ffffff',
    fontWeight: 'bold',
    height: '5vh',
    borderRadius: '5px',
    '&:hover': {
      backgroundColor: '#e61737',
      color: '#ffffff',
    },
    [theme.breakpoints.down('md')]: {
      height: '50px',
      borderRadius: '5px',
    },
  },

  submitDisabled: {
    color: '#ffffff !important',
    borderColor: '#ffffff !important',
    border: 'solid 1px',
    backgroundColor: '#ffffff !important',
  },

  agreeBox: {
    // color: #fefefe
    height: '5vh',
    padding: '0 1vw',
    borderRadius: '5px',
    backgroundColor: 'transparent',
  },

  Checkbox: {
    root: {
      '& colorSecondary': {
        color: 'red',
      },
    },
  },
  orLine: {
    width: '100%',
    textAlign: 'center',
    '& > span': {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
    },
    '&  > span:after': {
      content: '""',
      flexGrow: '1',
      background: '#2e3339',
      height: '2px',
      fontSize: '0px',
      lineHeight: '0px',
      margin: '0px 16px',
      display: 'inline-block',
    },
    '&  > span:before': {
      content: '""',
      flexGrow: '1',
      background: '#2e3339',
      height: '2px',
      fontSize: '0px',
      lineHeight: '0px',
      margin: '0px 16px',
      display: 'inline-block',
    },
  },

  // 등록 동의 문구
  ctrlLabel: {
    color: '#fefefe',
    '& .MuiFormControlLabel-label': {
      fontSize: '1em',
      wordBreak: 'keep-all',
    },
    '& .MuiCheckbox-root': {
      color: '#fefefe',
    },
    [theme.breakpoints.down('sm')]: {
      // alignItems: 'start',
      alignItems: 'center',
      '& .MuiFormControlLabel-label': {
        fontSize: '0.9em',
        wordBreak: 'keep-all',
      },
    },
  },
  naverBtn: {
    '& div': {
      width: '100%',
    },
  },
});

class HpValidate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      matches: window.matchMedia('(max-width:991px)').matches, //md:992px

      tel: '',
      authNum: '',
      authCheck: false,
    };
  }

  componentDidMount() {
    if (this.props.open) {
      Alert('알림!', '핸드폰번호를 인증하여야 이용 가능합니다.');
    }
  }

  handleNumChange = (type, e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === '' || re.test(e.target.value)) this.setState({ [type]: e.target.value });
  };

  phoneProve = () => {
    const phoneNum = this.state.tel;

    axiosInstance.get(`${API_URL}/user/sms?hp=${phoneNum}`, { headers: { Pragma: 'no-cache' } }).then(response => {
      Alert('', '인증번호가 발송되었습니다.');
      this.setState({
        authCheck: true,
      });
    });
  };

  phoneProveCheck = () => {
    const phone = this.state.tel;
    const authNum = this.state.authNum;

    if (!phone || !authNum) return false;

    axiosInstance.get(`${API_URL}/user/verify?key=${phone}&code=${authNum}`, { headers: { Pragma: 'no-cache' } }).then(response => {
      /* const data = response.data.value.Item; */
      if (response.data.value.verify === true) {
        this.setState({ isAuth: true });
        Alert('', '인증이 완료되었습니다.');
      } else {
        Alert('', ' 인증번호 불일치 <br /><br /> 인증번호가 올바르지 않습니다.');
      }
    });
  };

  handleSignup = () => {
    let param = {
      id: this.props.userData.id,
      phone_number: phoneNumber(this.state.tel),
    };

    axiosInstance.put(`${API_URL}/user/`, param, { headers: { Pragma: 'no-cache' } }).then(response => {
      if (response.status === 200) {
        Alert('', '인증되었습니다.', '', '', () => {
          document.location.reload();
        });
      }
    });
  };

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <Grid style={{ padding: '24px' }}>
          <Grid container justifyContent='center' style={{ paddingBottom: this.state.matches ? '3vh' : '2vh' }}>
            <Grid container justifyContent='flex-start' style={{ color: '#fff', padding: this.state.matches ? '2vh 0 1vh' : '3vh 0 1vh' }}>
              전화번호<span style={{ color: '#e61737' }}>*</span>
            </Grid>
            <Grid container justifyContent='center' style={{ padding: this.state.matches ? '1vh 0' : '0.5vh 0' }}>
              <Grid item xs={8}>
                <TextField
                  className={classes.loginInput}
                  name='tel'
                  id='tel'
                  placeholder='전화번호를 입력해주세요'
                  helperText='전화번호를 입력해주세요.'
                  value={this.state.tel}
                  fullWidth={true}
                  variant='outlined'
                  onChange={e => this.handleNumChange('tel', e)}
                  error={!this.state.tel && !!this.state.tel}
                />
              </Grid>
              <Grid container justifyContent='flex-end' item xs={4}>
                <Button className={classes.sendAuthNumBtn} onClick={() => this.phoneProve()}>
                  인증하기
                </Button>
              </Grid>
            </Grid>

            <Grid container justifyContent='center' style={{ padding: this.state.matches ? '1vh 0' : '0.5vh 0' }}>
              <Grid item xs={8}>
                <TextField
                  id='auth_number'
                  name='auth_number'
                  placeholder='인증번호를 입력해주세요.'
                  value={this.state.authNum}
                  className={classes.loginInput}
                  fullWidth={true}
                  variant='outlined'
                  onChange={e => this.handleNumChange('authNum', e)}
                />
              </Grid>

              <Grid container justifyContent='flex-end' item xs={4} className={classes.button_padding}>
                <Button className={classes.confirmBtn} onClick={() => this.phoneProveCheck()}>
                  인증번호 확인
                </Button>
              </Grid>
            </Grid>
            {this.state.isAuth === true ? (
              <Grid container justifyContent='flex-start' style={{ color: 'green', fontSize: 'small', padding: this.state.matches ? '1vh 0' : '0.5vh 0' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;인증되었습니다.
              </Grid>
            ) : (
              <Grid container justifyContent='flex-start' style={{ color: '#fefefe', padding: this.state.matches ? '1vh 0' : '0.5vh 0' }}>
                인증번호가 오지 않았나요?&nbsp;
                <u style={{ cursor: 'pointer' }} onClick={() => this.phoneProve()}>
                  인증번호 재발송
                </u>
              </Grid>
            )}

            {/* 약관동의 체크 박스 */}

            <Grid container alignItems='center' className={classes.agreeBox}>
              <FormControlLabel
                control={<Checkbox value={this.state.isInfoAgree} className={classes.Checkbox} onChange={e => this.setState({ isInfoAgree: e.target.checked ? 'Y' : 'N' })} color='secondary' />}
                labelPlacement='end'
                label='개인정보 수집에 동의합니다.'
                className={classes.ctrlLabel}
              />
            </Grid>
          </Grid>

          <Grid container justifyContent='center'>
            <Grid container justifyContent='center'>
              <Button fullWidth={true} className={classes.snsButton} onClick={this.handleSignup} disabled={!this.state.isAuth || this.state.isInfoAgree === 'N'}>
                인증하기
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

export default withStyles(styles, { withTheme: true })(HpValidate);
