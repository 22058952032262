import React from 'react';
import { Button, Dialog, Grid, IconButton, InputAdornment, TextField, withStyles, withWidth } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';
import PassworDialog from './PassworDialog';
// import NaverLogin from '@dohyeon/react-naver-login';
// import { API_URL, getAWSCredentials, KAKAKO_KEY, NAVER_CALLBACK, NAVER_KEY } from '../constants';

import awsconfig from '../aws-exports';
import axios from 'axios';
import { Amplify, Auth } from 'aws-amplify';
// import moment from 'moment';
import Join from '../signup/Join';

import NaverLoginBtn from './NaverLoginBtn';
import KaKaoCallback from './KaKaoCallback';

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import CustomCircularProgress from '../components/CustomCircularProgress';

Amplify.configure(awsconfig);

const styles = theme => ({
  bg: {
    backgroundImage: 'url("/images/sample/bg_sample.png")',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',

    width: '100%',
    height: '100%',
    // overflowY: 'auto',
  },

  loginPageBox: {
    padding: '3vh 0',
    [theme.breakpoints.down('md')]: {
      padding: '3vh 2vh',
    },
  },

  loginInput: {
    backgroundColor: '#0c0e10',
    borderRadius: '5px',
    '& input': {
      color: '#fefefe',
      padding: '0 1vw ',
      height: '5vh',
      backgroundColor: '#0c0e10',
      // borderRadius: '5px',
      [theme.breakpoints.down('md')]: {
        padding: '0',
        height: '50px',
        // * ios safari disable zoomin
        fontSize: '19px',
        transform: 'scale(0.75)',
      },
    },
  },

  snsButton: {
    height: '5vh',
    borderRadius: '5px',
    [theme.breakpoints.down('md')]: {
      height: '50px',
      borderRadius: '5px',
    },
  },

  orLine: {
    width: '100%',
    textAlign: 'center',
    '& > span': {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
    },
    '&  > span:after': {
      content: '""',
      flexGrow: '1',
      background: '#2e3339',
      height: '2px',
      fontSize: '0px',
      lineHeight: '0px',
      margin: '0px 16px',
      display: 'inline-block',
    },
    '&  > span:before': {
      content: '""',
      flexGrow: '1',
      background: '#2e3339',
      height: '2px',
      fontSize: '0px',
      lineHeight: '0px',
      margin: '0px 16px',
      display: 'inline-block',
    },
  },
});

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      pwDialog: false, //비밀번호 찾기 창
      matches: window.matchMedia('(max-width:991px)').matches, //md:992px
      current: window.location.href,
      isJoinOpen: false,
      showPassword: false, // 패스워드 보기 버튼
      loding: false,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {}

  openPwDialog = event => {
    this.setState({ pwDialog: true });
  };

  closePwDialog = event => {
    this.setState({ pwDialog: false });
  };

  handleSignIn = event => {
    this.setState({ loding: true });
    Auth.signIn(this.state.email, this.state.password)
      .then(async user => {
        const idToken = user.signInUserSession.idToken;
        localStorage.setItem('jebo_user', user.username);
        localStorage.setItem('jebo_jwt', idToken.getJwtToken());
        localStorage.setItem('jebo_name', user.attributes.name);
        localStorage.setItem('jebo_email', user.attributes.email);

        this.props.onClose();
        document.location.reload();
      })
      .catch(err => {
        // console.log(err);
        this.setState({ loding: false });
        alert(err.message);
      });
  };

  handleChange = (type, e) => this.setState({ [type]: e.target.value });

  goJoin = () => {
    this.props.onClose();
    this.props.handleOpenJoin();
  };

  /*
  사용안함 : 팝업형태 버그로 콜백 별도 처리 : NaverCallback
   */
  // onSuccessNaver(naverRes) {
  //   console.log(naverRes);
  //
  //   const res = {
  //     name: naverRes.name,
  //     email: naverRes.email,
  //     id: naverRes.id,
  //   };
  //   res['poolId'] = awsconfig.aws_user_pools_id;
  //   res['clientId'] = awsconfig.aws_user_pools_web_client_id;
  //
  //   const accessToken = localStorage.getItem('com.naver.nid.access_token');
  //   const expiresIn = moment().add(1, 'days').valueOf();
  //   axios.post(`${API_URL}/sns/naver`, res).then((result) => {
  //     this.getAWSCredentials({ accessToken: accessToken, expiresIn: expiresIn }, 'Naver', {
  //       userId: result.data.value.userId,
  //       email: result.data.value.email,
  //     });
  //   });
  // }

  showPassword = () => this.setState({ showPassword: !this.state.showPassword });

  render() {
    const { classes, open } = this.props;
    return (
      <React.Fragment>
        <Grid container justifyContent='center' style={{ backgroundColor: '#171A1D', overflowY: 'auto', borderRadius: '16px' }}>
          <Grid id='padding_grid' container justifyContent='center' item xs={12} md={9} className={classes.loginPageBox}>
            {/* 타이틀, 메인페이지 back 버튼 */}

            <Grid container>
              <Grid container justifyContent='flex-start' alignItems='center' item xs={10} style={{ color: '#ffffff', fontWeight: 'bold', fontSize: 'large' }}>
                로그인
              </Grid>
              <Grid container justifyContent='flex-end' alignItems='center' item xs={2}>
                <IconButton onClick={() => this.props.onClose()}>
                  <CloseIcon style={{ color: '#ffffff' }} />
                </IconButton>
              </Grid>
            </Grid>

            {/* 이메일입력 */}
            <Grid container justifyContent='center' style={{ paddingBottom: this.state.matches ? '3vh' : '2vh' }}>
              <Grid container justifyContent='flex-start' style={{ color: '#fff', padding: this.state.matches ? '2vh 0 1vh' : '3vh 0 1vh' }}>
                이메일<span style={{ color: '#e61737' }}>*</span>
              </Grid>
              <Grid container justifyContent='center'>
                <TextField
                  id='email'
                  type='email'
                  name='email'
                  value={this.state.email}
                  className={classes.loginInput}
                  fullWidth={true}
                  variant='outlined'
                  onChange={e => this.handleChange('email', e)}
                  onKeyPress={e => e.key === 'Enter' && this.handleSignIn()}
                  placeholder='이메일을 입력해 주세요'
                />
              </Grid>
            </Grid>

            {/* 비밀번호 */}
            <Grid container justifyContent='center' style={{ paddingBottom: this.state.matches ? '3vh' : '2vh' }}>
              <Grid container justifyContent='flex-start' style={{ color: '#fff', padding: this.state.matches ? '0 0 1vh' : '0 0 1vh ' }}>
                비밀번호<span style={{ color: '#e61737' }}>*</span>
              </Grid>
              <Grid container justifyContent='center'>
                <TextField
                  required
                  fullWidth
                  id='password'
                  type={this.state.showPassword ? 'text' : 'password'}
                  name='password'
                  placeholder='비밀번호를 입력해 주세요'
                  onChange={e => {
                    this.handleChange('password', e);
                  }}
                  onKeyPress={e => e.key === 'Enter' && this.handleSignIn()}
                  value={this.state.password}
                  className={classes.loginInput}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton aria-label='toggle password visibility' onClick={this.showPassword} style={{ color: '#ffffff' }}>
                          {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>

            {/* 비밀번호 찾기 */}
            <Grid container justifyContent='center'>
              <Grid container justifyContent='center' style={{ color: '#ffffff', padding: '0 0 10px 0', cursor: 'pointer' }}>
                <u onClick={this.openPwDialog}>비밀번호를 잊으셨나요?</u>
              </Grid>
              <Grid container justifyContent='center'>
                <Button fullWidth={true} className={classes.snsButton} style={{ backgroundColor: '#e61737', color: '#ffffff', fontWeight: 'bold' }} onClick={this.handleSignIn}>
                  로그인
                </Button>
              </Grid>
            </Grid>

            {/* or 공백 */}
            <Grid className={classes.orLine} style={{ color: '#fefefe', padding: this.state.matches ? '3vh 0' : '2vh 0' }}>
              <span>or</span>
            </Grid>

            {/* SNS 로그인 */}
            <Grid container justifyContent='center'>
              {open && (
                <>
                  <NaverLoginBtn title='로그인' />
                  <KaKaoCallback text={'로그인'} />
                </>
              )}
            </Grid>

            {/* 회원가입 문구 */}
            <Grid container justifyContent='center' style={{ color: '#ffffff', padding: this.state.matches ? '2vh 0' : '2vh 0 0' }}>
              <u style={{ cursor: 'pointer' }} onClick={() => this.goJoin()}>
                아직 회원이 아니신가요?
              </u>
            </Grid>
          </Grid>
          {/* </Grid> */}
        </Grid>

        <Dialog fullWidth maxWidth={'sm'} open={this.state.isJoinOpen} onClose={() => this.setState({ isJoinOpen: false })}>
          <Join onClose={() => this.setState({ isJoinOpen: false })} />
        </Dialog>

        <PassworDialog open={this.state.pwDialog} onClose={this.closePwDialog} />
        {this.state.loding && <CustomCircularProgress />}
        {/* </div> */}
      </React.Fragment>
    );
  }
}

export default withRouter(withStyles(styles)(withWidth()(LoginPage)));
