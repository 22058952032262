import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import axiosInstance from '../constants/AxiosInstance';
import { API_URL } from '../constants';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import { InputAdornment, TextField, Grid, withStyles, FormControl } from '@material-ui/core';
import { replaceValue } from './ListComponets';

const styles = (theme) => ({
  dropMain: {},

  dropdown: {
    position: 'absolute',
    top: 50,
    right: 0,
    left: 0,
    border: '0.5px solid #555555',
    padding: theme.spacing(4),
    zIndex: 1400,
    backgroundColor: '#1f2125',
  },
  formControl: {
    width: '100%',
    margin: '6px',
    '& div': {
      // border: '1px solid #2a2d31',
      color: '#fff',
      fontFamily: 'Noto Sans',
      fontSize: '14px',
    },
    '& svg': {
      color: '#fff',
    },
  },
  labelTxt: {
    color: '#aaa',
    objectFit: 'contain',
    fontFamily: 'Noto Sans',
    fontSize: '18px',
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: 'normal',
    textAlign: 'left',
  },
  selectBox: {
    position: 'absolute',
    width: '100%',
    transform: 'translate(0px, 45px)',
    zIndex: '1',
  },

  selectEmpty: {
    // marginTop: theme.spacing(3),
    backgroundColor: '#171A1D',
    listStyle: 'none',
    padding: '0',
    border: '1px solid #e61737',
    '& li': {
      padding: '0',
      borderBottom: '0.5px solid #555555',
      fontSize: '0.875em',
      color: '#ffffff',
      lineHeight: '40px',
      cursor: 'pointer',
    },
    '& li:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.1)',
    },
    '& li> a': {
      padding: '0 20px',
      [theme.breakpoints.down('md' || 'xm')]: {
        padding: '0 10px',
      },
    },
  },
  dateControl: {
    backgroundColor: '#171A1D',
    marginTop: '5px',
    position: 'relative',
    '& .MuiFormLabel-root': {
      color: '#ffffff',
    },
    '& .MuiInputBase-input': {
      textAlign: 'left',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  },
});

class FilterList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      equipmentList: [],
      topicList: [],
      equipmentOpen: false,
      topicOpen: false,
    };
    this.topicRef = React.createRef();
    this.equipmentRef = React.createRef();
  }

  handleInputChange = (type, e) => {
    let equData = null;
    let topicData = null;

    if (type === 'equipment') equData = replaceValue(this.state.equipmentList, e) === undefined ? '전체' : replaceValue(this.state.equipmentList, e);
    if (type === 'topic') topicData = replaceValue(this.state.topicList, e) === undefined ? '전체' : replaceValue(this.state.topicList, e);
    this.props.onChange(type, e, equData, topicData);
  };

  componentDidMount() {
    this.getSelectOptions();
    window.addEventListener('click', this.currentTargetClick);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.currentTargetClick);
  }

  getSelectOptions = () => {
    axiosInstance.get(`${API_URL}/code/`).then((response) => {
      // FILTERING TWO GROUPS : { equipment, topic }
      const equipmentList = response.data.value.filter((x) => x.group === 'equipment');
      const topicList = response.data.value.filter((x) => x.group === 'topic');

      this.setState({ equipmentList: equipmentList, topicList: topicList });
    });
  };

  currentTargetClick = ({ target }) => {
    if (!this.topicRef.current.contains(target)) {
      this.setState({ topicOpen: false });
    }
    if (!this.equipmentRef.current.contains(target)) {
      this.setState({ equipmentOpen: false });
    }
  };

  clickDateInput = (e) => {
    const name = e.target.name;

    if (name === 'equipment') {
      let boolean = !this.state.equipmentOpen;
      this.setState({ equipmentOpen: boolean, topicOpen: false });
    } else {
      let boolean = !this.state.topicOpen;
      this.setState({ topicOpen: boolean, equipmentOpen: false });
    }
  };

  render() {
    const { classes, data } = this.props;

    return (
      <div className={classes.dropMain}>
        <div className={classes.dropdown}>
          <Grid container>
            <Grid container justifyContent={'space-evenly'} style={{ borderBottom: '1px solid rgb(143, 143, 143, .5)', padding: '15px 0', borderRadius: '0' }}>
              <Grid className={classes.equipmentSelectWrapper} item xs={6} style={{ padding: '5px' }}>
                <FormControl className={classes.formControl}>
                  <TextField
                    className={classes.dateControl}
                    label='촬영 기기 선택'
                    name='equipment'
                    id='equipment'
                    type='button'
                    variant='outlined'
                    focused
                    ref={this.equipmentRef}
                    onClick={(e) => this.clickDateInput(e)}
                    value={data.renderEquipment}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end' className={classes.iconControl}>
                          {this.state.equipmentOpen ? <KeyboardArrowDownIcon style={{ color: '#e61737' }} /> : <KeyboardArrowUpIcon />}
                        </InputAdornment>
                      ),
                    }}
                  />
                  {this.state.equipmentOpen && (
                    <div className={classes.selectBox}>
                      <ul className={classes.selectEmpty} name='equipment' id='equipment'>
                        <li onClick={(e) => this.handleInputChange('equipment', '')}>
                          {/* eslint-disable-next-line */}
                          <a>전체</a>
                        </li>
                        {this.state.equipmentList &&
                          this.state.equipmentList.map((x, idx) => (
                            <li key={`${x.value}_idx`} onClick={(e) => this.handleInputChange('equipment', x.id)}>
                              {/* eslint-disable-next-line */}
                              <a>{x.value}</a>
                            </li>
                          ))}
                      </ul>
                    </div>
                  )}
                </FormControl>
              </Grid>
              <Grid className={classes.topicSelectWrapper} item xs={6} style={{ padding: '5px' }}>
                <FormControl className={classes.formControl}>
                  <TextField
                    className={classes.dateControl}
                    label='주제 선택'
                    name='topic'
                    id='topic'
                    type='button'
                    variant='outlined'
                    ref={this.topicRef}
                    onClick={(e) => this.clickDateInput(e)}
                    value={data.renderTopic}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end' className={classes.iconControl}>
                          {this.state.topicOpen ? <KeyboardArrowDownIcon style={{ color: '#e61737' }} /> : <KeyboardArrowUpIcon />}
                        </InputAdornment>
                      ),
                    }}
                  />
                  {this.state.topicOpen && (
                    <div className={classes.selectBox}>
                      <ul className={classes.selectEmpty} name='topic' id='topic'>
                        <li onClick={(e) => this.handleInputChange('topic', '')}>
                          {/* eslint-disable-next-line */}
                          <a>전체</a>
                        </li>
                        {this.state.topicList &&
                          this.state.topicList.map((x) => (
                            <li key={`${x.value}_idx`} onClick={(e) => this.handleInputChange('topic', x.id)}>
                              {/* eslint-disable-next-line */}
                              <a>{x.value}</a>
                            </li>
                          ))}
                      </ul>
                    </div>
                  )}
                </FormControl>
              </Grid>
            </Grid>
            {/* <Grid xs={12}>
              <p>날짜 선택</p>
              <Slider
                defaultValue={3}
                // getAriaValueText={console.log}
                aria-labelledby="discrete-slider"
                valueLabelDisplay="auto"
                marks
                step={10}
                min={10}
                max={60}
              />
            </Grid> */}
          </Grid>
        </div>
      </div>
    );
  }
}

export default withRouter(withStyles(styles, { withTheme: true })(FilterList));
