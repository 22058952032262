import React from 'react';
import { withStyles, Grid, withWidth } from '@material-ui/core';
import { returnUrl, setCreatedAt } from '../../components/ListComponets';
import ConditionalButton from './ConditionalButton';
import ReportButton from '../../components/ReportButton';
import { JEBOMARKET_IMG } from '../../constants';

const styles = theme => ({
  container: {
    color: '#ffffff',
    textAlign: 'center',
    border: 'none',
    padding: '15px 0',
    borderBottom: '1px solid rgb(143, 143, 143, .5)',
    alignItems: 'center',
  },
  titleContent: {
    width: '100%',
    textAlign: 'center',
    border: 'none',
    padding: '0',
    paddingBottom: '0 !important',
    margin: '0',
  },
  titleBorder: {
    width: '100%',
    display: 'block',
    margin: '16px',
    wordBreak: 'break-word',
  },
  imgWrapper: {
    width: '80%',
    marginRight: '10px',
    cursor: 'pointer',
  },
  imgContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  imgBody: {
    display: 'flex',
    width: '100%',
    height: '94px',
    objectFit: 'contain',
    border: '1px solid rgb(143, 143, 143, .5)',
  },
  stateContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  stateWrapper: {
    width: '80%',
    marginRight: '10px',
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  btnWrapper: {
    width: '80%',
    marginRight: '10px',
  },
});

class UploadBodyWebComponent extends React.Component {
  render() {
    const { classes, idx, data, noThumb, onClick } = this.props;

    return (
      <Grid className={classes.container} container>
        <Grid item xs={1}>
          <div className={classes.titleBorder}>{idx + 1}</div>
        </Grid>
        <Grid className={classes.imgContainer} item xs={2}>
          <div className={classes.imgWrapper} onClick={onClick}>
            <img className={classes.imgBody} src={data.vod[0]?.thumb ? returnUrl(data.vod[0]?.thumb) : noThumb} alt={'jebo_market'} />
          </div>
        </Grid>
        <Grid item xs={3}>
          <span className={classes.titleBorder} style={{ fontSize: '12px' }}>
            {data.title}
          </span>
        </Grid>
        <Grid item xs={2}>
          <div className={classes.titleBorder} style={{ fontSize: '13px' }} dangerouslySetInnerHTML={{ __html: setCreatedAt(data.created_at).replace(/ /g, '<br />') }} />
        </Grid>
        <Grid className={classes.stateContainer} item xs={2}>
          <div className={classes.stateWrapper}>
            <ConditionalButton code={data.vod_status} />
          </div>
        </Grid>

        <Grid className={classes.btnContainer} item xs={2}>
          <div className={classes.btnWrapper}>
            {/* <ReportBtn data={data} /> */}
            <ReportButton data={data} />
          </div>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(withWidth()(UploadBodyWebComponent));
