import React from 'react';
import { Box, CircularProgress, withStyles, withWidth } from '@material-ui/core';
const styles = (theme) => ({
  circularProgress: {
    position: 'fixed',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'block',
    textAlign: 'center',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0.1,0.1,0.1, 0.5)',
    zIndex: '1500',
  },
  progressArea: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

class CustomCircularProgress extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.circularProgress}>
        <Box className={classes.progressArea}>
          <CircularProgress size={68} />
        </Box>
      </div>
    );
  }
}

export default withStyles(styles)(withWidth()(CustomCircularProgress));
