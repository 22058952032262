import React, { Component } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import SettingsIcon from '@material-ui/icons/Settings';
import { Avatar, Button, Dialog, DialogTitle, DialogContent, Grid, IconButton, withStyles, Box, CircularProgress, Typography } from '@material-ui/core';

// import swal from 'sweetalert';
import PropTypes from 'prop-types';
import ProfileDialog from './ProfileDialog';
import ChangeInfoDialog from './ChangeInfoDialog';
import PassworDialog from '../../signup/PassworDialog';
import axiosInstance from '../../constants/AxiosInstance';
import { API_URL, readFile, fileUpload } from '../../constants';

import { Auth } from 'aws-amplify';

const styles = theme => ({
  loginInput: {
    '& input': {
      backgroundColor: '#0c0e10',
      [theme.breakpoints.down('md')]: {},
    },
  },
  colorBtn: {
    backgroundColor: '#e61737',
    border: '2px solid #e61737',
    borderRadius: '5px',
    color: '#ffffff',
    height: '5.5vh',
    fontWeight: 'bold',
    margin: theme.spacing(1, 0),
    [theme.breakpoints.down('md')]: {
      border: '1px solid #e61737',
      borderRadius: '5px',
      height: '50px',
      margin: theme.spacing(1, 0),
    },
    '&:hover': {
      backgroundColor: '#e61737',
      color: '#ffffff',
    },
  },

  changeBtn: {
    color: '#ffffff',
    border: '0.5px solid #ffffff',
    width: '100%',
    height: '4vh',
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1, 0),
      fontSize: '11px',
    },
  },
  dialogBorder: {
    '& .MuiPaper-rounded': {
      borderRadius: '0px',
    },
  },
  profileWrapper: {
    '& div:hover': {
      border: '3px solid #e61737',
    },
  },
  profileImg: {
    width: '10vh',
    height: '10vh',
    '& div:hover': {
      border: '1px solid #fff',
    },
  },
  circularProgress: {
    position: 'fixed',
    left: '50%',
    top: '40%',
    transform: 'translate(-50%, -50%)',
  },
  logoutBtn: {
    border: '2px solid #eee',
    borderRadius: '5px',
    color: '#ffffff',
    height: '5.5vh',
    fontWeight: 'bold',
    margin: theme.spacing(1, 0),
    [theme.breakpoints.down('md')]: {
      border: '1px solid #eee',
      borderRadius: '5px',
      height: '50px',
      margin: theme.spacing(1, 0),
    },
  },
});

function CircularProgressWithLabel(props) {
  return (
    <Box position='relative' display='inline-flex'>
      <CircularProgress size={100} variant='determinate' {...props} />
      <Box top={0} left={0} bottom={0} right={0} position='absolute' display='flex' alignItems='center' justifyContent='center'>
        <Typography variant='caption' component='div' style={{ color: 'white', fontSize: '25px', fontWeight: 'bold' }}>{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

class MypageDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      changeInfo: false, //개인정보 변경 Dialog
      pwDialog: false, // 비밀번호변경 Dialog
      profileDialog: false,
      matches: window.matchMedia('(max-width:991px)').matches,
      myInfo: '',
      uploadProgress: 0,
    };
  }

  handleChange = (event, newValue) => {
    this.setState({ [event]: newValue });
  };
  openChangeInfo = () => {
    this.setState({ changeInfo: true });
  };
  cloeseChangeInfo = () => {
    this.setState({ changeInfo: false });
  };

  openPwDialog = () => {
    this.setState({ pwDialog: true });
  };
  closePwDialog = () => {
    this.setState({ pwDialog: false });
  };

  profileDialog = () => this.setState({ profileDialog: !this.state.profileDialog });

  onChangeFile = async e => {
    let temp = e.target.files[0];

    await readFile(temp, -1)
      .then(file => {
        temp['path'] = file.target.result;

        fileUpload([temp], this.props.userData.id, 'picture', 'picture', `${API_URL}/user/`, this.progressCallback)
          .then(res => {
            this.props.reRender();
          })
          .catch(console.log);
      })

      .catch(e => console.log('read file err', e));
  };

  onDeleteFile = () => {
    axiosInstance
      .put(`${API_URL}/user/`, { id: this.props.userData.id, picture: null, profile: null })
      .then(res => {
        this.props.reRender();
      })
      .catch(err => {
        console.log('profile edit fail : ', err);
      });
  };

  progressCallback = per => {
    this.setState({ uploadProgress: per });

    if (per === 100) setTimeout(() => this.setState({ uploadProgress: 0 }), 500);
  };

  logOut = () => {
    localStorage.clear();
    sessionStorage.clear();
    Auth.signOut().then(() => {
      this.props.goHome();
      this.props.handleCloseMypage();
      document.location.reload();
    });
  };

  render() {
    const { open, onClose, classes, handleCloseMypage, userData, reRender } = this.props;

    return (
      <>
        {userData && (
          <Dialog className={classes.dialogBorder} fullWidth={true} maxWidth={'xs'} open={open} onClose={onClose} style={{ backgroundColor: 'transparent' }}>
            <DialogTitle style={{ backgroundColor: '#171A1D' }}>
              <Grid container justifyContent='center'>
                <Grid container item xs={11}>
                  <Grid container item xs={10} alignContent='center'>
                    <b style={{ color: '#ffffff' }}>마이페이지</b>
                  </Grid>

                  <Grid container item xs={2} justifyContent='flex-end'>
                    <IconButton onClick={onClose} style={{ padding: 0 }}>
                      <CloseIcon style={{ color: '#ffffff' }} />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </DialogTitle>

            <DialogContent style={{ backgroundColor: '#171A1D' }}>
              <Grid id='positioning_grid' container justifyContent='center'>
                <Grid container item xs={10} style={{ padding: this.props.theme.spacing(4, 0) }}>
                  <Grid className={classes.profileWrapper} container justifyContent='center' alignItems='center' item xs={5}>
                    <Avatar className={classes.profileImg} src={userData?.picture ? userData?.picture.path : undefined} onClick={this.profileDialog} />
                  </Grid>
                  <Grid container item xs={7}>
                    <Grid container style={{ padding: this.props.theme.spacing(1, 2) }}>
                      <span style={{ color: '#fefefe' }}>{userData?.email}</span>
                    </Grid>
                    <Grid container style={{ padding: this.props.theme.spacing(0, 2) }}>
                      <Button onClick={this.openChangeInfo} className={classes.changeBtn}>
                        <SettingsIcon />
                        &nbsp;개인정보 변경
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container justifyContent='center' item xs={11} md={10}>
                  <Button fullWidth={true} className={classes.colorBtn} onClick={this.openPwDialog}>
                    비밀번호 변경하기
                  </Button>

                  <Button className={classes.logoutBtn} fullWidth onClick={this.logOut}>
                    로그아웃
                  </Button>
                </Grid>
              </Grid>
            </DialogContent>

            {!!this.state.uploadProgress && (
              <div className={classes.circularProgress}>
                <CircularProgressWithLabel value={this.state.uploadProgress} />
              </div>
            )}

            <React.Fragment>
              <ChangeInfoDialog open={this.state.changeInfo} onClose={this.cloeseChangeInfo} userData={userData} handleCloseMypage={handleCloseMypage} reRender={reRender} />
              <ProfileDialog open={this.state.profileDialog} onClose={this.profileDialog} userData={userData} tx={{ onChangeFile: this.onChangeFile, onDeleteFile: this.onDeleteFile }} />
              <PassworDialog open={this.state.pwDialog} onClose={this.closePwDialog} handleCloseMypage={this.props.handleCloseMypage} pwChange={true} />
            </React.Fragment>
          </Dialog>
        )}
      </>
    );
  }
}

export default withStyles(styles, { withTheme: true })(MypageDialog);
