import React from 'react';
import { withStyles, Grid, withWidth, Button } from '@material-ui/core';
import AccountDialog from '../../dialog/AccountDialog';
import axiosInstance from '../../constants/AxiosInstance';
import { API_URL } from '../../constants';

const styles = (theme) => ({
  accountBox: {
    justifyContent: 'flex-end',
    borderBottom: '1px solid rgba(143, 143, 143, 0.5)',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-between',
    },
  },
  accountPoint: {
    width: '100%',
    height: 'auto',
    margin: '16px 0',
    color: '#fff',
    fontSize: '1.3rem',
    textAlign: 'right',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left',
    },
  },
  accountBtn: {
    backgroundColor: '#e61737',
    border: '1px solid #e61737',
    color: '#fff',
    width: '100%',
    fontWeight: 'bold',
    fontSize: '15px',
    height: '50px',
    margin: theme.spacing(1, 0),
    padding: theme.spacing(2, 0),
  },
});

class SaleFooterComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dialog: false,
      accountPoint: 0,
      pointCheck: false,
    };

    this.openAccountDialog = this.openAccountDialog.bind(this);
    this.closeAccountDialog = this.closeAccountDialog.bind(this);
  }

  componentDidMount() {
    this.renderAccount();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.data !== prevProps.data) {
      this.componentDidMount();
    }
  }

  openAccountDialog = async () => {
    const { data } = this.props;
    let _pointCheck = false;

    for await (let _data of data) {
      if (_data.account === 'withhold') {
        let param = {
          id: _data.id,
          account: 'pay-request',
        };

        let res = await axiosInstance.put(`${API_URL}/order/`, param, { headers: { Pragma: 'no-cache' } });
        console.log(res);

        _pointCheck = true;
      }
    }

    this.setState({ pointCheck: _pointCheck }, () => {
      this.setState({ dialog: true });
    });
  };

  closeAccountDialog = () => {
    this.setState({ dialog: false, pointCheck: false });
  };

  renderAccount = () => {
    const { data } = this.props;

    if (data.length > 0) {
      let payMoney = null;
      for (let _data of data) {
        if (_data.account === 'withhold') {
          payMoney += parseInt(_data.amount);
        }
      }

      // 사파리에서 지원이 안됨
      const _payMoney = Number(payMoney).toLocaleString();
      this.setState({ accountPoint: _payMoney });
    }
  };

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <Grid container item xs={12} className={classes.accountBox}>
          <Grid item xs={5} md={2}>
            <div className={classes.accountPoint}>
              <span>정산가능 금액</span>
            </div>
          </Grid>
          <Grid item xs={5} md={2}>
            <div style={{ width: '100%', height: 'auto', margin: '16px 0', color: 'red', fontSize: '1.3rem', textAlign: 'center' }}>
              <span>{this.state.accountPoint} ₩</span>
            </div>
          </Grid>
        </Grid>
        <Grid container item xs={12} style={{ justifyContent: 'flex-end', marginTop: '15px' }}>
          <Grid container item xs={12} md={2}>
            <Button fullWidth={true} className={classes.accountBtn} onClick={this.openAccountDialog}>
              정산 신청하기
            </Button>
          </Grid>
        </Grid>

        <AccountDialog
          pointCheck={this.state.pointCheck}
          open={this.state.dialog}
          onClose={this.closeAccountDialog}
          disableEsacpeKeyDown={true}
          reRender={this.props.reRender}
        />
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(withWidth()(SaleFooterComponent));
