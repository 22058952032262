import React from 'react';
import NaverLogin from '@dohyeon/react-naver-login';
import { Button, Grid, withStyles, withWidth } from '@material-ui/core';
import { withRouter } from 'react-router-dom';

import { NAVER_CALLBACK, NAVER_KEY } from '../constants';

const styles = theme => ({
  naverBtn: {
    '& div': {
      width: '100%',
    },
  },

  snsButton: {
    height: '5vh',
    borderRadius: '5px',
    [theme.breakpoints.down('md')]: {
      height: '50px',
      borderRadius: '5px',
    },
  },
});

const NaverLoginBtn = props => {
  const { classes, title } = props;

  return (
    <Grid container justifyContent='center' className={classes.naverBtn}>
      <NaverLogin clientId={NAVER_KEY} callbackUrl={NAVER_CALLBACK} isPopup={false}>
        <Button type='button' fullWidth={true} className={classes.snsButton} style={{ backgroundColor: '#fff', color: '#000', fontWeight: 'bold' }}>
          <Grid container justifyContent='center' alignItems='center' item xs={1}>
            <img src='/images/common/ic_naver.png' alt={'img'} />
          </Grid>
          <Grid container justifyContent='center' item xs={11}>
            네이버로 {title}
          </Grid>
        </Button>
      </NaverLogin>
    </Grid>
  );
};

export default withRouter(withStyles(styles)(withWidth()(NaverLoginBtn)));
