import React from 'react';
import { Button, Checkbox, FormControlLabel, Grid, IconButton, InputAdornment, withStyles, TextField } from '@material-ui/core';
import axios from 'axios';
import axiosInstance from '../constants/AxiosInstance';
import CloseIcon from '@material-ui/icons/Close';
import PassworDialog from './PassworDialog';
import Amplify, { Auth } from 'aws-amplify';
import awsconfig from '../aws-exports';
import { API_URL, phoneNumber } from '../constants';
import ConfirmDialog from './ConfirmDialog';

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { BiCheckCircle } from 'react-icons/bi';

import NaverLoginBtn from './NaverLoginBtn';
import Alert from '../components/Alert';

import KaKaoCallback from './KaKaoCallback';

Amplify.configure(awsconfig);

const styles = theme => ({
  bg: {
    backgroundImage: 'url("/images/sample/bg_sample.png")',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    width: '100%',
    height: '100%',
    overflowY: 'auto',
  },

  loginInput: {
    backgroundColor: '#0c0e10',
    borderRadius: '5px',
    '& input': {
      color: '#fefefe',
      padding: '0 1vw ',
      height: '5vh',
      backgroundColor: '#0c0e10',
      [theme.breakpoints.down('md')]: {
        padding: '0 4vw ',
        height: '50px',
        fontSize: '16px',
        transform: 'scale(0.9)',
      },
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'transparent',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'transparent',
      },
    },
    '& .MuiFormHelperText-root': {
      backgroundColor: 'rgb(24, 27, 31)',
      color: 'rgb(24, 27, 31)',
      margin: 0,
    },
    '& .MuiFormHelperText-root.Mui-error': {
      color: '#e61737',
    },
  },

  sendAuthNumBtn: {
    height: '5vh',
    width: '95%',
    border: '1px solid #ffffff',
    color: '#fefefe',
    [theme.breakpoints.down('md')]: {
      height: '50px',
      wordBreak: 'keep-all',
    },
  },

  confirmBtn: {
    width: '95%',
    border: '1px solid #585858',
    color: '#fefefe',
    backgroundColor: '#585858',
    height: '5vh',
    [theme.breakpoints.down('md')]: {
      height: '50px',
      wordBreak: 'keep-all',
    },
  },

  snsButton: {
    backgroundColor: '#e61737',
    color: '#ffffff',
    fontWeight: 'bold',
    height: '5vh',
    borderRadius: '5px',
    '&:hover': {
      backgroundColor: '#e61737',
      color: '#ffffff',
    },
    [theme.breakpoints.down('md')]: {
      height: '50px',
      borderRadius: '5px',
    },
  },

  submitDisabled: {
    color: '#ffffff !important',
    borderColor: '#ffffff !important',
    border: 'solid 1px',
    backgroundColor: '#ffffff !important',
  },

  agreeBox: {
    // color: #fefefe
    height: '5vh',
    padding: '0 1vw',
    borderRadius: '5px',
    backgroundColor: 'transparent',
  },

  Checkbox: {
    root: {
      '& colorSecondary': {
        color: 'red',
      },
    },
  },
  orLine: {
    width: '100%',
    textAlign: 'center',
    '& > span': {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
    },
    '&  > span:after': {
      content: '""',
      flexGrow: '1',
      background: '#2e3339',
      height: '2px',
      fontSize: '0px',
      lineHeight: '0px',
      margin: '0px 16px',
      display: 'inline-block',
    },
    '&  > span:before': {
      content: '""',
      flexGrow: '1',
      background: '#2e3339',
      height: '2px',
      fontSize: '0px',
      lineHeight: '0px',
      margin: '0px 16px',
      display: 'inline-block',
    },
  },

  // 등록 동의 문구
  ctrlLabel: {
    color: '#fefefe',
    '& .MuiFormControlLabel-label': {
      fontSize: '1em',
      wordBreak: 'keep-all',
    },
    '& .MuiCheckbox-root': {
      color: '#fefefe',
    },
    [theme.breakpoints.down('sm')]: {
      // alignItems: 'start',
      alignItems: 'center',
      '& .MuiFormControlLabel-label': {
        fontSize: '0.9em',
        wordBreak: 'keep-all',
      },
    },
  },
  naverBtn: {
    '& div': {
      width: '100%',
    },
  },
});

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      matches: window.matchMedia('(max-width:991px)').matches, //md:992px
      email: '',
      password: '',
      nickName: '',
      tel: '',
      authNum: '',
      authCheck: false,
      pwDialog: false,
      regState: 0, // 회원가입 순서:  0-이메일, 1-비밀번호, 2-닉네임, 3-전화번호
      isInfoAgree: 'N',
      isAuth: false,
      isEmailType: false,
      confirmDialog: false,
      showPassword: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleNumChange = this.handleNumChange.bind(this);
  }

  closePwDialog = event => {
    this.setState({ pwDialog: false });
  };
  closeConfirmDialog = event => {
    this.setState({ confirmDialog: false });
  };

  handleChange(type, e) {
    e.stopPropagation();
    e.preventDefault();
    if (type === 'tel') this.setState({ tel: phoneNumber(e.target.value) });
    else if (type === 'email') {
      const regExp = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
      this.setState({ [type]: e.target.value, isEmailType: regExp.test(e.target.value) ? true : false });
    } else this.setState({ [type]: e.target.value });
  }

  handleNumChange(type, e) {
    const re = /^[0-9\b]+$/;
    if (e.target.value === '' || re.test(e.target.value)) this.setState({ [type]: e.target.value });
  }

  handleSignupEmail = event => {
    if (this.state.regState === 0 && this.state.email === '') {
      Alert('', '이메일을 입력해주세요.');
      return false;
    } else {
      if (this.state.regState === 0 && this.state.isEmailType === true) {
        this.checkEmail();
      } else {
        Alert('', '잘못된 이메일 형식입니다.');
      }
    }
  };

  handleSignupNickName = event => {
    if (this.state.regState === 2 && this.state.nickName === '') {
      Alert('', '닉네임을 입력해 주십시오.');
    } else {
      this.checkNickName();
    }
  };

  checkEmail = event => {
    if (this.state.email) {
      axios.get(`${API_URL}/user/checkId?id=${this.state.email}`).then(response => {
        const data = response.data.value.exists;
        // console.log('이메일중복여부', data);
        if (data === true) {
          Alert('', '이미 사용중인 이메일입니다.');
        } else {
          this.setState({ regState: this.state.regState + 1 });
          return;
        }
      });
    }
  };

  checkNickName = event => {
    if (this.state.nickName) {
      axiosInstance.get(`${API_URL}/user/checkName?nickname=${this.state.nickName}`, { headers: { Pragma: 'no-cache' } }).then(response => {
        response.data.value.exists ? Alert('', '이미 사용중인 이메일입니다.') : this.setState({ regState: this.state.regState + 1 });
      });
    }
  };

  handleSignup = event => {
    if (this.state.regState < 3) {
      if (this.state.regState === 1 && this.state.password === '') {
        Alert('', '공란을 입력해 주십시오.');
      } else {
        this.setState({ regState: this.state.regState + 1 });
      }
    } else {
      Auth.signUp({
        username: this.state.email,
        password: this.state.password,
        attributes: {
          email: this.state.email,
          name: this.state.nickName,
          'custom:hp': this.state.tel,
        },
      }).then(event => {
        this.setState({ confirmDialog: true });
      });
    }
  };

  phoneProve = () => {
    const phoneNum = this.state.tel;

    axiosInstance.get(`${API_URL}/user/sms?hp=${phoneNum}`, { headers: { Pragma: 'no-cache' } }).then(response => {
      Alert('', '인증번호가 발송되었습니다.');
      this.setState({
        authCheck: true,
      });
    });
  };

  phoneProveCheck = () => {
    const phone = this.state.tel;
    const authNum = this.state.authNum;

    if (!phone || !authNum) return false;

    axiosInstance.get(`${API_URL}/user/verify?key=${phone}&code=${authNum}`, { headers: { Pragma: 'no-cache' } }).then(response => {
      /* const data = response.data.value.Item; */
      if (response.data.value.verify === true) {
        this.setState({ isAuth: true });
        Alert('', '인증이 완료되었습니다.');
      } else {
        Alert('', ' 인증번호 불일치 <br /><br /> 인증번호가 올바르지 않습니다.');
      }
    });
  };

  showPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  render() {
    const { classes, open } = this.props;

    return (
      <React.Fragment>
        <Grid container justifyContent='center' style={{ backgroundColor: '#171A1D', overflowY: 'auto' }}>
          {/* input 영역 */}
          <Grid id='padding_grid' container justifyContent='center' item xs={12} md={9} style={{ padding: this.state.matches ? '3vh 2vh' : '2vh 0' }}>
            {/* 타이틀, 메인페이지 back 버튼 */}
            <Grid container>
              <Grid container justifyContent='flex-start' alignItems='center' item xs={10} style={{ color: '#ffffff', fontWeight: 'bold', fontSize: 'large' }}>
                회원가입
              </Grid>
              <Grid container justifyContent='flex-end' alignItems='center' item xs={2}>
                <IconButton onClick={() => this.props.onClose()}>
                  <CloseIcon style={{ color: '#ffffff' }} />
                </IconButton>
              </Grid>
            </Grid>
            {this.state.regState === 0 && (
              <React.Fragment>
                {/* 이메일입력 */}
                <Grid container justifyContent='center' style={{ paddingBottom: this.state.matches ? '3vh' : '2vh' }}>
                  <Grid container justifyContent='flex-start' style={{ color: '#fff', padding: this.state.matches ? '2vh 0 1vh' : '3vh 0 1vh' }}>
                    이메일<span style={{ color: '#e61737' }}>*</span>
                  </Grid>
                  <Grid container justifyContent='center'>
                    <TextField
                      id='email'
                      type='email'
                      name='email'
                      placeholder='이메일을 입력해주세요.'
                      value={this.state.email}
                      className={classes.loginInput}
                      fullWidth={true}
                      variant='outlined'
                      onChange={e => this.handleChange('email', e)}
                      error={!this.state.isEmailType && !!this.state.email.length}
                      helperText={'공란 또는 올바른 이메일 형식이 아닙니다.'}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end' style={{ backgroundColor: 'transparent' }}>
                            {this.state.isEmailType ? <BiCheckCircle style={{ color: 'green' }} /> : ''}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>

                <Grid container justifyContent='center'>
                  <Grid container justifyContent='center'>
                    <Button className={classes.snsButton} fullWidth={true} onClick={this.handleSignupEmail}>
                      계속
                    </Button>
                  </Grid>
                </Grid>
              </React.Fragment>
            )}

            {this.state.regState === 1 && (
              <React.Fragment>
                {/* 비밀번호 입력 */}
                <Grid container justifyContent='center' style={{ paddingBottom: this.state.matches ? '3vh' : '2vh' }}>
                  <Grid container justifyContent='flex-start' style={{ color: '#fff', padding: this.state.matches ? '2vh 0 1vh' : '3vh 0 1vh' }}>
                    비밀번호<span style={{ color: '#e61737' }}>*</span>
                  </Grid>
                  <Grid container justifyContent='center'>
                    <TextField
                      className={classes.loginInput}
                      name='password'
                      id='password'
                      placeholder='비밀번호를 입력해주세요.'
                      value={this.state.password}
                      fullWidth={true}
                      variant='outlined'
                      onChange={e => this.handleChange('password', e)}
                      error={!this.state.password && !!this.state.password}
                      helperText='비밀번호를 입력해 주세요.'
                      type={this.state.showPassword ? 'text' : 'password'}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>
                            <IconButton aria-label='toggle password visibility' onClick={this.showPassword} style={{ color: '#ffffff' }}>
                              {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    ></TextField>
                  </Grid>
                </Grid>

                <Grid container justifyContent='center'>
                  <Grid container justifyContent='center'>
                    <Button className={classes.snsButton} fullWidth={true} onClick={this.handleSignup}>
                      계속
                    </Button>
                  </Grid>
                </Grid>
              </React.Fragment>
            )}

            {this.state.regState === 2 && (
              <React.Fragment>
                {/* 닉네임 입력 */}
                <Grid container justifyContent='center' style={{ paddingBottom: this.state.matches ? '3vh' : '2vh' }}>
                  <Grid container justifyContent='flex-start' style={{ color: '#fff', padding: this.state.matches ? '2vh 0 1vh' : '3vh 0 1vh' }}>
                    닉네임<span style={{ color: '#e61737' }}>*</span>
                  </Grid>
                  <Grid container justifyContent='center'>
                    <TextField
                      value={this.state.nickName}
                      name='nickname'
                      id='nickname'
                      placeholder='닉네임을 입력해주세요.'
                      className={classes.loginInput}
                      fullWidth={true}
                      variant='outlined'
                      onChange={e => this.handleChange('nickName', e)}
                      error={!this.state.nickName && !!this.state.nickName}
                      helperText='닉네임을 입력해 주세요.'
                    />
                  </Grid>
                </Grid>

                <Grid container justifyContent='center'>
                  <Grid container justifyContent='center'>
                    <Button className={classes.snsButton} fullWidth={true} onClick={this.handleSignupNickName}>
                      계속
                    </Button>
                  </Grid>
                </Grid>
              </React.Fragment>
            )}

            {this.state.regState === 3 && (
              <React.Fragment>
                {/* 전화번호 입력 */}
                <Grid container justifyContent='center' style={{ paddingBottom: this.state.matches ? '3vh' : '2vh' }}>
                  <Grid container justifyContent='flex-start' style={{ color: '#fff', padding: this.state.matches ? '2vh 0 1vh' : '3vh 0 1vh' }}>
                    전화번호<span style={{ color: '#e61737' }}>*</span>
                  </Grid>
                  <Grid container justifyContent='center' style={{ padding: this.state.matches ? '1vh 0' : '0.5vh 0' }}>
                    <Grid item xs={8}>
                      <TextField
                        className={classes.loginInput}
                        name='tel'
                        id='tel'
                        placeholder='전화번호를 입력해주세요'
                        helperText='전화번호를 입력해주세요.'
                        value={this.state.tel}
                        fullWidth={true}
                        variant='outlined'
                        onChange={e => this.handleNumChange('tel', e)}
                        error={!this.state.tel && !!this.state.tel}
                      />
                    </Grid>
                    <Grid container justifyContent='flex-end' item xs={4}>
                      <Button className={classes.sendAuthNumBtn} onClick={() => this.phoneProve()}>
                        인증하기
                      </Button>
                    </Grid>
                  </Grid>

                  <Grid container justifyContent='center' style={{ padding: this.state.matches ? '1vh 0' : '0.5vh 0' }}>
                    <Grid item xs={8}>
                      <TextField
                        id='auth_number'
                        name='auth_number'
                        placeholder='인증번호를 입력해주세요.'
                        value={this.state.authNum}
                        className={classes.loginInput}
                        fullWidth={true}
                        variant='outlined'
                        onChange={e => this.handleNumChange('authNum', e)}
                      />
                    </Grid>

                    <Grid container justifyContent='flex-end' item xs={4} className={classes.button_padding}>
                      <Button className={classes.confirmBtn} onClick={() => this.phoneProveCheck()}>
                        인증번호 확인
                      </Button>
                    </Grid>
                  </Grid>
                  {this.state.isAuth === true ? (
                    <Grid container justifyContent='flex-start' style={{ color: 'green', fontSize: 'small', padding: this.state.matches ? '1vh 0' : '0.5vh 0' }}>
                      &nbsp;&nbsp;&nbsp;&nbsp;인증되었습니다.
                    </Grid>
                  ) : (
                    <Grid container justifyContent='flex-start' style={{ color: '#fefefe', padding: this.state.matches ? '1vh 0' : '0.5vh 0' }}>
                      인증번호가 오지 않았나요?&nbsp;
                      <u style={{ cursor: 'pointer' }} onClick={() => this.phoneProve()}>
                        인증번호 재발송
                      </u>
                    </Grid>
                  )}

                  {/* 약관동의 체크 박스 */}

                  <Grid container alignItems='center' className={classes.agreeBox}>
                    <FormControlLabel
                      control={<Checkbox value={this.state.isInfoAgree} className={classes.Checkbox} onChange={e => this.setState({ isInfoAgree: e.target.checked ? 'Y' : 'N' })} color='secondary' />}
                      labelPlacement='end'
                      label='작가 및 회원 약관에 동의합니다.'
                      className={classes.ctrlLabel}
                    />
                  </Grid>
                </Grid>

                <Grid container justifyContent='center'>
                  <Grid container justifyContent='center'>
                    <Button fullWidth={true} className={classes.snsButton} onClick={this.handleSignup} disabled={!this.state.isAuth || this.state.isInfoAgree === 'N'}>
                      회원 가입하기
                    </Button>
                  </Grid>
                </Grid>
              </React.Fragment>
            )}

            {/* or 공백 */}
            <Grid className={classes.orLine} style={{ color: '#fefefe', padding: this.state.matches ? '3vh 0' : '2vh 0' }}>
              <span>or</span>
            </Grid>

            {/* SNS 로그인 */}
            <Grid container justifyContent='center'>
              {open && (
                <>
                  <NaverLoginBtn title='가입하기' />
                  <KaKaoCallback text='가입하기' />
                </>
              )}
            </Grid>

            {/* 로그인 문구 */}
            <Grid container justifyContent='center' style={{ color: '#ffffff', padding: this.state.matches ? '2vh 0' : '2vh 0 0' }}>
              <u style={{ cursor: 'pointer' }} onClick={() => this.props.handleOpenLogin()}>
                이미 가입하셨나요?
              </u>
            </Grid>
          </Grid>
          {/* </Grid> */}
        </Grid>

        <PassworDialog open={this.state.pwDialog} onClose={this.closePwDialog} disableBackdropClick={true} disableEscapeKeyDown={true} />
        <ConfirmDialog
          open={this.state.confirmDialog}
          disableBackdropClick={true}
          disableEscapeKeyDown={true}
          onClose={() => this.setState({ confirmDialog: false })}
          handleOpenLogin={this.props.handleOpenLogin.bind(this)}
          nickName={this.state.nickName}
        />
        {/* </div> */}
      </React.Fragment>
    );
  }
}

export default withStyles(styles, { withTheme: true })(LoginPage);
