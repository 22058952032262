export const OPEN_MENU = 'OPEN_MENU';
export const CLOSE_MENU = 'CLOSE_MENU';
export const OPEN_LOGIN = 'OPEN_LOGIN';
export const CLOSE_LOGIN = 'CLOSE_LOGIN';

export const openMenu = () => {
  return {
    type: OPEN_MENU,
  };
};

export const closeMenu = () => {
  return {
    type: CLOSE_MENU,
  };
};

export const openLogin = () => {
  return {
    type: OPEN_LOGIN,
  };
};

export const closeLogin = () => {
  return {
    type: CLOSE_LOGIN,
  };
};
