import './Alert.css';

const Alert = async (header, text, cancelButtonText, confirmButtonText, _select) => {
  let select = _select;

  const _alertCreate = async (header, text, cancelButtonText, confirmButtonText) => {
    let button = `<button id="customBtnSelect"><p>확인</p></button>`;
    if (cancelButtonText && confirmButtonText) {
      button = `<button id="customBtnClose"><p>${cancelButtonText}</p></button>
                <button id="customBtnSelect"><p>${confirmButtonText}</p></button>`;
    }

    let ele = document.querySelector('body');
    var _alert = document.createElement('div');
    _alert.className = 'alertBg show-alert';
    _alert.id = 'alertBg';
    _alert.innerHTML = `<div class="custom-alert">
                              <div class="alert-text">
                                  <p>${header}</p>
                                  <p>${text}</p>
                              </div>
                              <div class="alert-footer">
                                 ${button}
                              </div>
                          </div>`;
    ele.appendChild(_alert);
    if (confirmButtonText) document.getElementById('customBtnClose').onclick = () => selectAlert(false);
    document.getElementById('customBtnSelect').onclick = () => selectAlert(true);
  };

  const selectAlert = _res => {
    const child = document.getElementById('alertBg');
    if (select) select(_res);
    child.remove();
  };

  if (!!!document.getElementById('alertBg')) {
    _alertCreate(header, text, cancelButtonText, confirmButtonText);
  }
};

export default Alert;

// Ex)
// Alert('잘 둘러보고 계신가요?', '소도시라이프를 더욱 즐기고 싶으시다면 <br /> 로그인 해주세요!', '아니요', '예', (res) => {
//   console.log(res);
// });

// cancelButtonText : 취소버튼 영역
// confirmButtonText : 확인버튼 영역

// _select 선택한 결과 콜백
