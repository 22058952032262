import React, { Component } from 'react';
import { Dialog, DialogTitle, DialogContent, Grid, IconButton, withStyles, TextField, InputAdornment } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { encrypt } from '../constants';

const styles = theme => ({
  loginInput: {
    '& input': {
      backgroundColor: '#0c0e10',
      [theme.breakpoints.down('md')]: {},
    },
  },
  snsButton: {
    height: '5vh',
    borderRadius: '5px',
    [theme.breakpoints.down('md')]: {
      height: '50px',
      borderRadius: '5px',
    },
  },
  menuItem: {
    color: '#ffffff',
  },
  DialogMain: {
    backgroundColor: '#1f2125',
    padding: '19px',
    [theme.breakpoints.down('md')]: {
      padding: '9px',
    },
  },
  contentBox: {
    padding: '12px',
  },
  isBorder: {
    '& .MuiFormControl-root >::after': {
      content: '""',
      display: 'block',
      height: 14,
      borderRight: '1px solid rgb(143, 143, 143, .5)',
      opacity: '0.3',
    },
  },
  contentTitle: {
    width: '100%',
    // padding: '15px 120px 10px 23px',
    objectFit: 'contain',
    borderRadius: '5px',
    backgroundColor: '#171A1D',
    border: 'none',
    textAlign: 'center',
    '& .MuiInputBase-root': {
      color: '#fefefe',
      fontSize: '13.5px',
      fontWeight: 'bold',
      fontStretch: 'normal',
      fontStyle: 'normal',
      textAlign: 'center',
    },
    '& .MuiInputBase-root > input': {
      textAlign: 'left',
      color: '#cfcfcf',
      padding: '18.5px 14px 0 14px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  },
  contents: {
    [theme.breakpoints.down('md')]: {
      '& >div >div >div >div > input': {
        fontSize: '17px',
        transform: 'scale(0.75)',
        transformOrigin: 'left',
      },
    },
  },
});

const TextFields = withStyles(theme => ({
  root: {
    width: '100%',
    // padding: '15px 120px 10px 23px',
    objectFit: 'contain',
    borderRadius: '5px',
    backgroundColor: '#171A1D',
    border: 'none',
    textAlign: 'center',
    '& .MuiInputBase-root': {
      color: '#fefefe',
      fontSize: '13.5px',
      fontWeight: 'bold',
      fontStretch: 'normal',
      fontStyle: 'normal',
      textAlign: 'center',
    },
    '& .MuiInputBase-root > input': {
      textAlign: 'center',
      fontStyle: 'italic',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },

    [theme.breakpoints.down('md')]: {},
  },
}))(TextField);

class ReportDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      matches: window.matchMedia('(max-width:991px)').matches,
      url: '',
    };

    this.handleSignIn = this.handleSignIn.bind(this);
  }

  handleChange = (event, newValue) => {
    this.setState({ tabValue: newValue });
  };

  componentDidMount = () => {
    const { data } = this.props;

    let id = data.id;
    let username = encrypt(data.userId, 'data-key-1');
    let _url = `https://www.jebomarket.com/vod/Detail?data=${username}&c=${id}`;

    this.setState({ url: _url });
  };

  handleSignIn(event) {}

  goToUrl = () => {
    window.open(`${this.state.url}`, '_blank');
  };

  render() {
    const { open, onClose, classes, data } = this.props;
    return (
      <Dialog fullWidth={true} maxWidth={'sm'} onClose={onClose} open={open}>
        <Grid container item xs={12} className={classes.DialogMain}>
          <DialogTitle style={{ width: '100%', borderBottom: '1px solid rgb(143, 143, 143, .5)', padding: '0 0 15px 0' }}>
            <Grid container item xs={12} style={{ width: '100%', textAlign: 'center', display: 'block' }}>
              <div style={{ textAlign: 'right', height: '13px' }}>
                <IconButton onClick={onClose}>
                  <CloseIcon style={{ color: '#ffffff' }} />
                </IconButton>
              </div>
              <b style={{ color: '#ffffff' }}>사건 사고 제보합니다!</b>
            </Grid>
          </DialogTitle>

          <DialogContent style={{ padding: '0 0 5px 0' }}>
            <Grid id='positioning_grid' container justifyContent='center' className={classes.contents}>
              <Grid container justifyContent='center' item xs={12} className={classes.contentBox}>
                <Grid container item xs={12} style={{ marginTop: '10px' }}>
                  <TextFields
                    id='outlined-name'
                    value={data.title}
                    variant='outlined'
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='start'>
                          <span style={{ color: '#cfcfcf' }}>을(를) 목격했습니다.</span>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid container item xs={12} style={{ marginTop: '7px' }}>
                  <Grid container item xs={6} className={classes.isBorder}>
                    <TextFields
                      id='outlined-name'
                      value={data.date}
                      variant='outlined'
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>
                            <span style={{ color: '#cfcfcf' }}>날짜</span>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid container item xs={6}>
                    <TextFields
                      id='outlined-name'
                      value={data.time}
                      variant='outlined'
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>
                            <span style={{ color: '#cfcfcf' }}>시간</span>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container item xs={12} style={{ marginTop: '7px' }}>
                  <TextField className={classes.contentTitle} value={'자세한 경위'} variant='outlined' />
                  <TextFields id='outlined-name' value={data.content} variant='outlined' multiline rows={4} />
                </Grid>
                <Grid container item xs={12} style={{ marginTop: '7px' }}>
                  <TextFields
                    id='outlined-name'
                    value={this.state.url}
                    variant='outlined'
                    onClick={this.goToUrl}
                    style={{ cursor: 'pointer' }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>
                          <span style={{ color: '#cfcfcf' }}>영상</span>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid container item xs={12} style={{ marginTop: '7px' }}>
                  <span style={{ color: '#cfcfcf', fontSize: '12.5px', padding: '5px 14px' }}>해당 영상을 클릭하시면 영상 상세 페이지로 이동하실 수 있습니다.</span>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
        </Grid>
      </Dialog>
    );
  }
}

export default withStyles(styles)(ReportDialog);
