import React from 'react';
import { Grid, withStyles, withWidth, Button } from '@material-ui/core';

const styles = theme => ({
  noticeWrapper: {
    padding: '1.5rem 3.5rem 1.5rem 3.5rem',
    backgroundColor: '#171A1D',
    borderRadius: '5px',
    lineHeight: '2',
    marginBottom: '3rem',
    '& strong': {
      fontSize: '17px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0.5rem 1.5rem 0.5rem 1.5rem',
    },
  },
  noticeWrapperShaking: {
    padding: '1.5rem 3.5rem 1.5rem 3.5rem',
    border: '1px solid #e61737',
    backgroundColor: '#171A1D',
    borderRadius: '5px',
    lineHeight: '2',
    marginBottom: '3rem',
    '& strong': {
      fontSize: '17px',
    },
    animation: 'shake 0.6s',
    [theme.breakpoints.down('sm')]: {
      padding: '0.5rem 1.5rem 0.5rem 1.5rem',
    },
  },
  questionWrapper: {
    marginBottom: '3rem',
  },
  questionButtonWrapper: {
    letterSpacing: '5rem',
    marginTop: '0.5rem',
    [theme.breakpoints.down('sm')]: {
      // margin : theme.spacing(1, 0)
    },
  },
  buttonWrapper: {
    borderTop: '1px solid #8f8f8f',
    paddingTop: '2.5rem',
    display: 'flex',
    justifyContent: 'center',
  },
  colorBtn: {
    backgroundColor: '#e61737',
    '&:hover': {
      backgroundColor: '#e61737',
    },
    border: '1px solid #e61737',
    color: '#fff',
    fontWeight: 'bold',
    fontSize: 'large',
    height: '50px',
    margin: theme.spacing(1, 0),
    padding: theme.spacing(2, 0),
  },
  disabledBtn: {
    backgroundColor: ' #1f2125',
    border: '1px solid #474747',
    color: '#ccc',
    fontWeight: 'bold',
    fontSize: 'large',
    height: '50px',
    margin: theme.spacing(1, 0),
    padding: theme.spacing(2, 0),
    [theme.breakpoints.down('md')]: {},
  },
  qBtnTrue: {
    backgroundColor: '#e61737',
    '&:hover': {
      backgroundColor: '#e61737',
    },
    border: '1px solid #e61737',
    color: '#fff',
    fontSize: '14px',
    width: '100%',
    height: '46px',
    // margin: theme.spacing(1, 0),
    padding: theme.spacing(2, 0),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  qBtnFalse: {
    backgroundColor: ' #1f2125',
    border: '1px solid #474747',
    color: '#ccc',
    fontSize: '14px',
    width: '100%',
    height: '46px',
    // margin: theme.spacing(1, 0),
    padding: theme.spacing(2, 0),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  questionTitle: {
    fontSize: '16px',
  },
  requiredMark: {
    color: '#f60337',
  },
});

class Entrance extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabValue: 0, // 탭 시작값
      matches: window.matchMedia('(max-width:480px)').matches, //md:992px
      q1Button: false,
      q2Button: true,
      preView: null,
    };
    this.refList = [];
    this.onClickQ1Btn = this.onClickQ1Btn.bind(this);
    this.onClickQ2Btn = this.onClickQ2Btn.bind(this);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.videoScroll, true);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.videoScroll, true);
  }

  onClickQ1Btn(v) {
    this.setState({ q1Button: v });
  }

  onClickQ2Btn(v) {
    this.setState({ q2Button: v });
  }

  onClickFileSelect(e) {
    e.preventDefault();
    document.getElementById('file-picker').click();
  }

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <Grid>
          <Grid container direction='column'>
            {/* Notice */}
            <Grid container direction='column' className={!this.state.q1Button || this.state.q2Button ? classes.noticeWrapperShaking : classes.noticeWrapper}>
              <Grid item>
                <strong>업로드시 유의사항</strong>
                <br />
                1) 제보마켓에 업로드되는 모든 영상은 본인이 <span className={classes.requiredMark}>직접 촬영한 영상</span>이여야 합니다.
                <br />
                2) 제보마켓에 업로드되는 모든 영상은 이전에 <span className={classes.requiredMark}>다른 사이트(개인 SNS포함)</span>에 업로드된 적이 없어야 합니다.
                <br />
              </Grid>
            </Grid>

            {/* Questions */}
            <Grid container direction='column' className={classes.questionWrapper}>
              <Grid container direction='column' spacing={5}>
                {/* Q1 */}
                <Grid item>
                  <Grid container direction='column'>
                    <Grid item className={classes.questionTitle}>
                      Q1. 직접 촬영하신 영상인가요?&nbsp;<span className={classes.requiredMark}>*</span>
                    </Grid>
                    <Grid container className={classes.questionButtonWrapper} spacing={3} justifyContent='flex-end'>
                      <Grid container item xs={12} sm={12} md={3} lg={3} justifyContent='flex-end'>
                        <Button className={this.state.q1Button ? classes.qBtnTrue : classes.qBtnFalse} onClick={() => this.onClickQ1Btn(true)}>
                          네
                        </Button>
                      </Grid>
                      <Grid container item xs={12} sm={12} md={3} lg={3} justifyContent='flex-start'>
                        <Button className={this.state.q1Button ? classes.qBtnFalse : classes.qBtnTrue} onClick={() => this.onClickQ1Btn(false)}>
                          아니오
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                {/* Q2 */}
                <Grid item>
                  <Grid container direction='column'>
                    <Grid item className={classes.questionTitle}>
                      Q2. 다른 사이트에 이 영상을 업로드 하신 적 있으신가요?&nbsp;<span className={classes.requiredMark}>*</span>
                    </Grid>
                    <Grid container className={classes.questionButtonWrapper} spacing={3} justifyContent='flex-end'>
                      <Grid container item xs={12} sm={12} md={3} lg={3} justifyContent='flex-end'>
                        <Button className={this.state.q2Button ? classes.qBtnTrue : classes.qBtnFalse} onClick={() => this.onClickQ2Btn(true)}>
                          네
                        </Button>
                      </Grid>
                      <Grid container item xs={12} sm={12} md={3} lg={3} justifyContent='flex-start'>
                        <Button className={this.state.q2Button ? classes.qBtnFalse : classes.qBtnTrue} onClick={() => this.onClickQ2Btn(false)}>
                          아니오
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {/* Button */}
            <div className={classes.buttonWrapper}>
              <Grid container item justifyContent='center' md={6} xs={6}>
                <input
                  type='file'
                  id='file-picker'
                  multiple
                  accept='video/*'
                  style={{ display: 'none' }}
                  onChange={e => {
                    this.props.onChangeFile(e);
                  }}
                />

                <Button
                  fullWidth={true}
                  className={!(this.state.q1Button && !this.state.q2Button) ? classes.disabledBtn : classes.colorBtn}
                  onClick={this.onClickFileSelect}
                  disabled={!(this.state.q1Button && !this.state.q2Button)}
                >
                  파일 선택
                </Button>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(withWidth()(Entrance));
