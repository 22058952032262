import React, { Component } from 'react';

import CloseIcon from '@material-ui/icons/Close';

import { Button, Dialog, DialogTitle, DialogContent, Grid, IconButton, withStyles, Avatar } from '@material-ui/core';

const styles = theme => ({
  colorBtn: {
    marginTop: '2rem',
    backgroundColor: '#e61737',
    border: '2px solid #e61737',
    borderRadius: '5px',
    color: '#ffffff',
    height: '5.5vh',
    fontWeight: 'bold',
    margin: theme.spacing(1, 0),
    [theme.breakpoints.down('md')]: {
      border: '1px solid #e61737',
      borderRadius: '5px',
      height: '50px',
      margin: theme.spacing(1, 0),
    },
  },
  btn: {
    backgroundColor: '#0c0e10',
    border: '2px solid #0c0e10',
    borderRadius: '5px',
    color: '#ffffff',
    height: '5.5vh',
    fontWeight: 'bold',
    margin: theme.spacing(1, 0),
    [theme.breakpoints.down('md')]: {
      border: '1px solid #0c0e10',
      borderRadius: '5px',
      height: '50px',
      margin: theme.spacing(1, 0),
    },
  },
  dialogBorder: {
    backgroundColor: 'transparent',
    '& .MuiPaper-rounded': {
      borderRadius: '0px',
    },
  },
  profileImg: {
    width: '10vh',
    height: '10vh',
  },
});

class ProfileDialog extends Component {
  render() {
    const { classes, open, onClose, tx, userData } = this.props;

    return (
      <Dialog className={classes.dialogBorder} fullWidth={true} maxWidth={'xs'} open={open} onClose={onClose}>
        <DialogTitle style={{ backgroundColor: '#171A1D' }}>
          <Grid container justifyContent='center'>
            <Grid container item xs={11}>
              <Grid container item xs={10} alignContent='center'>
                <b style={{ color: '#fff' }}>프로필 사진</b>
              </Grid>

              <Grid container item xs={2} justifyContent='flex-end'>
                <IconButton onClick={onClose}>
                  <CloseIcon style={{ color: '#fff' }} />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </DialogTitle>

        <DialogContent style={{ backgroundColor: '#171A1D' }}>
          <Grid id='positioning_grid' container justifyContent='center'>
            <Grid container justifyContent='center' alignItems='center' item xs={4}>
              <Avatar className={classes.profileImg} src={userData?.picture ? userData?.picture.path : undefined} />
              <input type='file' id='file-picker' accept='image/*' style={{ display: 'none' }} onChange={tx.onChangeFile} />
            </Grid>
            <Grid container justifyContent='center' item xs={10}>
              <Button fullWidth className={classes.colorBtn} onClick={() => document.getElementById('file-picker').click()}>
                이미지 수정
              </Button>
              <Button fullWidth className={classes.btn} onClick={tx.onDeleteFile}>
                이미지 삭제
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  }
}

export default withStyles(styles, { withTheme: true })(ProfileDialog);
