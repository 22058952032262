import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { CssBaseline, withStyles, withWidth } from '@material-ui/core';
import Topbar from './Topbar';
import Footer from './Footer';
import { connect } from 'react-redux';
import BannerSection from '../section/BannerSection';

const styles = theme => ({
  container: {
    width: '100%',
    height: '100%',
    overflowY: 'auto',
    overflowX: 'auto',
    position: 'relative',
    backgroundColor: '#171A1D',
  },
});

const LayoutPage = props => {
  const { classes } = props;
  const [matches, setMatches] = useState(window.matchMedia('(max-width:480px)').matches);

  return (
    <Fragment>
      <div className={classes.container}>
        <div id='topScroll' />
        <Topbar borderCheck={props.topbarBorderCheck} />

        <div style={{ display: 'flex', width: '100%', flexDirection: 'row', minHeight: 'calc(100vh - 80px)', backgroundColor: '#171A1D' }}>
          <div style={{ width: !matches && props.menuValue ? '20%' : '0%' }}></div>
          <div style={{ width: !matches && props.menuValue ? '80%' : '100%', overflowX: 'hidden' }}>
            {props.bannerSection && <BannerSection />}
            <CssBaseline />
            {props.children}
          </div>
        </div>
        {props.footer && <Footer />}
      </div>
    </Fragment>
  );
};

LayoutPage.propTypes = {
  children: PropTypes.any,
};

const mapStateToProps = state => {
  return {
    menuValue: state.changeMenu.value,
  };
};

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps)(withWidth()(LayoutPage)));
