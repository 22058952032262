import AWS from 'aws-sdk';

const S3_BUCKET = 'file.jebomarket.com';
const REGION = 'ap-northeast-2';

AWS.config.update({
  accessKeyId: 'AKIAY7RRXRNTILAUINMU',
  secretAccessKey: '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',
});

const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
});

const UploadToS3 = (file, folder, id, callback, keyName, fileName) => {
  return new Promise((resolve, reject) => {
    const orgFileName = fileName;
    const ext = fileName;
    const _key = keyName ? folder + '/' + id + '_' + keyName + '.' + ext : folder + '/' + id + '_' + fileName;
    const params = {
      ACL: 'public-read',
      Body: file,
      Bucket: S3_BUCKET,
      Key: _key,
    };

    myBucket
      .putObject(params)
      .on('httpUploadProgress', evt => {
        if (callback) callback(Math.round((evt.loaded / evt.total) * 100));
      })
      .send((err, data) => {
        if (err) console.log(err);
        else {
          resolve({ key: _key, name: orgFileName, path: `https://s3.${REGION}.amazonaws.com/${S3_BUCKET}/${_key}` });
        }
      });
  });
};

export default UploadToS3;
