import React, { Component } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, Grid, IconButton, withStyles, TextField } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { API_URL } from '../constants';
import axios from 'axios';
import axiosInstance from '../constants/AxiosInstance';
import { Auth } from 'aws-amplify';
import { BiCheckCircle } from 'react-icons/bi';

import Alert from '../components/Alert';

const styles = theme => ({
  loginInput: {
    '& input': {
      height: '5vh',
      color: '#fefefe',
      backgroundColor: '#0c0e10',
      borderRadius: '5px',
      padding: '0 1vw ',
      [theme.breakpoints.down('md')]: {
        height: '50px',
        padding: '0 4vw ',
        // fontSize: '19px',
      },
    },

    '& .MuiFormHelperText-root': {
      display: 'none',
      color: 'rgb(24, 27, 31)',
    },
    '& .MuiFormHelperText-root.Mui-error': {
      color: '#e61737',
    },
  },
  sendAuthNumBtn: {
    height: '5vh',
    width: '95%',
    border: '1px solid #ffffff',
    color: '#fefefe',
    [theme.breakpoints.down('md')]: {
      height: '50px',
      wordBreak: 'keep-all',
    },
  },
  snsButton: {
    height: '5vh',
    borderRadius: '5px',
    [theme.breakpoints.down('md')]: {
      height: '50px',
      borderRadius: '5px',
    },
  },
  dialogBorder: {
    '& .MuiPaper-rounded': {
      borderRadius: '0px',
    },
  },
});

class PasswordDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      matches: window.matchMedia('(max-width:991px)').matches,
      pwPageState: 0,
      authEmail: '',
      oldPassword: '',
      newPassword: '',
      sendEmail: false,
      emailValidation: false,
      isAuth: false,
      pwChange: false,
      authCode: '',
    };
  }

  componentDidMount() {
    if (this.props.pwChange) {
      this.setState({ pwPageState: 2, isAuth: true, pwChange: true });
    }
  }

  handleChangeEmail(type, e) {
    const re = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
    if (e.target.value === '' || re.test(e.target.value)) {
      this.setState({ emailValidation: true });
      this.setState({ [type]: e.target.value });
    } else {
      this.setState({ emailValidation: false });
      this.setState({ [type]: e.target.value });
    }
  }

  handleChange(type, e) {
    this.setState({ [type]: e.target.value });
  }

  sendValidEmail = () => {
    if (this.state.authEmail === '' || this.state.emailValidation === false) {
      Alert('', '이메일을 정확히 입력해주세요.');
    } else {
      let param = {
        toEmail: this.state.authEmail,
      };

      axios
        .post(`${API_URL}/mail/forgotPwd`, param, { headers: { Pragma: 'no-cache' } })
        .then(response => {
          Alert('', '인증메일이 발송되었습니다.', '', '', () => {
            this.setState({ pwPageState: 1 });
          });
        })
        .catch(error => {
          console.log(error);
          Alert('', '인증메일 발송에 실패하였습니다.');
        });
    }
  };

  changePw = () => {
    if (this.state.newPassword === '') Alert('', '비밀번호를 입력해주세요.');
    else {
      this.doChange();
    }
  };

  pwdChange = Id => {
    let param = {
      userId: Id ? Id : this.state.authEmail,
      newpassword: this.state.newPassword,
    };

    axios.put(`${API_URL}/user/changePwd`, param, { headers: { Pragma: 'no-cache' } }).then(response => {
      Alert('', '비밀번호가 변경되었습니다.', '', '', () => {
        this.closeDialog();
      });
    });
  };

  doChange = () => {
    if (this.state.pwChange) {
      const _id = localStorage.getItem('jebo_user');
      Auth.signIn(_id, this.state.oldPassword)
        .then(user => {
          if (user) this.pwdChange(_id);
        })
        .catch(err => {
          Alert('', '기존 비밀번호가 일치하지 않습니다.');
          return false;
        });
    } else {
      this.pwdChange();
    }
  };

  logOut() {
    localStorage.clear();
    sessionStorage.clear();
    Auth.signOut().then(() => {
      this.props.handleCloseMypage();
    });
  }

  reSendEmail = () => {
    if (this.state.authEmail === '' || this.state.emailValidation === false) {
      Alert('', '인증메일을 정확히 입력해주세요.');
    } else {
      Alert('', '인증메일이 재발송 되었습니다.');
      this.setState({ pwPageState: 1 });
    }
  };

  codeProveCheck = () => {
    axiosInstance.get(`${API_URL}/user/verify?key=${this.state.authEmail}&code=${this.state.authCode}`, { headers: { Pragma: 'no-cache' } }).then(response => {
      response.data.value.verify
        ? Alert('', '인증이 완료되었습니다.', '', '', () => {
            this.setState({ isAuth: true, pwPageState: 2 });
          })
        : Alert('인증번호 불일치', '인증번호가 올바르지 않습니다.');
    });
  };

  closeDialog = () => {
    if (!this.props.pwChange) {
      this.setState(
        {
          pwPageState: 0,
          authEmail: '',
          newPassword: '',
          sendEmail: false,
          emailValidation: false,
          isAuth: false,
          authCode: '',
          oldPassword: '',
        },
        () => {
          this.props.onClose();
        },
      );
    } else {
      this.props.onClose();
    }
  };

  render() {
    const { open, onClose, classes } = this.props;
    const { pwPageState } = this.state;

    return (
      <Dialog className={classes.dialogBorder} fullWidth={true} maxWidth={'xs'} open={open} style={{ overflow: 'hidden' }}>
        <DialogTitle style={{ backgroundColor: '#171A1D' }}>
          <Grid container justifyContent='center' item xs={12}>
            <Grid container justifyContent='center' item xs={12} md={9}>
              <Grid container item xs={11} alignContent='center' justifyContent='flex-start'>
                {this.state.pwChange ? <b style={{ color: '#ffffff' }}>비밀번호 재설정</b> : <b style={{ color: '#ffffff' }}>비밀번호 찾기</b>}
              </Grid>
              <Grid container item xs={1}>
                <IconButton onClick={this.closeDialog}>
                  <CloseIcon style={{ color: '#ffffff' }} />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </DialogTitle>

        <DialogContent style={{ backgroundColor: '#171A1D' }}>
          <Grid container justifyContent='center'>
            {pwPageState === 0 && (
              <React.Fragment>
                <Grid container justifyContent='center' item xs={11} md={11} style={{ paddingBottom: this.state.matches ? '3vh' : '2vh' }}>
                  <Grid container justifyContent='flex-start' style={{ color: '#fff', padding: this.state.matches ? '0 0 2vh' : '1vh 0 ' }}>
                    이메일<span style={{ color: '#e61737' }}>*</span>
                  </Grid>
                  <Grid container justifyContent='center'>
                    <TextField
                      id='authEmail'
                      type='email'
                      name='authEmail'
                      value={this.state.authEmail}
                      className={classes.loginInput}
                      fullWidth={true}
                      variant='outlined'
                      onChange={e => this.handleChangeEmail('authEmail', e)}
                      error={this.state.emailValidation === false ? true : false}
                      placeholder='이메일을 입력해 주세요'
                      helperText=' 공란이거나 올바른 이메일 형식이 아닙니다.'
                    />
                  </Grid>
                </Grid>
                <Grid container justifyContent='center' item xs={12} md={10} style={{ paddingBottom: this.state.matches ? '1vh' : '1vh' }}>
                  <span style={{ textAlign: 'center', fontWeight: 500, fontSize: 'small', color: '#fefefe' }}>
                    가입할 때 사용하신 이메일 주소를 입력해주세요.
                    <br />
                    해당 이메일로 인증 메일이 발송됩니다.
                  </span>
                </Grid>
                <Grid container justifyContent='center' item xs={12}>
                  <Button fullWidth={true} className={classes.snsButton} style={{ backgroundColor: '#e61737', color: '#ffffff', fontWeight: 'bold' }} onClick={this.sendValidEmail}>
                    인증메일 발송
                  </Button>
                </Grid>

                {/* <Grid container justifyContent="center" item xs={11} style={{ padding: this.state.matches ? '2vh 0 4vh' : '2vh 0 4vh' }}>
                <span style={{ color: '#fefefe' }}>
                    인증메일이 오지 않았나요?&nbsp;{' '}
                    <u style={{ cursor: 'pointer' }} onClick={this.reSendEmail}>
                      인증메일 재발송
                    </u>
                  </span>
              </Grid> */}
              </React.Fragment>
            )}

            <Grid container justifyContent='center' item xs={11} style={{ paddingBottom: this.state.matches ? '3vh' : '2vh' }}>
              {this.state.pwPageState === 1 && (
                <Grid container justifyContent='center' style={{ padding: this.state.matches ? '1vh 0' : '0.5vh 0' }}>
                  <Grid item xs={8}>
                    <TextField
                      id='authCode'
                      name='code'
                      value={this.state.authCode}
                      className={classes.loginInput}
                      fullWidth={true}
                      variant='outlined'
                      onChange={e => this.handleChange('authCode', e)}
                      error={this.state.authCode === ''}
                      placeholder='인증코드를 입력해주세요'
                    />
                  </Grid>
                  <Grid container justifyContent='flex-end' item xs={4}>
                    <Button className={classes.sendAuthNumBtn} onClick={() => this.codeProveCheck()} disabled={this.state.isAuth === true ? true : false}>
                      인증하기
                    </Button>
                  </Grid>
                </Grid>
              )}

              {this.state.isAuth === true ? (
                <React.Fragment>
                  {/* 기존비밀번호 */}
                  {this.state.pwChange && (
                    <>
                      <Grid container justifyContent='flex-start' style={{ color: '#fff', padding: this.state.matches ? '0 0 1vh' : '1vh 0' }}>
                        기존 비밀번호<span style={{ color: '#e61737' }}>*</span>
                      </Grid>
                      <Grid container justifyContent='center'>
                        <TextField
                          id='oldPassword'
                          type='text'
                          name='oldPassword'
                          value={this.state.oldPassword}
                          className={classes.loginInput}
                          fullWidth={true}
                          variant='outlined'
                          onChange={e => this.handleChange('oldPassword', e)}
                          placeholder='기존 비밀번호를 입력해 주세요.'
                          error={this.state.newPassword === ''}
                          helperText='기존 비밀번호를 입력해 주세요.'
                        />
                      </Grid>
                    </>
                  )}

                  <Grid container justifyContent='flex-start' style={{ color: '#fff', padding: this.state.matches ? '0 0 1vh' : '1vh 0' }}>
                    비밀번호 재설정<span style={{ color: '#e61737' }}>*</span>
                  </Grid>
                  <Grid container justifyContent='center'>
                    <TextField
                      id='newPassword'
                      type='text'
                      name='newPassword'
                      value={this.state.newPassword}
                      className={classes.loginInput}
                      fullWidth={true}
                      variant='outlined'
                      onChange={e => this.handleChange('newPassword', e)}
                      placeholder='새로운 비밀번호를 입력해 주세요.'
                      error={this.state.newPassword === ''}
                      helperText='새로운 비밀번호를 입력해 주세요.'
                    />
                  </Grid>

                  <Grid container justifyContent='center' item xs={12} style={{ padding: '2vh 0' }}>
                    <Button fullWidth={true} className={classes.snsButton} style={{ backgroundColor: '#e61737', color: '#ffffff', fontWeight: 'bold' }} onClick={this.changePw}>
                      비밀번호 재설정
                    </Button>
                  </Grid>
                </React.Fragment>
              ) : (
                ''
              )}
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  }
}

export default withStyles(styles)(PasswordDialog);
