import React, { Component } from 'react';
import { Dialog, DialogTitle, DialogContent, Grid, withStyles, Button, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import EditDetail from '../upload/components/EditDetail';

const styles = theme => ({
  editDialog: {
    backgroundColor: '#171A1D',
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      overflow: 'auto',
    },
  },
});

class VodEditDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      matches: window.matchMedia('(max-width:991px)').matches,
    };
  }

  onClickClose = () => {
    this.props.setDialog(false);
  };

  render() {
    const { classes, open, data, onClose, reRender, history } = this.props;

    return (
      <Dialog fullWidth={true} maxWidth={'sm'} open={open}>
        <DialogContent className={classes.editDialog}>
          <Grid container item xs={12} justifyContent='flex-end'>
            <IconButton onClick={onClose}>
              <CloseIcon style={{ color: '#ffffff' }} />
            </IconButton>
          </Grid>
          <EditDetail data={data} onClose={onClose} reRender={reRender} history={history} />
        </DialogContent>
      </Dialog>
    );
  }
}

export default withStyles(styles, { withTheme: true })(VodEditDialog);
