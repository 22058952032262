import React from 'react';
import { Button, Grid, withStyles, withWidth } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { API_URL, getAWSCredentials, KAKAKO_KEY, NAVER_CALLBACK, NAVER_KEY } from '../constants';
import awsconfig from '../aws-exports';
import axios from 'axios';
import { Amplify, Auth } from 'aws-amplify';
Amplify.configure(awsconfig);

const styles = theme => ({
  snsButton: {
    height: '5vh',
    borderRadius: '5px',
    [theme.breakpoints.down('md')]: {
      height: '50px',
      borderRadius: '5px',
    },
  },
});

class KaKaoCallback extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      kakao: null,
    };

    this.handleKakao = this.handleKakao.bind(this);
  }

  componentDidMount() {
    let KakaoSdk = window['Kakao'];
    this.setState({ kakao: KakaoSdk });
    if (KakaoSdk && !KakaoSdk.isInitialized()) {
      KakaoSdk.init(KAKAKO_KEY);
    }
  }

  handleKakao() {
    this.state.kakao.Auth.login({
      success: function (res) {
        res['poolId'] = awsconfig.aws_user_pools_id;
        res['clientId'] = awsconfig.aws_user_pools_web_client_id;
        axios.post(`${API_URL}/sns/kakao`, res).then(result => {
          getAWSCredentials({ accessToken: res.access_token, expiresIn: res.expires_in }, 'Kakao', {
            userId: result.data.value.userId,
            email: result.data.value.email,
          }).then(() => {
            this.props.onClose();
            document.location.reload(true);
          });
        });
      },
      fail: function (error) {
        console.log(error.error_description);
        alert('fail');
      },
    });
  }

  render() {
    const { classes, text } = this.props;
    return (
      <React.Fragment>
        <Grid container justifyContent='center' style={{ paddingTop: this.state.matches ? '2vh' : '2vh' }}>
          <Button onClick={() => this.handleKakao()} fullWidth={true} className={classes.snsButton} style={{ backgroundColor: '#ffea0f' }}>
            <Grid container justifyContent='center' alignItems='center' item xs={1}>
              <img src='/images/common/ic_kakao.png' alt={'img'} />
            </Grid>
            <Grid container justifyContent='center' item xs={11} style={{ color: '#3c1e1e', fontWeight: 'bold' }}>
              카카오로 {text}
            </Grid>
          </Button>
        </Grid>
      </React.Fragment>
    );
  }
}

export default withRouter(withStyles(styles)(withWidth()(KaKaoCallback)));
