import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Dialog, DialogTitle, DialogContent, Grid, IconButton, MenuItem, withStyles, TextField, Select, CircularProgress, Box } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import axiosInstance from '../constants/AxiosInstance';
import { API_URL } from '../constants';

const styles = theme => ({
  loginInput: {
    '& input': {
      backgroundColor: '#0c0e10',
      [theme.breakpoints.down('md')]: {},
    },
  },
  colorBtn: {
    backgroundColor: '#e61737',
    border: '2px solid #e61737',
    borderRadius: '5px',
    color: '#ffffff',
    height: '5vh',
    fontWeight: 'bold',
    margin: theme.spacing(2, 0),
    [theme.breakpoints.down('md')]: {
      border: '1px solid #e61737',
      borderRadius: '5px',
      height: '50px',
      margin: theme.spacing(1, 0),
    },
  },
  menuItem: {
    color: '#ffffff',
  },
  selectBox: {
    width: '100%',
    height: '50px',
    marginBottom: '10px',
    textAlign: 'center',
    color: 'white',
    background: 'rgb(12, 14, 16)',
  },
  floatingLabel: {
    width: '100%',
    '& .MuiInputLabel-root': {
      color: 'rgba(254, 254, 254, 0.25);',
    },
    '& .MuiInputBase-root': {
      color: '#ffffff',
      fontSize: '12px',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'rgba(254, 254, 254, 0.25);',
      },
      '&:hover fieldset': {
        borderColor: 'rgba(254, 254, 254, 0.25);',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'rgba(254, 254, 254, 0.25);',
      },
    },
  },
  DialogMain: {
    backgroundColor: '#171A1D',
    padding: '15px',
    [theme.breakpoints.down('md')]: {
      padding: '0',
    },
  },
  DialogMainEvent: {
    backgroundColor: '#171A1D',
    padding: '15px',
    [theme.breakpoints.down('md')]: {
      padding: '0',
    },
    animation: 'shake 0.6s',
  },
  circularProgress: {
    position: 'fixed',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
});

class OfferDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      channel: '', //구매자 소속
      purpose: '', //사용목적
      vod: '',
      amount: '',
      buyer: localStorage.getItem('jebo_user'),
      seller: this.props.data.userId,
      userName: localStorage.getItem('jebo_name'),
      select1YouTube: {
        channel: '',
        url: '',
      },
      matches: window.matchMedia('(max-width:991px)').matches,
      circularProgress: false,
      Q1List: [],
      Q2List: [],
      select1: '',
      select2: '',
      submit: 1,
      delay: '',

      text: '',
    };

    this.refChannel = React.createRef();
    this.refPurpose = React.createRef();
    this.refYoutubeChannel = React.createRef();
    this.refYoutubeUrl = React.createRef();
  }

  componentDidMount() {
    this.getSelectOptions();
    this.getText();
  }

  getSelectOptions = () => {
    axiosInstance.get(`${API_URL}/code/`).then(response => {
      // FILTERING TWO GROUPS : { equipment, topic }
      const Q1 = response.data.value.filter(x => x.group === 'Q1');
      const Q2 = response.data.value.filter(x => x.group === 'Q2');

      Q1.sort((a, b) => {
        return Number(a.order) > Number(b.order) ? 1 : -1;
      });
      Q2.sort((a, b) => {
        return Number(a.order) > Number(b.order) ? 1 : -1;
      });

      this.setState({ Q1List: Q1, Q2List: Q2, select1: Q1[0].value, select2: Q2[0].value });
    });
  };

  getText = async () => {
    let response = await axiosInstance.get(`${API_URL}/info/company`, { headers: { Pragma: 'no-cache' } });

    this.setState({ text: response.data.value.reqbox });
  };

  handleChange = (event, newValue) => {
    if (event === 'select1YouTubeChannel' || event === 'select1YouTubeUrl') {
      let _select1YouTube = this.state.select1YouTube;
      if (event === 'select1YouTubeChannel') _select1YouTube['channel'] = newValue;
      else _select1YouTube['url'] = newValue;
      this.setState({ select1YouTube: _select1YouTube });
    } else {
      this.setState({ [event]: newValue });
    }
  };

  requestBtn = () => {
    let param = {
      channel: '',
      purpose: '',
      vod: this.props.data.id,
      status: 'request',
      amount: this.props.data.price,
      account: 'withhold',
      buyer: this.state.buyer,
      seller: this.props.data.userId,
      downloadCnt: 0,
    };

    if (this.state.select1 === '기타') {
      if (this.state.channel === '') {
        this.focusEvent('channel');
        return;
      } else {
        param['channel'] = this.state.channel;
      }
    } else if (this.state.select1 === '유튜브') {
      if (this.state.select1YouTube.channel === '') {
        this.focusEvent('youTubeChannel');
        return;
      } else if (this.state.select1YouTube.url === '') {
        this.focusEvent('youTubeUrl');
        return;
      } else {
        param['channel'] = this.state.select1YouTube;
      }
    } else {
      param['channel'] = this.state.select1;
    }

    if (this.state.select2 === '기타 (직접입력)') {
      if (this.state.purpose === '') {
        this.focusEvent('purpose');
        return;
      } else {
        param['purpose'] = this.state.purpose;
      }
    } else {
      param['purpose'] = this.state.select2;
    }
    this.setState({ circularProgress: true });
    axiosInstance.post(`${API_URL}/order/`, param, { headers: { Pragma: 'no-cache' } }).then(response => {
      if (response.status === 200) {
        this.setState({ submit: 2, circularProgress: false });
      }
    });
  };

  focusEvent = id => {
    document.getElementById(id).focus();
    this.shakeAction();
  };

  shakeAction = () => {
    let animationElemnet = document.getElementById('DialogMain').parentElement;
    if (this.state.delay) {
      clearTimeout(this.state.delay);
    }
    animationElemnet.style.removeProperty('animation');
    let delay = setTimeout(() => {
      animationElemnet.style = 'animation: shake 0.6s';
    }, 200);
    this.setState({ delay: delay });
  };

  render() {
    const { open, onClose, classes } = this.props;
    return (
      <Dialog fullWidth={true} maxWidth={'xs'} onClose={onClose} open={open}>
        {this.state.submit === 1 && (
          <Grid container item xs={12} id='DialogMain' className={classes.DialogMain}>
            <DialogTitle style={{ width: '100%' }}>
              <Grid container justifyContent='center'>
                <Grid container item xs={12}>
                  <Grid container item xs={10} alignContent='center'>
                    <b style={{ color: '#ffffff' }}>영상구매 의뢰하기</b>
                  </Grid>
                  <Grid container item xs={2} justifyContent='flex-end'>
                    <IconButton onClick={onClose}>
                      <CloseIcon style={{ color: '#ffffff' }} />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </DialogTitle>

            <DialogContent>
              <Grid id='positioning_grid' container justifyContent='center'>
                {/* Q1 Button */}
                <Grid container justifyContent='center' item xs={12}>
                  <Grid container style={{ color: '#ffffff', fontWeight: 'bold', marginBottom: '0.5rem' }}>
                    Q1.구매하시는 분은 누구신가요&nbsp;?&nbsp;<span style={{ color: '#e61737' }}>*</span>
                  </Grid>
                  <Grid container justifyContent={this.state.matches ? 'center' : 'center'} item xs={12}>
                    <Select className={classes.selectBox} value={this.state.select1} onChange={e => this.handleChange('select1', e.target.value)}>
                      {this.state.Q1List.map((item, index) => (
                        <MenuItem value={item.value} key={`${item.value}_${index}`}>
                          {item.value}
                        </MenuItem>
                      ))}
                    </Select>
                    {/* if statement matchs, add textfield */}
                    {this.state.select1 === '유튜브' && (
                      <Grid container item spacing={1} xs={12} style={{ display: 'flex', marginBottom: '0.75rem' }}>
                        <Grid item xs={4}>
                          <TextField
                            label='채널명'
                            id='youTubeChannel'
                            variant='outlined'
                            className={classes.floatingLabel}
                            value={this.state.select1YouTube.channel}
                            inputRef={this.refYoutubeChannel}
                            onChange={e => this.handleChange('select1YouTubeChannel', e.target.value)}
                          />
                        </Grid>
                        <Grid item xs={8}>
                          <TextField
                            label='유튜브 url (직접입력)'
                            id='youTubeUrl'
                            variant='outlined'
                            className={classes.floatingLabel}
                            value={this.state.select1YouTube.url}
                            inputRef={this.refYoutubeUrl}
                            onChange={e => this.handleChange('select1YouTubeUrl', e.target.value)}
                          />
                        </Grid>
                      </Grid>
                    )}
                    {this.state.select1 === '기타 (직접입력)' && (
                      <Grid container item xs={12} style={{ display: 'flex', marginBottom: '0.75rem' }}>
                        <TextField
                          label='직접입력'
                          variant='outlined'
                          id='channel'
                          className={classes.floatingLabel}
                          value={this.state.channel}
                          inputRef={this.refChannel}
                          onChange={e => this.handleChange('channel', e.target.value)}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Grid>

                {/* Q2 Button */}
                <Grid container justifyContent='center' item xs={12}>
                  <Grid container style={{ color: '#ffffff', fontWeight: 'bold', marginBottom: '0.5rem' }}>
                    Q2.어디에 사용하실 계획인가요&nbsp;?&nbsp;<span style={{ color: '#e61737' }}>*</span>
                  </Grid>
                  <Grid container justifyContent={this.state.matches ? 'center' : 'center'} item xs={12}>
                    <Select className={classes.selectBox} value={this.state.select2} onChange={e => this.handleChange('select2', e.target.value)}>
                      {this.state.Q2List.map((item, index) => (
                        <MenuItem value={item.value} key={`${item.value}_${index}`}>
                          {item.value}
                        </MenuItem>
                      ))}
                    </Select>
                    {/* if statement matchs, add textfield */}
                    {this.state.select2 === '기타 (직접입력)' && (
                      <Grid container item xs={12} style={{ display: 'flex', marginBottom: '0.75rem' }}>
                        <TextField
                          label='직접입력'
                          id='purpose'
                          variant='outlined'
                          className={classes.floatingLabel}
                          value={this.state.purpose}
                          inputRef={this.refPurpose}
                          onChange={e => this.handleChange('purpose', e.target.value)}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Grid>

                {/* 의뢰하기 */}
                <Grid container justifyContent='center' item xs={12}>
                  <Button fullWidth={true} className={classes.colorBtn} onClick={() => this.requestBtn()}>
                    의뢰하기
                  </Button>
                </Grid>
              </Grid>
            </DialogContent>
          </Grid>
        )}

        {/* 의뢰 완료 시 */}
        {this.state.submit === 2 && (
          <Grid container item xs={12} className={classes.DialogMain} style={{ color: '#ffffff' }}>
            <DialogTitle style={{ width: '100%' }}>
              <Grid container item xs={12} style={{ width: '100%', textAlign: 'center', display: 'block' }}>
                <div style={{ textAlign: 'right', height: '13px' }}>
                  <IconButton onClick={onClose}>
                    <CloseIcon style={{ color: '#ffffff' }} />
                  </IconButton>
                </div>
                <b style={{ color: '#ffffff' }}>의뢰완료</b>
              </Grid>
            </DialogTitle>

            <DialogContent>
              <Grid id='positioning_grid' container>
                {/* Q1 Button */}
                <Grid container justifyContent='center' item xs={12} style={{ width: '100%', textAlign: 'center', display: 'block', fontSize: '16px' }}>
                  <span style={{ fontWeight: 'bold', marginBottom: '0.5rem' }}>{this.state.userName}</span>님! <br />
                  영상 구매를 의뢰해주셔서 감사드립니다. <br />
                  <br />
                  <span>
                    {this.state.text.split('\\n').map(line => {
                      return (
                        <span key={line}>
                          {line}
                          <br />
                        </span>
                      );
                    })}
                  </span>
                </Grid>

                {/* 의뢰하기 */}
                <Grid container justifyContent='center' item xs={12}>
                  <Button
                    fullWidth={true}
                    className={classes.colorBtn}
                    onClick={() => {
                      this.props.history.push('/history/purchase');
                    }}
                  >
                    확인
                  </Button>
                </Grid>
              </Grid>
            </DialogContent>
          </Grid>
        )}
        {this.state.circularProgress && (
          <div className={classes.circularProgress}>
            <Box>
              <CircularProgress size={68} />
            </Box>
          </div>
        )}
      </Dialog>
    );
  }
}

export default withRouter(withStyles(styles, { withTheme: true })(OfferDialog));
