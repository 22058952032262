import React, { Component } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, Grid, IconButton, withStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const styles = (theme) => ({
  loginInput: {
    '& input': {
      backgroundColor: '#0c0e10',
      [theme.breakpoints.down('md')]: {},
    },
  },
  colorBtn: {
    backgroundColor: '#e61737',
    border: '2px solid #e61737',
    borderRadius: '5px',
    color: '#ffffff',
    height: '5vh',
    fontWeight: 'bold',
    margin: theme.spacing(2, 0),
    [theme.breakpoints.down('md')]: {
      border: '1px solid #e61737',
      borderRadius: '5px',
      height: '50px',
      margin: theme.spacing(1, 0),
    },
  },
  menuItem: {
    color: '#ffffff',
  },
  selectBox: {
    width: '100%',
    height: '50px',
    marginBottom: '10px',
    textAlign: 'center',
    color: 'white',
    background: 'rgb(12, 14, 16)',
  },
  floatingLabel: {
    width: '100%',
    '& .MuiInputLabel-root': {
      color: 'rgba(254, 254, 254, 0.25);',
    },
    '& .MuiInputBase-root': {
      color: '#ffffff',
      fontSize: '12px',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'rgba(254, 254, 254, 0.25);',
      },
      '&:hover fieldset': {
        borderColor: 'rgba(254, 254, 254, 0.25);',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'rgba(254, 254, 254, 0.25);',
      },
    },
  },
  DialogMain: {
    backgroundColor: '#171A1D',
    padding: '15px',
    [theme.breakpoints.down('md')]: {
      padding: '0',
    },
  },
});

class accountDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buyer: '', //구매자 소속
      purposeOfuse: '', //사용목적
      matches: window.matchMedia('(max-width:991px)').matches,
      submit: 0,
    };
  }

  handleChange = (event, newValue) => {
    this.setState({ [event]: newValue });
  };

  requestBtn = () => {
    this.setState({ submit: 1 });
  };

  render() {
    const { open, onClose, classes, pointCheck, reRender } = this.props;

    if (pointCheck) {
      reRender();
    }

    return (
      <Dialog fullWidth={true} maxWidth={'xs'} onClose={onClose} open={open}>
        <Grid container item xs={12} className={classes.DialogMain}>
          <DialogTitle style={{ width: '100%' }}>
            <Grid container item xs={12} style={{ width: '100%', textAlign: 'center', display: 'block' }}>
              <div style={{ textAlign: 'right', height: '13px' }}>
                <IconButton onClick={onClose}>
                  <CloseIcon style={{ color: '#ffffff' }} />
                </IconButton>
              </div>
              {!pointCheck ? <b style={{ color: '#ffffff' }}>정산불가</b> : <b style={{ color: '#ffffff' }}>정산신청 완료</b>}
            </Grid>
          </DialogTitle>

          <DialogContent>
            <Grid id="positioning_grid" container>
              {!pointCheck ? (
                <Grid
                  container
                  justifyContent="center"
                  item
                  xs={12}
                  style={{ width: '100%', textAlign: 'center', display: 'block', fontSize: '16px', color: '#ffffff', fontWeight: '500' }}
                >
                  정산 가능한 금액이 없습니다.
                  <br />
                  <br />
                  <span style={{ fontSize: '16px' }}>감사합니다.</span>
                </Grid>
              ) : (
                <Grid
                  container
                  justifyContent="center"
                  item
                  xs={12}
                  style={{ width: '100%', textAlign: 'center', display: 'block', fontSize: '16px', color: '#ffffff', fontWeight: '500' }}
                >
                  <span style={{ fontWeight: 'bold', marginBottom: '0.5rem' }}>홍길동</span>님! <br />
                  정산신청이 정삭적으로 완료되었습니다. <br />
                  <br />
                  <span style={{ fontSize: '14px' }}>
                    등록하신 계좌번호로 정산을 완료한 후, <br />
                    메일로 알려드립니다. <br />
                    <br />
                    감사합니다. <br />
                  </span>
                </Grid>
              )}

              <Grid container justifyContent="center" item xs={12}>
                <Button fullWidth={true} className={classes.colorBtn} onClick={onClose}>
                  확인
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </Grid>
      </Dialog>
    );
  }
}

export default withStyles(styles)(accountDialog);
