/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: 'ap-northeast-2',
  aws_cognito_identity_pool_id: 'ap-northeast-2:fcea7af7-bdec-45b1-ada9-fd37596cf7b5',
  aws_cognito_region: 'ap-northeast-2',
  aws_user_pools_id: 'ap-northeast-2_AJKsWipXn',
  aws_user_pools_web_client_id: '7ldi6sadf1579qm5lhpl50rsk1',
  oauth: {},
  aws_user_files_s3_bucket: 'file.jebomarket.com',
  aws_user_files_s3_bucket_region: 'ap-northeast-2',
};

export default awsmobile;
