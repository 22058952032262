import React from 'react';
import ReactJWPlayer from 'react-jw-player';
import { withStyles, withWidth } from '@material-ui/core';

import Alert from '../components/Alert';

const styles = theme => ({
  videoBox: {
    '& .jwplayer': {
      width: '100%',
      height: '100%',
      // maxHeight: '522px !important',
      borderTopLeftRadius: '13px',
      [theme.breakpoints.down('md' || 'xm')]: {
        borderTopLeftRadius: '13px',
        borderTopRightRadius: '13px',
      },
      '& .jw-video': {
        [theme.breakpoints.down('md' || 'xm')]: {
          borderTopLeftRadius: '13px',
          borderTopRightRadius: '13px',
        },
      },
    },
    width: '100%',
  },
});

class JwPlayer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      vodData: '',
    };
  }

  componentDidMount() {
    this.setState({ vodData: this.props.data });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.data.id !== prevProps.data.id) {
      this.componentDidMount();
    }
  }

  componentWillUnmount() {
    this.setState({ vodData: '' });
  }

  onReady = (event, id) => {
    const player = window.jwplayer(id);
    try {
      player.seek(0);
      player.pause();
    } catch (e) {
      console.log(e);
    }
  };

  onMeta(e, id) {
    try {
      const player = window.jwplayer(id);
      player.stretching = 'fill';

      this.props.lodingStop();
    } catch (e) {
      console.log(e);
    }
  }
  error(e, id) {
    if (e.sourceError?.code === 4) {
      Alert('', '영상이 아직 인코딩 처리가 되지 않았거나, <br /> 영상에 문제가 발생하였습니다. <br /> 잠시 후 다시시도해주세요.', '', '', () => {
        this.props.history.goBack();
      });
    }
  }

  render() {
    const { classes, width } = this.props;
    const { vodData } = this.state;
    let playlist = [];

    if (vodData) {
      playlist = [
        {
          file: vodData.vod[0].vodUrl,
          image: vodData.vod[0].thumb,
        },
      ];
    }

    return (
      <>
        {vodData && (
          <ReactJWPlayer
            aspectratio
            className={classes.videoBox}
            playerId={vodData.id}
            playerScript='https://cdn.jwplayer.com/libraries/grRptRQ9.js'
            playlist={playlist}
            //isAutoPlay={true}
            onReady={e => {
              this.onReady(e, vodData.id);
            }}
            onMeta={e => {
              this.onMeta(e, vodData.id);
            }}
            onError={e => {
              this.error(e, vodData.id);
            }}
            customProps={{
              controls: true,
              repeat: true,
              volume: 100,
              width: '100%',
              height: '522px',
            }}
          />
        )}
      </>
    );
  }
}

export default withStyles(styles, { withTheme: true })(withWidth()(JwPlayer));
