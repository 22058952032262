import React from 'react';
import { useNaverLogin } from '@dohyeon/react-naver-login';
import { API_URL, getAWSCredentials, NAVER_CALLBACK, NAVER_KEY } from '../constants';
import queryString from 'querystring';
import axios from 'axios';
import awsconfig from '../aws-exports';

const NaverCallback = props => {
  const { naverLoginInit, loading } = useNaverLogin({
    clientId: NAVER_KEY,
    callbackUrl: NAVER_CALLBACK,
    isPopup: true,
    callbackHandle: false,
  });

  React.useEffect(() => {
    if (!loading) {
      const naverLoginInstance = naverLoginInit();
      naverLoginInstance.getLoginStatus(status => {
        if (status) {
          if (window.location.hash.indexOf('access_token') !== -1) {
            let tokenParam = queryString.parse(window.location.hash.replace('#', ''));
            const param = {};
            param['poolId'] = awsconfig.aws_user_pools_id;
            param['clientId'] = awsconfig.aws_user_pools_web_client_id;
            axios
              .get(`${API_URL}/sns/naverCallback?access_token=${tokenParam.access_token}&poolId=${param.poolId}&clientId=${param.clientId}`, param, {
                headers: { Pragma: 'no-cache' },
              })
              .then(response => {
                getAWSCredentials({ accessToken: tokenParam.access_token }, 'Naver', {
                  userId: response.data.value.userId,
                  email: response.data.value.email,
                }).then(() => (window.location.href = '/'));
              })
              .catch(console.log)
              .finally(() => {});
          }
        } else {
          console.log('fail!');
        }
      });
    }
    // eslint-disable-next-line
  }, [loading]);

  return <div id='naverIdLogin' style={{ display: 'none' }} />;
};

export default NaverCallback;
