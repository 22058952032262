import axiosInstance from '../constants/AxiosInstance';
import { API_URL } from '../constants';

export const setCreatedAt = (created_at) => {
  const dt = new Date(Number(created_at));
  let addDt =
    dt.getFullYear() +
    '-' +
    ('00' + (dt.getMonth() + 1).toString()).slice(-2) +
    '-' +
    ('00' + dt.getDate().toString()).slice(-2) +
    ' ' +
    ('00' + dt.getHours().toString()).slice(-2) +
    ':' +
    ('00' + dt.getMinutes().toString()).slice(-2);

  return addDt;
};

export const replaceValue = (arr, _id) => {
  for (let i = 0; i < arr.length; i++) {
    if (arr[i].id === _id) {
      return arr[i].value;
    }
  }
};

export const getCodeList = async (type) => {
  const response = await axiosInstance.get(`${API_URL}/code/${type}`, { headers: { Pragma: 'no-cache' } });
  const data = response.data.value;
  return data;
};

export const returnUrl = (url) => `https://s3.ap-northeast-2.amazonaws.com/file.jebomarket.com/thumbnail/${url}`;
